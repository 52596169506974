@charset "utf-8";
@keyframes funnyshow {
  0% {
    opacity: 0;
  }

  50%,
  to {
    opacity: 1;
  }
}

@keyframes twinkle {
  0%,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes twinkle {
  0%,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes twinkle {
  0%,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes twinkle {
  0%,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes double-blink {
  0%,
  10%,
  12%,
  13%,
  18%,
  8%,
  to {
    opacity: 1;
    height: 0;
  }

  14%,
  16% {
    opacity: 1;
    height: 3.5625rem;
  }
}

@keyframes double-blink {
  0%,
  10%,
  12%,
  13%,
  18%,
  8%,
  to {
    opacity: 1;
    height: 0;
  }

  14%,
  16% {
    opacity: 1;
    height: 3.5625rem;
  }
}

@keyframes morph {
  0%,
  to {
    d: path(
      "M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45	c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2	c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7	c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z"
    );
  }

  50% {
    d: path(
      "M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6	c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8	C48.9,198.6,57.8,191,51,171.3z"
    );
  }
}

.w100 {
  width: 100%;
}

.pl0 {
  padding-left: 0 !important;
}

.pt30 {
  padding-top: 30px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.pull-none {
  float: none !important;
}

#content {
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  #bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    &.theme {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
#content.bg-space {
  background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/bg-all-min.jpg);
}
#content.bg-spring {
  background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/bg-all-min.jpg);
}
#content.bg-fall {
  background: url(//file.valofe.com/Valofe_file/web/vfun/images/sub/membership/fall/bg-fall-min.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
}
/* 여름 */
#content.bg-summer {
  background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/theme/summer/bg-summer-1-min.jpg);
  animation: bgChange 2.5s ease-in-out infinite;
}

.theme.summer {
  .theme-obj {
    position: absolute;
  }
  ._car {
    right: -45vw;
    bottom: 30px;
    width: 45vw;
    mix-blend-mode: lighten;
    animation: carMoving 15s ease-in-out infinite, shaking 15s ease-out infinite;
  }
  ._flower {
    right: 0;
    bottom: 0;
    height: 172px;
  }

  ._bt1 {
    bottom: 50px;
    right: 600px;
    animation: flying2 13s ease-in-out infinite;
  }
  ._bt2 {
    bottom: 0;
    right: 220px;
    animation: flying1 13s ease-in-out infinite;
  }
  ._bt3 {
    bottom: 60px;
    right: 50px;
    animation: flying3 10s ease-in-out infinite;
  }

  @keyframes carMoving {
    0% {
      right: -45vw;
    }
    20% {
      right: -15vw;
    }
    20%,
    50% {
      right: -15vw;
    }
    80% {
      right: 145vw;
    }
    80%,
    100% {
      right: 145vw;
    }
  }

  @keyframes bgChange {
    0% {
      background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/theme/summer/bg-summer-1-min.jpg);
    }
    50% {
      background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/theme/summer/bg-summer-2-min.jpg);
    }
    100% {
      background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/theme/summer/bg-summer-1-min.jpg);
    }
  }

  @keyframes shaking {
    0%,
    20% {
      bottom: 30px;
    }
    21% {
      bottom: 25px;
    }
    22.5% {
      bottom: 30px;
    }
    25% {
      bottom: 25px;
    }
    27.5% {
      bottom: 30px;
    }
    30% {
      bottom: 27px;
    }
    32.5% {
      bottom: 30px;
    }
    35% {
      bottom: 25px;
    }
    37.5% {
      bottom: 30px;
    }
    40% {
      bottom: 25px;
    }
    42.5% {
      bottom: 30px;
    }
    45% {
      bottom: 27px;
    }
    47.5% {
      bottom: 30px;
    }
    50% {
      bottom: 28px;
    }
    51% {
      bottom: 30px;
    }
    51%,
    100% {
      bottom: 30px;
    }
  }

  @keyframes flying1 {
    0% {
      bottom: 0px;
      right: 220px;
    }
    10% {
      bottom: 5px;
      right: 230px;
    }
    10% {
      bottom: 10px;
      right: 210px;
    }
    30% {
      bottom: 17px;
      right: 220px;
    }
    50% {
      bottom: 20px;
      right: 210px;
    }
    70% {
      bottom: 16px;
      right: 200px;
    }
    80% {
      bottom: 8px;
      right: 210px;
    }
    100% {
      bottom: 0px;
      right: 220px;
    }
  }

  @keyframes flying2 {
    0% {
      bottom: 50px;
      right: 600px;
    }
    10% {
      bottom: 55px;
      right: 605px;
    }
    15% {
      bottom: 52px;
      right: 610px;
    }
    20% {
      bottom: 58px;
      right: 620px;
    }
    30% {
      bottom: 66px;
      right: 610px;
    }
    50% {
      bottom: 67px;
      right: 600px;
    }
    70% {
      bottom: 80px;
      right: 610px;
    }
    80% {
      bottom: 70px;
      right: 605px;
    }
    100% {
      bottom: 50px;
      right: 600px;
    }
  }

  @keyframes flying3 {
    0% {
      bottom: 60px;
      right: 50px;
    }
    10% {
      bottom: 65px;
      right: 45px;
    }
    15% {
      bottom: 62px;
      right: 40px;
    }
    20% {
      bottom: 65px;
      right: 45px;
    }
    30% {
      bottom: 60px;
      right: 48px;
    }
    50% {
      bottom: 67px;
      right: 52px;
    }
    70% {
      bottom: 70px;
      right: 55px;
    }
    80% {
      bottom: 64px;
      right: 50px;
    }
    100% {
      bottom: 60px;
      right: 50px;
    }
  }
}

.section-login {
  z-index: 1;
  margin-top: 0;
  margin-bottom: inherit;
  min-height: calc(100% - 13rem);

  .email_msg {
    display: inline-block;
    margin-left: 5px;
    margin-top: -3px;
  }

  .grecaptcha {
    width: 100%;

    div {
      div {
        margin: 0 auto;
      }
    }
  }
}

.section-login:first-child {
  padding-top: 6vh;
  padding-bottom: 5rem;
  text-align: left;
  padding-left: 10%;
  height: 100%;
  box-sizing: border-box;
  margin-top: 0;
}

.section-login {
  // margin-bottom: 5rem !important;
  position: relative;
}

.bg-motion,
.svg-bottom {
  position: absolute;
  display: inline-block;
  z-index: 0;
}

.bg-motion {
  left: -5%;
  top: 0;
  width: 36%;
  height: 100%;
}

.svg-bottom {
  right: 0;
  bottom: 0;
  width: 74%;
  height: 17.5%;
  pointer-events: none;
}

.bg-motion img,
.svg-bottom img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.section-login * {
  font-family: "Noto Sans", Sans-serif;
}

.section-login .fa {
  display: inline-block;
  font: 14px/1;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section-login .box {
  position: relative;
  // margin-top: 3rem;
  margin-left: 15%;
  margin-bottom: inherit;
  border-radius: 0.625rem;
  box-sizing: border-box;
  width: 800px;
  height: auto;
  -webkit-box-shadow: 0 0 20px 5px rgba(22, 24, 31, 0.75);
  -moz-box-shadow: 0 0 20px 5px rgba(22, 24, 31, 0.75);
  box-shadow: 0 0 20px 5px rgba(22, 24, 31, 0.75);
  background: #33363f;
}

.section-login .box.wide {
  width: 980px;
}

.box-cont {
  float: left;
}

.box-cont.left,
.box-cont.right {
  box-sizing: border-box;
  height: 100%;
}

.box-cont.left {
  float: left;
  position: relative;
  width: 60%;
  background: #33363f;
  min-height: 34rem;
  padding: 2.5rem 7% 1.8rem;
  border-top-left-radius: 0.875rem;
  border-bottom-left-radius: 0.875rem;
}

.box-cont.right {
  width: 40%;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.box-cont h1 {
  line-height: 2.25rem;
  color: #fff;
  font-size: 2.375rem;
  margin-bottom: 1.25rem;
  // overflow-y: hidden;
  padding-bottom: 14px;
}

.box-cont h1 small,
.step-small {
  color: #fff;
  font-weight: 400;
  font-size: 0.75rem;
  vertical-align: top;
  float: right;
  text-transform: uppercase;
}

.step-small {
  float: right;
  margin-top: -60px;

  i {
    display: inline-block;
    margin-top: -3px;
  }
}

.section-login .wrapper-input {
  position: relative;
  width: 100%;
  // margin-bottom: 0.75rem;

  &.btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.cont table,
.section-login .wrapper-input.not-all {
  // width: 110%;
  // margin-left: -5%;
}

.wrapper-input .alert {
  // display: none;
  display: block;
  width: 100%;
  padding-left: 0.35rem;
  margin-top: 0.35rem;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #b03870;
  letter-spacing: 0.125px;
}

.wrapper-input .alert.round {
  box-sizing: border-box;
}

.wrapper-input .alert.round,
.wrapper-input + .alert.round {
  // display: none;
  display: block;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  color: #b03870;
}

.wrapper-input .alert.green {
  color: #53ce7f;
}

.wrapper-input .alert.on,
.wrapper-input + .alert.round.on {
  display: block;
}

.wrapper-input .eyes {
  position: absolute;
  top: 0.5rem;
  bottom: 0;
  right: 1.25rem;
  margin: auto 2px;
  cursor: pointer;
}

.btn.small-input,
.wrapper-input .eyes .icon-eye {
  transition: all 0.2s;
}

.wrapper-input .eyes .icon-eye.off {
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-eyes-off.png);
  width: 17px;
  height: 13px;
  display: inline-block;
}

.wrapper-input .eyes .icon-eye.on {
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-eyes-on.png);
  width: 17px;
  height: 11px;
  display: inline-block;
}

.btn.small-input {
  position: absolute;
  top: 0.425rem;
  right: 0.4rem;
  height: inherit;
  line-height: inherit;
  min-width: inherit;
  cursor: pointer;
  border-radius: 1rem;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.btn.small-input.setting {
  background: #6586e2;
}

.btn.small-input.setting:hover {
  background: #4271f5;
}

.btn.small-input .txt {
  display: inline-block;
  font-size: 0.75rem;
  color: #fff;
  margin-right: 0.4375rem;
  vertical-align: middle;
  height: auto;
  text-transform: none;
}

.btn.small-input .icon-pencil {
  width: 1.5rem;
  height: 1.5625rem;
  display: inline-block;
  vertical-align: middle;
  background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-pencil.png);
  background-size: cover;
}

.btn.small-input.change {
  background: #6dbfd0;
}

.btn.small-input.change:hover {
  background: #48b6ce;
}
.section-login input[type="email"],
.section-login input[type="password"],
.section-login input[type="text"],
.section-login input[type="date"] {
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  min-width: inherit;
  padding: 0.625rem 2rem;
  border-radius: 1.5rem;
  -moz-border-radius-bottomright: 1.5rem;
  -moz-border-radius-bottomleft: 1.5rem;
  -webkit-border-bottom-right-radius: 1.5rem;
  -webkit-border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  -moz-border-radius-topright: 1.5rem;
  -moz-border-radius-topleft: 1.5rem;
  -webkit-border-top-right-radius: 1.5rem;
  -webkit-border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  font-size: 0.875rem;
  border: 4px solid #bdc2d2;
  color: #9ea1a9 !important;
  cursor: pointer;
}

.section-login input[type="email"]:focus {
  border: 4px solid #4271f5;
  outline: 0;
}

.section-login .wrapper-input.border-type {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-width: inherit;
  border-radius: 1.5rem;
  font-size: 0.875rem;
  border: 4px solid #bdc2d2;
  color: #9ea1a9 !important;
  background: #fff;
  transition: all 0.2s;
}

.section-login .wrapper-input.border-type.on {
  border: 4px solid #4271f5;
  outline: 0;
}

.section-login .wrapper-input.border-type.read-only {
  border: 4px solid #bdc2d2;
  color: #9ea1a9 !important;
  outline: 0;
}

.section-login input[type="email"].border-none-type,
.section-login input[type="password"].border-none-type,
.section-login input[type="text"].border-none-type {
  float: left;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  outline: 0;
  border: 0;
  -webkit-appearance: none;
  background: 0 0;
  color: #9ea1a9 !important;
}

.width60,
input[type="email"].width60,
input[type="text"].width60 {
  width: 60%;
}

.width40,
input[type="email"].width40,
input[type="text"].width40 {
  width: 40%;
}

input[type="checkbox"]:checked {
  background: 0 0;
}

.section-login input[type="checkbox"] {
  position: relative;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-sizing: border-box;
  display: inline-block;
  margin: 0 4px 0 0;
  width: 14px;
  height: 14px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  align-items: flex-start;
  vertical-align: middle;
  background: 0 0;
  border: 0 !important;
  cursor: pointer;
}

.section-login {
  input[type="checkbox"]:after {
    position: absolute;
    content: "";
    display: inline-block;
    margin: 0 9px 0 0;
    left: -1px;
    top: -1px;
    border: 0 !important;
    width: 14px;
    height: 15px;
    background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/chk-off.png)
      0 0 no-repeat;
  }

  input[type="checkbox"]:checked {
    outline: 0;
    border: 0 !important;
    box-sizing: border-box;
  }

  input[type="checkbox"]:checked:after {
    position: absolute;
    content: "";
    display: inline-block;
    margin: 0 9px 0 0;
    width: 14px;
    height: 15px;
    left: -1px;
    top: -4px;
    border: 0 !important;
    background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/chk-on.png)
      0 0 no-repeat;
  }
}

.btn.round-type {
  outline: 0;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  line-height: inherit;
  min-width: inherit;
  padding: 0.9rem 2rem;
  border-radius: 1.75rem;
  font-size: 0.875rem;
  align-items: flex-start;
  color: #fff;
  background: #6586e2;
}

.valid-type-not {
  display: inline-block;
  background: #6e788e;
  color: rgba(255, 255, 255, 0.5);
  cursor: default;
  pointer-events: none;
}

.pwbtn-wrap {
}

.btn.round-type.valid-type-not:focus,
.btn.round-type.valid-type-not:hover,
.valid-type-not:focus,
.valid-type-not:hover {
  background: #6e788e;
  color: rgba(255, 255, 255, 0.5);
  cursor: default;
  pointer-events: none;
}

.valid-type-pass {
  display: inline-block;
  background: #6586e2;
}

.valid-type-active {
  display: inline-block;
  background: #4271f5;
  color: #fff;
}

.valid-type-active:focus,
.valid-type-active:hover,
.valid-type-pass:focus,
.valid-type-pass:hover {
  background: #4271f5;
  color: #fff;
}

.section-login input[type="email"].border-none-type:read-only,
.section-login input[type="email"]:read-only,
.section-login input[type="password"].border-none-type:read-only,
.section-login input[type="password"]:read-only,
.section-login input[type="text"].border-none-type:read-only,
.section-login input[type="text"]:read-only {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: #fff;
  font-size: 14px;
  cursor: default !important;
  pointer-events: none !important;
}

input::-webkit-input-placeholder {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: #fff;
  font-size: 14px;
  cursor: default !important;
}

:-moz-placeholder,
textarea:-moz-placeholder {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: #fff;
  font-size: 14px;
  cursor: default !important;
}

:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: #fff;
  font-size: 14px;
  cursor: default !important;
}

::-moz-placeholder,
textarea::-moz-placeholder {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: #fff;
  font-size: 14px;
  cursor: default !important;
}

::-ms-input-placeholder {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: #fff;
  font-size: 14px;
  cursor: default !important;
}

::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: #fff;
  font-size: 14px;
  cursor: default !important;
}

::placeholder {
  font-weight: 400;
  color: #9ea1a9 !important;
  background: inherit;
  font-size: 14px;
  cursor: default !important;
}

.btn.round-type.valid-type-not {
  // display: none;
  display: inline-block;
  background: #6e788e;
  color: rgba(255, 255, 255, 0.5);
  cursor: default;
  pointer-events: none;
}

.btn.round-type.valid-type-pass {
  display: inline-block;
  background: #6586e2;
}

.btn.round-type.valid-type-active {
  display: none;
  background: #4271f5;
  color: #fff;
}

.btn.round-type.valid-type-active.on,
.btn.round-type.valid-type-not.on,
.btn.round-type.valid-type-pass.on {
  display: inline-block;
}

.btn.round-type.valid-type-active:focus,
.btn.round-type.valid-type-active:hover,
.btn.round-type.valid-type-pass:focus,
.btn.round-type.valid-type-pass:hover,
.btn.round-type:focus,
.btn.round-type:hover {
  background: #4271f5;
  color: #fff;
}

.label-chk-sns {
  margin-left: 0.625rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #fff;
}

.btn.small {
  display: inline-block;
  background-color: #6e788e;
  width: 10.1875rem;
  margin-left: 1rem;
  min-width: inherit;
  height: inherit;
  line-height: inherit;
}

.btn.small:first-child {
  margin-left: 0;
}

.btn.small.active {
  background-color: #4271f5;
}

.btn.small.text-over,
.btn.smallest {
  font-size: 0.75rem;
  padding: 1rem;
}

.btn.smallest {
  float: right;
  display: inline-block;
  background-color: transparent;
  width: auto;
  min-width: inherit;
  height: inherit;
  line-height: inherit;
  margin-top: 0.125rem;
  padding: 0.5rem;
  vertical-align: middle;
  text-align: center;
  text-transform: none;
}

.btn.smallest:first-child,
.wrapper-input.sns > .btn-sns:first-child {
  margin-left: 0;
}

.btn.grey-border {
  color: #6e788e;
  border: 2px solid #6e788e;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.btn.grey-border:hover {
  background: 0 0;
  color: #4271f5;
  border-color: #4271f5;
}

.btn.arrow {
  display: none;
  background-color: transparent;
  min-width: inherit;
  width: 2rem;
  height: 2rem;
  line-height: 2;
  margin-right: 0.75rem;
  padding: 0;
  font-size: 0.75rem;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  text-transform: none;
  margin-bottom: 3px;
}

.btn.arrow.on {
  display: inline-block;
}

.wrapper-input.link {
  width: calc(100% - 1.25rem);
  margin-top: 1rem;
  margin-left: 0.625rem;
  margin-bottom: 1rem;
}

.wrapper-input.link > a,
.wrapper-input.link > span > a {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  font-size: 0.75rem;
  text-transform: none;
}

.wrapper-input.link > span {
  display: inline-block;
  vertical-align: middle;
  color: #bdc2d2;
  font-size: 0.8125rem;
  letter-spacing: -0.25px;
}

.wrapper-input.link > span > a {
  vertical-align: middle;
}

#cont_3 .label-chk-sns,
.section-login input[type="checkbox"].round-chk {
  box-sizing: border-box;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
}

#cont_3 .label-chk-sns {
  width: 100%;
  line-height: 1.4;
  padding-left: 4rem;
  font-size: 0.875rem;
  text-indent: -1rem;
  letter-spacing: 0;
}

#cont_3 .round-chk {
  margin-left: -2rem;
}

#cont_3 .label-chk-sns[for="agree_all"] {
  padding-left: 3rem;
  font-size: 0.9375rem;
}

.section-login input[type="checkbox"].round-chk {
  position: relative;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  margin: 0 1.125rem 0 0;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  align-items: flex-start;
  vertical-align: middle;
  background: 0 0;
  border: 0 !important;
  width: 1.375rem;
  height: 1.375rem;
  transition: all 0.2s;
}

.section-login input[type="checkbox"].round-chk:focus:after {
  border: 2px solid #6e788e !important;
}

input[type="checkbox"]#agree_all:after,
input[type="checkbox"].round-chk:after {
  position: absolute;
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 0 9px 0 0;
  left: 0;
  top: 0;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  background: 0 0;
  background-position: center;
}

input[type="checkbox"].round-chk:after {
  border: 2px solid #6e788e !important;
  transition: all 0.2s;
}

input[type="checkbox"]#agree_all:after {
  border: 2px solid #3567ff !important;
}

input[type="checkbox"]#agree_all:checked:after,
input[type="checkbox"].round-chk:checked:after,
input[type="checkbox"].square-chk:checked:after {
  position: absolute;
  content: "";
  display: inline-block;
  box-sizing: border-box;
  width: 1.375rem;
  height: 1.375rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"].square-chk:checked:after {
  margin: -3px 9px 0 -1px;
  border: 0;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/chk-on.png)
    center no-repeat;
}

input[type="checkbox"]#agree_all:checked:after,
input[type="checkbox"].round-chk:checked:after {
  margin: 0 9px 0 0;
}

input[type="checkbox"].round-chk:checked:after {
  border: 2px solid #fff !important;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/round-chk-on.png)
    center no-repeat;
}

input[type="checkbox"]#agree_all:checked:after {
  border: 2px solid #3567ff !important;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/round-chk-all-on.png)
    center no-repeat;
}

.switch,
.switch .txt {
  display: inline-block;
  vertical-align: middle;
}

.switch {
  position: relative;
  width: 50%;
  height: 2.125rem;
}

.switch .txt {
  width: 100%;
  margin-right: 1rem;
  line-height: 3;
  font-size: 0.75rem;
  color: #bdc2d2;
}

.section-login .switch input[type="checkbox"].toggle-chk {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider,
.slider:after,
.slider:before {
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider {
  top: 0;
  left: 5.25rem;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 4rem;
  background-color: #6e788e;
}

.slider:after,
.slider:before {
  width: 1.625rem;
}

.slider:before {
  content: "";
  height: 1.625rem;
  left: 0.25rem;
  bottom: 0.25rem;
  background-color: #bdc2d2;
}

.slider:after {
  left: 2.25rem;
  top: 0.75rem;
  background: 0 0;
  font-weight: 700;
  color: #bdc2d2;
  font-size: 0.625rem;
  letter-spacing: 0.125px;
}

input.toggle-chk:checked + .slider {
  background-color: #4271f5;
}

input.toggle-chk:focus + .slider {
  box-shadow: 0 0 1px #4271f5;
}

input.toggle-chk:checked + .slider:before {
  background-color: #fff;
  -webkit-transform: translateX(1.875rem);
  -ms-transform: translateX(1.875rem);
  transform: translateX(1.875rem);
}

input.toggle-chk:checked + .slider:after {
  position: absolute;
  width: 1.625rem;
  left: 0.5rem;
  top: 0.75rem;
  background: 0 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-weight: 700;
  color: #fff;
  font-size: 0.625rem;
  letter-spacing: 0.125px;
}

.slider.round {
  border-radius: 2.125rem;
}

.slider.round:before {
  border-radius: 50%;
}

.guide {
  margin-top: -25px;
  text-align: right;
  color: #c3bbdd;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
}

.essential,
.optional {
  margin-right: 6px;
  color: #b03870;
  vertical-align: text-top;
}

.optional {
  color: #406be1;
}

.guide .essential,
.guide .optional,
.slider.round {
  vertical-align: middle;
}

.step {
  // margin-bottom: 2rem;
  position: relative;
}

.ul-step {
  z-index: 1;
  display: inline-block;
  align-items: center;
  justify-content: space-around;
  width: 56%;
  margin-left: auto;
  margin-right: auto;
}

.ul-step li {
  position: relative;
  display: inline-block;
  width: 3rem;
  text-align: center;
  transition: background 2s;
}

.ul-step li:first-child {
  float: left;
  margin-left: -1.35rem;
}

.ul-step li:last-child {
  float: right;
  margin-right: -1.35rem;
}

.ul-step .circle {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  border: 3px solid #6e788e;
  background: #3b3e4a;
  transition: background 2s;
}

.ul-step .txt {
  font-size: 0.75rem;
  font-weight: 700;
  color: #6e788e;
  transition: color 2s;
}

.ul-step li.on .circle {
  border: 3px solid #3567ff;
  transition: background 2s;
}

.ul-step li.on .txt {
  color: #3567ff;
  transition: color 2s;
}

.step > .bar {
  position: absolute;
  height: 3px;
  width: 56%;
  left: 24%;
  top: 1.95rem;
  z-index: 0;
  transition: background 2s;
}

.step > .bar._1 {
  background: linear-gradient(
    90deg,
    rgba(53, 103, 255, 0) 0,
    #868c9e 0.1%,
    #868c9e 100%
  );
  transition: background 2s;
}

.step > .bar._2 {
  background: linear-gradient(90deg, #3567ff 50%, #868c9e 50.1%, #868c9e 100%);
  transition: background 2s;
}

.step > .bar._3,
.step > .bar.last {
  background: linear-gradient(90deg, #3567ff 0, #3567ff 100%);
  transition: background 2s;
}

.ul-step li.on:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 50%;
  margin-left: -6px;
  width: 12px;
  height: 7px;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/step-on.png)
    center no-repeat;
}

// .box-cont .cont {
//   display: none;
// }

.box-cont .cont.on {
  display: block;
}

.pull-size {
  width: 130%;
  margin-left: -15%;
  padding: 1rem 0;
  text-align: center;
  background: #3b3e4a;
  vertical-align: middle;
  letter-spacing: 0;
}

.wrapper-input.link.pull-size {
  position: absolute;
  width: 100%;
  margin-top: inherit;
  margin-bottom: inherit;
  bottom: 0;
  left: 0;
  margin-left: 0;
  border-bottom-left-radius: 0.875rem;
}

.wrapper-input.link.pull-size > span > a {
  margin-left: 6px;
  color: #82a2fd;
  font-size: 0.8125rem;
  vertical-align: middle;
  transition: all 0.25s;
}

.wrapper-input.link.pull-size > span > a:hover {
  text-decoration: underline;
}

.line-or {
  position: relative;
  margin-top: 0.5rem;
  width: 100%;
  height: 1px;
  background-color: rgba(189, 194, 210, 0.2);
}

.btn.signup em,
.line-or,
.or {
  display: inline-block;
}

.or {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: auto;
  font-size: 12px;
  height: 12px;
  text-align: center;
  color: #bdc2d2;
  background: #33363f;
  letter-spacing: 0;
  text-transform: uppercase;
}

.section-login .icon {
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  vertical-align: middle;
  transition: all 0.25s;
}

.nav-li .icon {
  background: 0 0;
}

.icon-apple,
.icon-fb,
.icon-gg,
.icon-vfun {
  display: inline-block;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
}

.icon-vfun {
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-vfun.png)
    center no-repeat;
}

.icon-fb {
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-fb.png)
    center no-repeat;
}

.icon-gg {
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-gg.png)
    center no-repeat;
}

.icon-apple {
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-apple.png)
    center no-repeat;
}

.wrapper-input.sns {
  margin-top: 1.875rem;
  width: calc(100% - 1.25rem);
  text-align: center;
  margin-bottom: 0.625rem;
}

.btn-login-more,
.wrapper-input.sns > .btn-sns {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
}

.wrapper-input.sns > .btn-sns {
  margin-left: 16px;
  vertical-align: top;
  color: #fff;
  text-transform: none;
}

.btn-sns {
  background: transparent;

  &:hover {
    background: transparent;
  }

  .icon {
    margin-bottom: 0.5rem;
    width: 3.25rem;
    height: 3.25rem;
    border: 4px solid #bdc2d2;
  }
}

.btn-sns .icon-apple,
.btn-sns .icon-fb,
.btn-sns .icon-gg,
.btn-sns .icon-vfun {
  margin-top: 0.125rem;
}

.btn-sns .txt {
  transition: all 0.25s;
}

.btn-sns:hover .icon {
  background: #fff;
  border: 4px solid #4271f5;
}

.btn-sns:hover .txt {
  color: #6586e2;
}

.btn-login-more {
  float: right;
  padding: 0.1rem 0.65rem;
  font-weight: 700;
  border-radius: 0.875rem;
  text-indent: 0;
  background: #5cd8d9;
  color: #33363f;
}

.btn.signup {
  margin-bottom: 0.875rem;
  padding: 0.5rem 0.625rem;
  color: #6e788e;
  clear: both;
  text-transform: none;
  background: #fff;

  .icon {
    float: left;
    width: 1.75rem;
    height: 1.75rem;
    margin-top: 0;
    background: 0 0;
  }
}

.btn.signup .icon-apple,
.btn.signup .icon-fb,
.btn.signup .icon-gg,
.btn.signup .icon-vfun {
  border-radius: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: 0;
}

.btn.signup .icon-fb {
  background-size: 1.75rem 1.75rem;
}

.btn.signup .icon-gg {
  background-size: 1.75rem 1.6875rem;
}

.btn.signup .icon-apple {
  background-size: 1.0625rem 1.1875rem;
}

.btn.signup small {
  font-weight: 400;
  font-style: normal;
  vertical-align: middle;
}

.btn.signup em {
  height: 1.75rem;
  line-height: 1.68;
}

.lastname-bar {
  position: absolute;
  left: 60%;
  top: 0;
  top: 0.8125rem;
  width: 1px;
  height: 0.875rem;
  background: #bdc2d2;
}

.section-login input[type="password"]:focus,
.section-login input[type="text"]:focus {
  border: 4px solid #4271f5;
  outline: 0;
}

.section-login input[type="text"].input-click {
  border: 4px solid #4271f5;
  outline: 0;
  cursor: text;
}

.section-login input[type="email"].border-none-type:focus,
.section-login input[type="password"].border-none-type:focus,
.section-login input[type="text"].border-none-type:focus {
  border: 0;
  outline: 0;
}

.g-recaptcha {
  display: none;
  margin-bottom: 5px;
}

.membership .wrap-btn {
  margin-top: 5px;
}

.recaptcha-wrap .input-wrap {
  display: none;
}

#recaptcha-wrapper.on,
.recaptcha-wrap.on .g-recaptcha,
.recaptcha-wrap.on .input-wrap {
  display: block;
}

.input-wrap.remember {
  margin-bottom: 15px;
}

#recaptcha-wrapper {
  display: none;
  position: relative;
  margin-top: 0.9375rem;
  margin-bottom: 1.5rem;
  text-align: center;
  height: 78px;
}

#recaptcha-wrapper.on .g-recaptcha {
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -144px;
}

.recaptcha-wrap.on {
  display: block;
  height: 78px;
}

#recaptcha-wrapper .input-wrap {
  margin-top: 0;
}

.box-cont.promote {
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 5.4375rem 0 0;
}

.area-logo {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/vfun-emblem.png)
    center no-repeat;
  background-size: 100%;
}

.area-greeting {
  display: inline-block;
  margin-top: 0.875rem;
  width: 100%;
  height: 2.8125rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/txt-greeting.png)
    center no-repeat;
  background-size: 6.75rem 2.8125rem;
}

.area-character {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0);
  width: 100%;
  height: 14.9rem;
}

.area-gamepad {
  animation: updown 2s 1s ease infinite, fadeInUp 1s 0s ease 1;
  position: relative;
  margin-top: -10rem;
  display: inline-block;
  width: 100%;
  height: 13.1875rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/gamepad.png)
    center no-repeat;
  background-size: 14.625rem 13.1875rem;
}

.area-funny,
.funny-eye {
  position: absolute;
  display: inline-block;
}

.area-funny {
  right: 0;
  bottom: -6.5rem;
  width: 100%;
  height: 20rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/funny.png)
    center top no-repeat;
  background-size: auto 20rem;
  transfrom: translate(0, -6rem);
  -webkit-animation: funnyshow 1s 0s linear forwards;
  -moz-animation: funnyshow 1s 0s linear forwards;
  -o-animation: funnyshow 1s 0s linear forwards;
  animation: funnyshow 1s 0s linear forwards;
}

.funny-eye {
  top: 7.25rem;
  left: 50%;
  margin-left: -1.875rem;
  width: 3.5625rem;
  height: 3.5625rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/funny-eye.png)
    center no-repeat;
}

.twinkle1 {
  position: absolute;
  left: 50%;
  margin-left: -3rem;
  top: -6rem;
  width: 0.875rem;
  height: 1rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/twinkle-1.png)
    center no-repeat;
  animation: twinkle 2s 0s infinite ease-in-out;
  -webkit-animation: twinkle 2s 0s infinite ease-in-out;
  -o-animation: twinkle 2s 0s infinite ease-in-out;
  -moz-animation: twinkle 2s 0s infinite ease-in-out;
  opacity: 0;
}

.twinkle2 {
  margin-left: 1.25rem;
  top: -5rem;
  width: 0.5rem;
  height: 0.5625rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/twinkle-2.png)
    center no-repeat;
  animation: twinkle 2s 0.5s infinite ease-in-out;
  -webkit-animation: twinkle 2s 0.5s infinite ease-in-out;
  -o-animation: twinkle 2s 0.5s infinite ease-in-out;
  -moz-animation: twinkle 2s 0.5s infinite ease-in-out;
}

.twinkle2,
.twinkle3,
.twinkle4 {
  position: absolute;
  left: 50%;
  opacity: 0;
}

.twinkle3 {
  margin-left: -1rem;
  top: -2.25rem;
  width: 0.75rem;
  height: 0.8125rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/twinkle-3.png)
    center no-repeat;
  animation: twinkle 2.5s 0.25s infinite ease-in-out;
  -webkit-animation: twinkle 2.5s 0.25s infinite ease-in-out;
  -o-animation: twinkle 2.5s 0.25s infinite ease-in-out;
  -moz-animation: twinkle 2.5s 0.25s infinite ease-in-out;
}

.twinkle4 {
  margin-left: 3rem;
  top: -1.75rem;
  width: 0.875rem;
  height: 1rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/twinkle-4.png)
    center no-repeat;
  animation: twinkle 3s 0.75s infinite ease-in-out;
  -webkit-animation: twinkle 3s 0.75s infinite ease-in-out;
  -o-animation: twinkle 3s 0.75s infinite ease-in-out;
  -moz-animation: twinkle 3s 0.75s infinite ease-in-out;
}

.double-blink {
  -webkit-animation: double-blink 4s 0s cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
  animation: double-blink 4s 0s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  opacity: 0;
}

#gsp_canvas svg {
  width: 70%;
  margin-top: 0;
  position: absolute;
  left: -4rem;
  top: -81rem;
  z-index: 0;
  transform: rotate(176deg);
}

#gsp_canvas svg path {
  d: path(
    "M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45	c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2	c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7	c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z"
  );
  animation: morph 5s infinite;
}

.txt-info {
  margin-bottom: 3.25rem;
  width: 110%;
  margin-left: -5% !important;
  font-size: 1.125rem;
  text-align: center;
  color: #fff;
  word-break: keep-all;
}

:lang(cn) .small-grey,
:lang(cn) .txt-info,
:lang(jp) .small-grey,
:lang(jp) .txt-info,
:lang(th) .small-grey,
:lang(th) .txt-info,
:lang(tw) .small-grey,
:lang(tw) .txt-info,
:lang(zh) .small-grey,
:lang(zh) .txt-info {
  word-break: break-all;
}

.small-grey {
  display: block;
  margin-top: 0.625rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #bdc2d2;
  word-break: keep-all;
  letter-spacing: 0;
}

.txt-info a {
  color: #bdc2d2;
  text-decoration: none;
}

td.bg-grey {
  background: rgba(255, 255, 255, 0.1);
}

td.small-grey {
  display: table-cell;
  width: auto;
  min-width: 6rem;
  padding: 0.5rem;
  border: 1px solid #53555d;
}

.sns-agree-info + .txt-info {
  font-size: 0.75rem;
}

.sns-agree-info {
  margin: 3rem auto 1.25rem;
  width: 100%;
}

.sns-agree-info th {
  padding: 0.75rem;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  background: #4271f5;
  color: #fff;
}

.sns-agree-info td {
  font-size: 0.875rem;
  padding: 0.75rem 0.875rem;
  vertical-align: middle;
  border-bottom: 1px solid #bdc2d2;
  color: #33363f;
  background: #fff;
}

.sns-agree-info td .circle {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 0.875rem;
  width: 1.4375rem;
  height: 1.4375rem;
  border: 2px solid #3567ff;
  background: 0 0;
}

.sns-agree-info td .circle:after {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 50%;
  margin-left: -0.25rem;
  width: 9px;
  height: 7px;
  background: url(../../assets/images/step-on.png) center top no-repeat;
  background-size: 100%;
}

.tui-datepicker {
  border: 1px solid #33363f;
  position: absolute;
  margin-left: -136px;
  left: 50%;
  top: -200px;
  z-index: 99;
}

#calendar.shadow-on {
  display: block;
  z-index: 9998;
}

@media screen and (max-width: 1536px) {
  #content.bg-fall {
    background-position: calc(100% + 7vw) bottom;
    background-size: calc(100% + 7vw) auto;
  }
}
@media screen and (max-width: 1366px) {
  .bg-motion {
    width: 70%;
  }

  .svg-bottom {
    width: 100%;
    height: auto;
    bottom: -10px;
  }

  #content.bg-fall {
    background-position: calc(100% + 3vw) bottom;
    background-size: cover;
  }
}

@media screen and (max-width: 1280px) {
  h1.mb60 {
    margin-bottom: 2rem !important;
  }

  #content.bg-space {
    background-position: right bottom;
    background-size: cover;
  }
  #content.bg-fall {
    background-position: right bottom;
    background-size: cover;
  }
  .section-login .box {
    width: 66%;
    height: auto;
    margin-left: 10%;
  }

  .section-login .box.wide {
    width: 80%;
    margin-left: 10%;
  }

  .area-funny {
    bottom: -5rem;
    height: 16rem;
    background-size: auto 16rem;
  }

  .funny-eye {
    top: 5.8rem;
    margin-left: -1.5rem;
    width: 2.85rem;
    height: 2.85rem;
  }

  // .wrapper-input .eyes {
  //   top: 0.875rem;
  // }

  .box-cont.left {
    padding: 1.8rem 7% 1.5rem;
  }

  .box-cont h1 {
    font-size: 2rem;
  }

  .pull-size {
    padding: 0.8rem 0 0.75rem;
  }

  .step > .bar {
    top: 1.75rem;
  }

  .box-cont h1.mb20,
  .wrapper-input.mb10 {
    margin-bottom: 0.5rem !important;
  }

  .svg-bottom {
    bottom: -5px;
  }

  .txt-info {
    line-height: 1.4;
    margin-top: 1.25rem;
    font-size: 1rem;
  }

  .step-small {
    margin-top: -48px;
  }
}

@media screen and (max-width: 1200px) {
  .ul-step li.on:after {
    top: 11px;
  }

  .txt-info {
    font-size: 1.125rem;
  }

  .step-small {
    margin-top: -42px;
  }
}

@media screen and (max-width: 1024px) {
  .section-login input[type="email"],
  .section-login input[type="email"].border-none-type:read-only,
  .section-login input[type="email"]:read-only,
  .section-login input[type="password"],
  .section-login input[type="password"].border-none-type:read-only,
  .section-login input[type="password"]:read-only,
  .section-login input[type="text"],
  .section-login input[type="text"].border-none-type:read-only,
  .section-login input[type="text"]:read-only,
  input[type="email"]:disabled,
  input[type="email"]:read-only,
  input[type="file"]:disabled,
  input[type="file"]:read-only,
  input[type="password"]:disabled,
  input[type="password"]:read-only,
  input[type="text"]:disabled,
  input[type="text"]:read-only {
    font-size: 1rem !important;
    padding: 0.75rem 1.25rem;
    border-width: 2px;
    color: #9ea1a9 !important;
  }

  .btn-sns .txt,
  .btn.round-type,
  .wrapper-input.link > a,
  .wrapper-input.link > span > a {
    font-size: 1rem;
  }

  .label-chk-sns {
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.25px;
  }

  .section-login .wrapper-input.border-type {
    border-width: 2px;
  }

  .section-login input[type="email"]:focus,
  .section-login input[type="password"]:focus,
  .section-login input[type="text"]:focus {
    border-width: 2px;
  }

  // .wrapper-input .eyes {
  //   top: 1.125rem;
  // }

  .section-login h1 {
    padding-bottom: 0;
  }

  .box-cont h1 {
    line-height: 3.25rem;
  }

  .line-or {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .bg-motion {
    width: 100%;
  }

  .svg-bottom {
    bottom: 55px;
  }

  .box-cont h1 {
    padding-bottom: 0px;
  }

  .theme.summer ._car {
    right: -45vw;
    bottom: 40px;
    width: 90vw;
  }
  @keyframes shaking {
    0%,
    20% {
      bottom: 20vw;
    }
    21% {
      bottom: 19.5vw;
    }
    22.5% {
      bottom: 20vw;
    }
    25% {
      bottom: 19.5vw;
    }
    27.5% {
      bottom: 20vw;
    }
    30% {
      bottom: 19.5vw;
    }
    32.5% {
      bottom: 20vw;
    }
    35% {
      bottom: 19.5vw;
    }
    37.5% {
      bottom: 20vw;
    }
    40% {
      bottom: 19.5vw;
    }
    42.5% {
      bottom: 20vw;
    }
    45% {
      bottom: 19.5vw;
    }
    47.5% {
      bottom: 20vw;
    }
    50% {
      bottom: 19.5vw;
    }
    51% {
      bottom: 20vw;
    }
    51%,
    100% {
      bottom: 20vw;
    }
  }

  .theme.summer ._flower {
    height: 40vw;
  }

  #content.bg-fall {
    background-position: 59% bottom;
    background-size: cover;
  }
}

@media screen and (max-width: 960px) {
  .login-promote .item dd span,
  .login-promote .item dt {
    color: #fff;
  }
}

@media screen and (max-width: 900px) {
  #content.bg-space {
    min-height: calc(100vh - 10.55rem) !important;
    background-size: cover;
    height: auto;
    background-position: 100% 0;
  }

  .bg-motion {
    background: 0 0;
  }

  .section-login {
    height: 100%;
  }

  .section-login:last-of-type {
    margin-bottom: inherit;
  }

  .section-login .box {
    position: relative;
    display: inline-block;
    width: 70%;
    height: auto;
    background: rgba(51, 54, 63, 0.95);
  }

  .pull-size {
    width: 116%;
    margin-left: -8%;
    margin-top: 20px;
  }

  .wrapper-input.link.pull-size {
    width: 100%;
    margin-left: 0;
    left: 0;
  }

  .box-cont.left {
    float: none;
    width: 100%;
    border-top-right-radius: 0.875rem;
    border-bottom-right-radius: 0.875rem;
    background: 0 0;
  }

  .box-cont.right {
    display: none !important;
  }

  .section-login .wrapper-input.save-id {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .tui-datepicker {
    z-index: 99;
    left: 50%;
    top: -212px;
    margin-left: -136px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .ul-step li:last-child {
    margin-right: -1.45rem;
  }

  .step-small {
    margin-top: -36px;
  }
}

@media screen and (orientation: portrait) and (max-width: 1024px) {
  #content.bg-space {
    background-position: 100% -20%;
    background-size: auto 100%;
  }

  #gsp_canvas {
    display: none;
  }

  .section-login {
    height: 100%;
  }

  .section-login .box {
    width: 80%;
    height: auto;
  }
}

@media screen and (orientation: portrait) and (max-width: 900px) {
  #content.bg-space {
    background-position: 100% 0;
    background-size: cover;
  }

  .section-login {
    height: 100%;
    // min-height: calc(100vh + 3rem);
  }

  .section-login:last-of-type {
    margin-bottom: inherit;
  }

  .section-login .box {
    position: relative;
    display: inline-block;
    width: 70%;
    height: auto;
    margin-left: 15%;
  }

  .section-login .box.wide {
    width: 70%;
    left: inherit;
    margin-left: 15%;
  }

  .box-cont.left {
    float: none;
    width: 100%;
    border-top-right-radius: 0.875rem;
    border-bottom-right-radius: 0.875rem;
  }

  .box-cont.right {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .bg-motion {
    background: 0 0;
  }

  .section-login .box,
  .section-login .box.wide {
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 375px) {
  .btn.small {
    width: 9rem;
    margin-left: 1rem;
  }
}

// @media screen and (orientation: landscape) and (max-width: 1366px) {
//   .section-login {
//     min-height: calc(110vw + 3rem);
//   }
// }

// @media screen and (orientation: landscape) and (max-width: 1024px) {
//   .section-login {
//     min-height: calc(110vw + 3rem);
//   }
// }

// @media screen and (orientation: landscape) and (max-aspect-ratio: 8/5) and (max-width: 1366px) {
//   .section-login {
//     min-height: calc(100vw - 3rem);
//   }
// }

// @media screen and (max-aspect-ratio: 4/3) and (min-width: 1024px) {
//   .section-login {
//     min-height: calc(110vh);
//   }
// }

// @media screen and (max-aspect-ratio: 3/2) and (min-width: 1024px) {
//   .section-login {
//     min-height: calc(110vw);
//   }
// }

// @media screen and (orientation: landscape) and (max-width: 900px) {
//   .section-login {
//     min-height: calc(110vw);
//   }
// }

// @media screen and (orientation: landscape) and (max-width: 640px) {
//   .section-login {
//     min-height: calc(137vw);
//   }
// }

.bg-motion {
  background: 0 0;
}

.section-login {
  z-index: 999 !important;
}

#gsp_canvas {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

#content.bg-space {
  position: relative;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
