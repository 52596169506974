@charset "UTF-8";
body {
  margin: 0;
  padding: 0; /* font-family:'Microsoft Yahei',"Helvetica Neue",Helvetica, Arial,'dotum','NotoKr', sans-serif; f */
  font-size: 13px;
  color: #8e9297;
}
#container {
  width: 96%;
  margin: 3rem auto 0;
  padding-bottom: 30px;
  line-height: 1.7;
}

/* 날짜 선택 */
#ver1 {
  letter-spacing: -0.5px;
}
.policy {
  text-align: justify;
}
.policy .wrap-select {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: right;
  border-bottom: 1px solid #dedede;
}
.policy .wrap-select .sel-category {
  width: 140px;
  margin: 0;
  padding: 0px 7px;
  font-size: 14px;
}
.policy .wrap-select .sel-version {
  width: 140px;
  margin: 0px 0px 0 8px;
  padding: 0px 7px;
  font-size: 14px;
}
.policy .contents {
  padding: 0;
  font-size: 13px;
}

.policy h1 {
  padding: 15px 0 15px 0;
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: bold;
  border-bottom: 5px solid #00bfff;
  color: #00bfff;
}
.policy h2 {
  margin: 22px 0 15px;
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: bold;
  color: #8e9297;
}
.policy h3 {
  margin: 22px 0 15px;
  font-size: 14px;
  letter-spacing: -1px;
  font-weight: bold;
}
.policy h4 {
  margin: 22px 0 15px;
  font-size: 14px;
  letter-spacing: -1px;
  font-weight: bold;
}
.policy h3.underline {
  text-decoration: underline;
  color: #888;
}
.policy .tw {
  line-height: 1.4;
}
.policy .tw h1 {
  letter-spacing: 1px;
  color: #8e9297;
}
.policy .tw h2 {
  letter-spacing: 1px;
  color: #8e9297;
}
.policy .tw h3 {
  letter-spacing: 1px;
  color: #8e9297;
}

.policy ul {
  padding-left: 0;
}
.policy ul li {
  padding-left: 16px;
  text-indent: -13px;
  list-style: none;
  word-break: keep-all;
}
.policy ul.no_indent li {
  padding-left: 0;
  text-indent: 0;
  line-height: 1.7;
}
.policy ul.no_indent p {
  margin: 0;
  padding-left: 14px;
  text-indent: 0;
  color: #888;
}
.policy li .strong {
  font-weight: bold;
}
.policy li .num {
  font-weight: bold;
  padding-right: 0px;
  width: 30px;
}

.policy li ul.no_indent li {
  padding-left: 0;
}
.policy li ul.no_indent li p {
  padding-left: 0;
}

.policy li ol.li_indent {
  list-style: none;
  counter-reset: numList;
}
.policy li ol.li_indent li {
  position: relative;
  line-height: 1.7;
  text-indent: 0px;
  padding-left: 16px;
}
.policy li ol.li_indent li:before {
  counter-increment: numList;
  content: counter(numList);

  float: left;
  position: absolute;
  top: 5px;
  left: 0px; /*-16*/

  font: normal 10px sans-serif;
  text-align: center;
  color: #888;
  line-height: 11px;
  text-indent: -3px;
  letter-spacing: -2px;

  width: 11px;
  height: 11px;
  background: none;
  border: 1px solid #888;

  -moz-border-radius: 999px;
  border-radius: 999px;
}

.policy li ol.li_indent li p {
  margin: 0 0 0 0;
  text-indent: 0px;
  color: #888;
}
p.no_indent {
  text-indent: 0; /* padding-left:8px; */
}
p.no-indent span.strong {
  font-weight: bold;
}
ul li p.no_indent,
ol li p {
  color: #888;
}
.policy_tbl {
  table-layout: fixed;
  word-break: break-word;
  width: 100%;
  text-indent: 0;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
}
.policy_tbl tr th {
  border: 1px solid #cdcdcd;
  border-top: 5px solid #f49e0b;
  border-bottom: 1px solid #f49e0b;
  text-align: center;
  padding: 8px 0 8px 5px;
  background: none;
  word-break: break-word;
  text-indent: 0;
  background: #f5f5f5;
}
.policy_tbl tr td {
  text-align: center;
  padding: 6px 0 6px 0px;
  border: 1px solid #cdcdcd;
  word-break: break-word;
  text-indent: 0;
}
.policy_tbl tr td:first-child,
.policy_tbl tr th:first-child {
  border-left: none;
}
.policy_tbl tr td:last-child,
.policy_tbl tr th:last-child {
  border-right: none;
}
.policy_tbl tr:nth-child(1) th {
  border-bottom: 1px solid #f49e0b;
}
.policy_tbl tr:nth-child(1) td {
  border-top: 1px solid #f49e0b;
}
.policy_tbl tr:last-child td {
  border-bottom: 1px solid #f49e0b;
}

.policy .contents ul p a {
  text-decoration: none;
  color: #ff9922;
}
.policy .contents ul li a {
  text-decoration: underline;
  color: #ff9922;
}

.policyTbl {
  margin: 0 35px 0 30px;
  margin-bottom: 18px;
  border: 1px solid #333;
}
.policyTbl caption {
  text-indent: -9999em;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #222;
}
.policyTbl th {
  height: 30px;
  background: #f5f5f5;
  color: #222;
  font-weight: bold;
}
.policyTbl th,
.policyTbl td {
  /*display:table-cell;*/
  border: 1px solid #333;
  text-align: center;
  word-break: keep-all;
}
.policyTbl .policyTitle {
  text-align: left;
  padding-left: 8px;
  background-color: #ddd;
}
.policyTbl .policyTitle2 {
  font-weight: bold;
  background-color: #c8c8c8;
  line-height: 150%;
  text-align: left;
}
.policyTbl .tdLeft p {
  text-align: left;
  padding-right: 10px;
}

.go-article {
  overflow: hidden;
}
.go-article li {
  box-sizing: border-box;
  width: 50%;
  float: left;
}

// #PaymentMethodPop{z-index: 9999;display: none;opacity:0;position: fixed;left: 0;right: 0;top: 0;bottom: 0;text-align: center;}
// #popupWrap{background-color:#fff;position:relative;display: inline-block;vertical-align:middle;width:500px;height:auto;padding-bottom: 60px;background-color:#fff; border:1px solid #c9c9c9;}
// #popupWrap h1{text-indent: 0;padding:15px;border-bottom:5px solid #00bfff;text-align:left;color:#00bfff;}
// #popupWrap .popupInner{padding:15px;}
// #popupWrap .popupInner .txtArea{width: 100%;height: 360px;border:none; border-bottom: 1px solid #d1d1d1;background: #fff;color: #666;line-height: 20px;text-align: left;/* margin-left: 16px; */padding:0;font-size: 13px;}
// #popupWrap .popupInner .confirm{position:relative;width:100%;}
// #popupWrap .popupInner .confirm a{position: absolute;top: 19px;left: 50%;margin-left: -50px;padding: 5px 0;width: 100px;line-height: 30px;text-align: center;text-indent:0;}
// #popupWrap .popupInner .confirm a, #popupWrap .popupInner .confirm a:visited{text-align:center;background:#ff9900;color:#fff;text-decoration:none;}
// #popupWrap .popupInner .confirm a:hover, #popupWrap .popupInner .confirm a:focus{text-align:center;background:#df8104;color:#fff;text-decoration:none;}
// #popupWrap .closed{position:absolute;top:20px;right:15px;text-decoration:none;}
// #popupWrap .closed a{color:#8e9297}
// #popupWrap .closed a:hover{color:#ff9900;}
// #pvShadow{display: none;opacity:0;width: 100%;height: 100%;position: fixed;padding-bottom: 0px;top: 0;left: 0;z-index: 9998;background-size: cover;background: rgba(0,0,0,0.70);}

.cont-before,
.cont-before:visited {
  font-size: 1.125rem;
  color: #00bfff;
  text-decoration: underline;
}
.cont-before:focus,
.cont-before:hover {
  font-size: 1.125rem;
  color: #00bfff;
}
.indent {
  padding-left: 32px;
  padding-left: 2rem;
}
@media screen and (max-width: 1024px) {
  #container {
    margin: 0 auto;
  }
  .go-article li {
    box-sizing: border-box;
    width: 100%;
    float: none;
  }
}

@media screen and (max-width: 414px) {
  .policy .wrap-select {
    text-align: center;
  }
  .policy .wrap-select .sel-category {
    width: 130px;
    margin: 0;
    padding: 0px 7px;
    font-size: 14px;
  }
  .policy .wrap-select .sel-version {
    width: 130px;
    margin: 0;
    padding: 0px 7px;
    font-size: 14px;
  }
}
@media screen and (max-width: 360px) {
  .policy .wrap-select .sel-category {
    width: 120px;
    margin: 0;
    padding: 0px 7px;
    font-size: 14px;
  }

  .policy .wrap-select .sel-version {
    width: 120px;
    margin: 0;
    padding: 0px 7px;
    font-size: 14px;
  }
}
