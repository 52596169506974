@charset "utf-8";

#google_otp {
  section h1 {
    overflow-x: hidden;
    height: auto;
    min-height: 25px;
    line-height: 1.5rem;
    padding-bottom: 14px;
  }
  section p.message {
    margin-top: 3.5rem;
    margin-bottom: 2.5rem;
    text-align: center;
    font-size: 1.125rem;
    color: #33363f;
  }
  section ul + p.message {
    margin-bottom: 1rem;
  }
  section p.message small {
    display: block;
    margin-top: 0.75rem;
    color: #6e788e;
    font-size: 0.875rem;
  }

  .sub-top {
    margin-top: 0;
  }
  .sub-top .row-w {
    position: relative;
    /*padding:2.5rem 1.5rem 3.5rem;*/
    padding: 46px 56px;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/otp/bg-otp-min.jpg)
      center top no-repeat;
    background-size: cover;
    text-align: center;
  }
  .sub-top .row-w:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: #4271f5;
    background: -moz-linear-gradient(
      left,
      #4271f5 0%,
      #60abf8 44%,
      #2dc1ff 100%
    );
    background: -webkit-linear-gradient(
      left,
      #4271f5 0%,
      #60abf8 44%,
      #2dc1ff 100%
    );
    background: linear-gradient(
      to right,
      #4271f5 0%,
      #60abf8 44%,
      #2dc1ff 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4271f5", endColorstr="#2dc1ff", GradientType=1);
  }
  .sub-top .ph {
    line-height: 1.8;
    color: #bdc2d2;
    font-size: 0.875rem;
    letter-spacing: 0;
    margin-top: 1rem;
  }
  .sub-top .ph strong {
    font-weight: 400;
  }

  .sub-cont .row-w {
    padding: 2.875rem 3.5rem;
    background: #fff;
    text-align: center;
  }
  .sub-cont h2 {
    font-size: 1.25rem;
    color: #3b3e4a;
    vertical-align: middle;
  }
  .sub-cont h2 span {
    vertical-align: middle;
  }
  .sub-cont h2 .bar {
    display: inline-block;
    margin-bottom: 1.25rem;
    width: 1.875rem;
    height: 3px;
    background-color: #6586e2;
  }
  .sub-cont h2 .step {
    display: inline-block;
    margin-bottom: 1rem;
    padding: 0.45rem 0.875rem;
    font-size: 0.875rem;
    color: #fff;
    font-weight: normal;
    background: #6586e2;
    border-radius: 1rem;
    vertical-align: middle;
  }

  .icon.otp {
    display: inline-block;
    width: 5.3rem;
    height: 5.125rem;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/otp/icon-otp-min.png)
      center center no-repeat;
    background-size: cover;
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    padding: 3rem 2.125rem;
    background: #f3f6fd;
    border-radius: 0.25rem;
  }
  .box.text-center {
    flex-direction: column;
  } /* 다른 박스가 없는지 확인 필요 */
  ul.box {
    flex-wrap: wrap;
  }
  #games .game-list {
    float: left;
    margin-bottom: 1.25rem;
    margin-left: 1.5%;
    width: 13%;
    border-radius: 50%;
    text-align: center;
  }
  #games .game-list:nth-child(7n + 1) {
    margin-left: 0;
  }
  #games .circle {
    width: 80%;
    border-radius: 50%;
    border: 3px solid #4271f5;
  }
  #games .info {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    letter-spacing: 0;
  }
  #games .tit {
    margin-bottom: 0.3755rem;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.4;
    font-size: 0.75rem;
    color: #33363f;
    text-transform: uppercase;
  }
  #games .lang {
    letter-spacing: 0;
    font-size: 0.6875rem;
    color: #6e788e;
  }

  #steps {
    padding-left: 15%;
    padding-right: 15%;
  }
  #steps .step-list {
    position: relative;
    float: left;
    margin-left: 2.5%;
    width: 18%;
    border-radius: 50%;
    text-align: center;
  }

  #steps .step-list:first-child {
    margin-left: 0;
  }
  #steps .circle {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #4271f5;
    background: -moz-linear-gradient(
      left top,
      #2dc1ff 0%,
      #366af5 44%,
      #4271f5 100%
    );
    background: -webkit-linear-gradient(
      left top,
      #2dc1ff 0%,
      #366af5 44%,
      #4271f5 100%
    );
    background: linear-gradient(
      left top,
      #2dc1ff 0%,
      #366af5 44%,
      #4271f5 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2dc1ff", endColorstr="#4271f5", GradientType=1);
  }
  #steps .step-list:first-child:before {
    display: none;
  }
  #steps .step-list:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    left: -1.5rem;
    top: 1rem;
    background: url("https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/otp/arrow-otp-min.png")
      center no-repeat;
  }
  #steps .step-list:nth-of-type(2) .circle,
  #steps .step-list:nth-of-type(3) .circle,
  #steps .step-list:nth-of-type(4) .circle {
    line-height: 2.8;
  }
  #steps .step-list:nth-of-type(1) .circle,
  #steps .step-list:nth-of-type(5) .circle {
    line-height: 3;
  }
  #steps .circle .icon {
    height: 60%;
    vertical-align: middle;
  }
  #steps .info {
    clear: left;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    letter-spacing: 0;
    line-height: 1.4;
    height: 2.2rem;
    font-size: 0.75rem;
    color: #33363f;
    text-transform: uppercase;
  }
  /* 앱 다운로드 */
  .otp-downloads {
    box-sizing: border-box;
    width: 100%;
    margin-top: 0.85rem;
    margin-bottom: 3rem;
    padding: 0;
    /*  border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      background: #fff;*/
  }
  .otp-downloads span {
    display: inline-block;
    margin-left: 1rem;
    vertical-align: middle;
  }
  .otp-downloads span:first-child {
    margin-left: 0;
  }
  .otp-downloads span:nth-child(3) {
    margin-left: 1.5rem;
  }

  .otp-downloads span:nth-child(1) img,
  .otp-downloads span:nth-child(1) a:hover img {
    height: 4rem;
    vertical-align: middle;
  }
  .otp-downloads span:nth-child(2) img,
  .otp-downloads span:nth-child(2) a:hover img {
    height: 4rem;
    vertical-align: middle;
  }
  .otp-downloads span:nth-child(3) img {
    width: 6rem;
    height: 6rem;
    border: 2px solid #b4b5b7;
    vertical-align: middle;
  }

  .otp-downloads figcaption {
    display: inline-block;
    position: absolute;
    height: 1px;
    top: -1px;
    text-indent: -9999em;
  }
  .otp-downloads span:nth-child(3) figcaption {
    position: relative;
    display: block;
    margin-top: 0.75rem;
    height: inherit;
    top: inherit;
    font-size: 0.75rem;
    text-indent: initial;
    color: #6e788e;
  }

  input[type="text"].otp-6 {
    max-width: 334px;
    min-width: 200px;
    width: 80%;
    padding: 0.75rem 1.5rem;
    border: 3px solid #bdc2d2;
    border-radius: 1.75rem;
    text-align: center;
    letter-spacing: 0.5rem;
  }
  input[type="text"].otp-6.put-auth-num {
    min-width: 230px;
  }
  input[type="text"].otp-6::placeholder {
    letter-spacing: 0;
  }
  input[type="text"].otp-6:-ms-input-placeholder {
    letter-spacing: 0;
  }
  input[type="text"].otp-6::-ms-input-placeholder {
    letter-spacing: 0;
  }
  input[type="text"].otp-6:-moz-placeholder {
    letter-spacing: 0;
  }
  input[type="text"].otp-6::-moz-placeholder {
    letter-spacing: 0;
  }
  input[type="text"].otp-6::-webkit-input-placeholder {
    letter-spacing: 0;
  }

  /* 앱설정 */
  .border-box {
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: 2px solid #f3f6fd;
  }
  .border-box .box {
    margin-top: 0;
  }
  .box .message {
    position: relative;
    width: 100%;
  }
  .box .circle-bg {
    display: inline-block;
    border-radius: 15px;
    color: #fff;
    font-size: 11px;
    padding: 7px 33px 5px;
    background: #4271f5;
  }

  .setting-steps {
    float: left;
    width: 30%;
    margin-left: 5%;
  }
  .setting-steps:nth-of-type(1) {
    margin-left: 0;
  }

  .circle-line {
    position: absolute;
    width: 70%;
    height: 1px;
    background: blue;
    left: 15%;
    top: 1.5rem /*.setting-steps .circle  height의 1/2 */;
  }
  .setting-steps .circle {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    margin-bottom: 1.125rem;
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
    background: #4271f5;
    background: -moz-linear-gradient(
      left top,
      #2dc1ff 0%,
      #366af5 44%,
      #4271f5 100%
    );
    background: -webkit-linear-gradient(
      left top,
      #2dc1ff 0%,
      #366af5 44%,
      #4271f5 100%
    );
    background: linear-gradient(
      left top,
      #2dc1ff 0%,
      #366af5 44%,
      #4271f5 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2dc1ff", endColorstr="#4271f5", GradientType=1);
  }
  .setting-steps .txt {
    font-size: 0.875rem;
    line-height: 1.7;
    color: #3b3e4a;
  }

  /* otp-settings */
  .otp-settings {
    box-sizing: border-box;
    width: 100%;
    margin-top: 2rem;
    padding: 0 0 2rem;
    background: #fff;
  }
  .otp-settings span.setting-cont {
    position: relative;
    display: inline-block;
    margin-left: 3rem;
    padding-bottom: 0;
    font-size: 16px;
    height: 8rem;
    vertical-align: middle;
  }
  .otp-settings span.setting-cont:first-child {
    margin-left: 0;
  }
  .otp-settings span.setting-cont:nth-child(2) {
    line-height: 8rem;
    padding-top: 2.5rem;
    font-size: 12px;
    color: #bdc2d2;
  }
  .otp-settings span.setting-cont:nth-child(3) {
    margin-left: 0.5rem;
  }
  .otp-settings span.setting-cont:nth-child(3) {
    margin-left: 2.5rem;
    max-width: 240px;
    max-width: 15rem;
  } /* 중앙정렬 */
  .otp-settings span.setting-cont:first-child img {
    width: 8rem;
    vertical-align: middle;
  }
  .otp-settings span.setting-cont sub {
    display: inline-block;
    line-height: 4rem;
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #808080;
  }
  .otp-settings span.setting-cont sub em {
    margin-left: 0.5rem;
    font-weight: bold;
    color: #000;
  }
  .otp-settings span.setting-cont:nth-of-type(3) sub em {
    padding: 0.75rem 2.5rem;
    border: 3px solid #bdc2d2;
    border-radius: 1.75rem;
    font-weight: normal;
    color: #33363f;
  }
  .otp-settings + .added {
    margin-top: 0.25rem;
    padding-left: 1rem;
    font-size: 12px;
    color: #6586e2;
  }

  .otp-settings figcaption {
    display: inline-block;
    position: absolute;
    height: 1px;
    top: -1px;
    text-indent: -9999em;
  }
  .otp-settings b {
    display: inline-block;
    padding: 0.5rem 0 0;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    color: #3b3e4a;
    font-size: 0.875rem;
  }
  .otp-settings figure {
  }

  /* 인포메이션 : stamp 공통  */
  section.info {
    margin-top: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
  }
  section.info h1 {
    font-size: 1rem;
    color: #4f4f4f;
  }
  .info .row-w {
    padding: 46px 56px;
    background: #fff;
    border-top: none;
  }
  ul.show-info {
    padding-left: 1rem;
  }
  ul.show-info li {
    position: relative;
    list-style: circle;
    font-size: 13px;
    color: #444;
    padding-left: 0px;
    line-height: 1.5;
    text-align: left;
    color: #444;
  }

  :lang(tw) ul.show-info li {
    word-break: break-all;
  }
  :lang(jp) ul.show-info li {
    word-break: break-all;
  }
  :lang(ko) ul.show-info li {
    word-break: keep-all;
  }
  :lang(tr) ul.show-info li {
    word-break: keep-all;
  }
  :lang(fr) ul.show-info li {
    word-break: keep-all;
  }
  :lang(de) ul.show-info li {
    word-break: keep-all;
  }
  :lang(en) ul.show-info li {
    word-break: keep-all;
  }

  ul.show-info li a {
    color: #139bb8;
  }
  ul.show-info li a:hover {
    color: #139bb8;
    text-decoration: underline;
  }
  ul.show-info li img {
    width: 13px;
    height: 13px;
    vertical-align: middle;
  }
  ul.show-info li .info-img {
    position: absolute;
    box-sizing: border-box;
    z-index: 1;
    left: 200px;
    padding: 1.5rem 2rem 1rem;
    box-sizing: border-box;
    border: 1px solid #d4d4d4;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background: #fff;
    text-align: center;
    color: #a2a5ac;
  }

  ul.show-info li .info-img a {
    color: #d4d4d4;
    font-size: 16px;
  }
  ul.show-info li .info-img a:hover {
    color: #139bb8;
    font-size: 16px;
  }
  ul.show-info li .info-img.dim {
    display: none;
    top: -86px;
    left: 100px;
    width: 220px;
    min-height: 155px;
  }
  ul.show-info li .info-img.active {
    display: none;
    top: -70px;
    left: 100px;
    width: 220px;
    min-height: 155px;
  }
  ul.show-info li .info-img img {
    width: 96px;
    height: 96px;
  }

  /* 버튼 라운드 타입 : sns_login 공통 */
  .btn.round-type {
    outline: none;
    box-sizing: border-box;
    width: auto;
    height: auto;
    line-height: inherit;
    min-width: 7.5rem;
    margin-top: 2rem;
    padding: 0.9375rem 2rem;
    border-radius: 1.75rem;
    font-size: 0.875rem;
    align-items: flex-start;
    color: #fff;
    font-weight: normal;
    background: #6e788e /*#6586e2*/;
    text-transform: initial;
    letter-spacing: 0;
  }
  .btn.round-type.long {
    min-width: 13.875rem;
  }
  .btn.round-type:nth-of-type(2) {
    margin-left: 2rem;
  }
  .btn.round-type:hover,
  .btn.round-type:focus {
    background: #4271f5;
    color: #fff;
  }

  .valid-type-not {
    display: inline-block;
    background: #6e788e;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
    pointer-events: none;
  }
  .valid-type-not:hover,
  .valid-type-not:focus {
    background: #6e788e;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
    pointer-events: none;
  }
  .valid-type-pass {
    display: inline-block;
    background: #6586e2;
  }
  .valid-type-active {
    display: inline-block;
    background: #4271f5;
    color: #fff;
  }

  .valid-type-pass:hover,
  .valid-type-pass:focus,
  .valid-type-active:hover,
  .valid-type-active:focus {
    background: #4271f5;
    color: #fff;
  }

  .btn.round-type.long .fas.fa-angle-left {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
  .btn.round-type.long .fas.fa-angle-right {
    margin-left: 0.25rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  #google_otp {
    /* 서비스 안내 */
    #steps {
      padding-left: 0;
      padding-right: 0;
    }
    /* otp 탈퇴 완료 */
    .box {
      padding: 2rem;
    }
    #games .game-list {
      width: 18.5%;
    }
    #games .game-list:nth-child(7n + 1) {
      margin-left: 1.5%;
    }
    #games .game-list:nth-child(5n + 1) {
      margin-left: 0;
    }
    section ul + p.message {
      margin-bottom: 0.5rem;
    }
  }
}
@media screen and (max-width: 768px) {
  #google_otp {
    .sub-cont .row-w {
      padding: 1.875rem 1.5rem;
    }
    /* 앱 설치 하기*/
    section p.message {
      margin-top: 1.5rem;
    }
    .otp-downloads {
      margin-bottom: 6rem;
    }
    .otp-downloads span:first-child,
    .otp-downloads span {
      margin-top: 11rem;
    }
    .otp-downloads span:nth-child(3) {
      display: block;
      margin-left: 0;
      margin-top: -14rem;
    }

    /* btn.long*/
    .btn.round-type.long {
      min-width: 10rem;
    }
  }
}

@media screen and (max-width: 640px) {
  #google_otp {
    .btn.round-type:nth-of-type(2) {
      margin-left: 1rem;
    }

    /* otp 탈퇴 완료 */
    .box {
      padding: 2rem 1rem;
    }
    #games .game-list {
      width: 23.5%;
    }
    #games .game-list:nth-child(5n + 1) {
      margin-left: 1.5%;
    }
    #games .game-list:nth-child(4n + 1) {
      margin-left: 0%;
    }
  }
}

@media screen and (max-width: 578px) {
  #google_otp {
    .otp-settings span.setting-cont {
      margin-left: 0;
    }
    .otp-settings span.setting-cont:nth-child(2) {
      display: none;
    }
    .otp-settings span.setting-cont:nth-of-type(3) sub em {
      padding: 0.75rem 1rem;
    }

    .setting-steps .circle {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1rem;
    }
    .circle-line {
      display: none;
    }
    .setting-steps {
      margin-top: 1.5rem;
      width: 100%;
      margin-left: 0;
    }
    .setting-steps:nth-of-type(1) {
      margin-top: 0;
      width: 100%;
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 460px) {
  #google_otp {
    /* 앱 설치 하기*/
    .btn.round-type.long {
      min-width: 8rem;
    }
    .otp-downloads {
      margin-bottom: 6rem;
    }
    .otp-downloads span:first-child,
    .otp-downloads span {
      margin-top: 10rem;
    }
    .otp-downloads span:nth-child(3) {
      margin-top: -13rem;
    }
    .otp-downloads span:nth-child(1) img,
    .otp-downloads span:nth-child(1) a:hover img,
    .otp-downloads span:nth-child(2) img,
    .otp-downloads span:nth-child(2) a:hover img {
      height: 3rem;
    }

    /* otp 탈퇴 완료 */
    .box {
      padding: 2rem 1rem;
    }
    #games .game-list {
      width: 32.333%;
    }
    #games .game-list:nth-child(4n + 1) {
      margin-left: 1.5%;
    }
    #games .game-list:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 414px) {
  #google_otp {
    .otp-settings span.setting-cont:nth-child(3) {
      margin-top: 3rem;
    }
    .otp-settings span.setting-cont:nth-child(3) {
      margin-left: 0;
    } /* 중앙정렬 */
  }
}
@media screen and (max-width: 400px) {
  #google_otp {
    /* 앱 설치 하기*/
    .otp-downloads {
      margin-bottom: 6rem;
    }
    .otp-downloads span:first-child,
    .otp-downloads span {
      margin-top: 10rem;
    }
    .otp-downloads span:nth-child(3) {
      margin-top: -13rem;
    }
    .otp-downloads span:nth-child(1) img,
    .otp-downloads span:nth-child(1) a:hover img,
    .otp-downloads span:nth-child(2) img,
    .otp-downloads span:nth-child(2) a:hover img {
      height: 2.5rem;
    }
  }
}
