@charset "utf-8";
.sorting-nav h1 {
  color: #fff;
  letter-spacing: -0.5px;
  width: 50%;
  float: left;
  overflow-x: hidden;
  height: auto;
  min-height: 25px;
  line-height: 1.5rem;
  padding-bottom: 14px;
}

.sorting-nav {
  display: block;
  width: 100%;
  // margin-top: 7.5rem;
  padding-top: 4rem;
}

.sorting-nav ul {
  float: right;
  display: inline-block;
  width: auto;
}

.sorting-nav ul li {
  float: left;
  cursor: pointer;
}

.sorting-nav ul li:before {
  content: "|";
  vertical-align: middle;
  display: inline-block;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: normal;
  color: #68788e;
}

.sorting-nav ul li:first-child:before {
  display: none;
}

.sorting-nav ul li:first-child {
  margin-left: 0rem;
}

.sorting-nav ul li a {
  display: inline-block;
  position: relative;
  z-index: -1;
  color: #68788e;
  font-size: 14px;
  font-weight: 500;
}

.sorting-nav ul li a .fa {
  display: inline-block;
  color: #68788e;
  font-size: 14px;
  font-weight: 500;
}

.sorting-nav ul li.on a {
  color: #bdc2d2;
}

.sorting-nav .list-icon {
  margin-right: 5px;
}

.sorting-nav ul li a:hover .fa,
.sorting-nav ul li.on a .fa {
  display: inline-block;
  color: #bdc2d2;
  font-size: 14px;
  font-weight: 500;
}

.sorting-nav {
  select {
    webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    float: right;
    box-sizing: border-box;
    padding: 0px 14px;
    width: calc(100% - 30px);
    max-width: 215px;
    border: 1px solid #b6ccd1;
    color: #4d6b72 !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    height: 37px;
    line-height: 37px;
    vertical-align: middle;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background: #fff
      url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/stamp/arrow-default.png)
      no-repeat 218px 50%;
  }
}
