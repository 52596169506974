@charset "UTF-8";

.view-popup {
  scroll-behavior: smooth;
  z-index: 9999 !important;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;

  * {
    box-sizing: border-box;
  }

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }

  .view-shadow {
    display: none;
    position: fixed;
    opacity: 1;
    // z-index: -1;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    top: 0;
    left: 0;
    background-size: cover;
    background: rgba(0, 0, 0, 0.6);
  }

  .agree-check {
    font-size: 0.75rem;
    margin-top: 0.625rem;

    * {
      margin-right: 4px;
    }

    div {
      color: var(--gray-100);
      cursor: pointer;
    }

    svg {
      cursor: pointer;

      &.true {
        color: var(--primary-200);
      }
    }

    a {
      color: var(--primary-200);
      text-decoration: underline;
    }
  }

  .slick-arrow {
    border-radius: 99px;
    background: rgba(0, 0, 0, 0.5);
    width: 2.25rem;
    height: 2.25rem;
    margin: auto 0;
  }

  .slick-next:before,
  .slick-prev:before {
    content: ">";
    font-family: cursive;
    font-size: 17px;
  }

  .slick-prev:before {
    content: "<";
  }

  .slick-prev {
    left: 0;
    z-index: 1;
  }

  .slick-next {
    right: 0;
  }

  .yellow-tag {
    border-radius: 10px 0px;
    background: #ffc700;
    font-size: 12px;
    color: var(--Gray900, #121212);
    font-weight: 700;
    padding: 4px 10px;
    position: absolute;
    left: 0;
  }

  &.sms {
    .pop-sizing {
      overflow: hidden;
    }

    .pop-cont {
      width: 38%;
      overflow: hidden;
      text-align: start;
    }

    .pop-article {
      p {
        color: var(--gray-100);
        font-weight: bold;
      }

      .select {
        min-width: 180px;
        margin-right: 8px;
      }

      .country-name {
        width: 80px;
        margin-right: 10px;
      }

      .btn {
        background: transparent;
        border: 1px solid var(--primary-200);
        border-radius: 20px;
        padding: 0.5rem;
        color: var(--primary-200);
        // display: block;
        // width: 20%;
        // max-width: 90px;
        width: 90px;
        cursor: pointer;

        &.active {
          background: var(--primary-200);
          color: var(--gray-100);
        }
      }

      .num-auth {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        &.valid-pass {
          cursor: default;
          pointer-events: none;
        }

        .loading {
          display: flex;
          align-items: center;

          .timer {
            margin-left: 5px;
          }
        }
      }

      .agree-check {
        display: flex;
        align-items: center;
      }

      .txt {
        font-size: 0.75rem;
        color: var(--gray-100);
        margin-top: 7px;

        svg {
          vertical-align: middle;
          margin-right: 3px;
          margin-left: -1px;
        }
      }

      .row {
        width: calc(100% - 180px);

        .btn {
          display: inline-block;
        }

        .num-input {
          width: calc(100% - 98px);
          display: inline-flex;
        }
      }

      .num-input {
        margin-right: 8px;
        display: flex;
        align-items: center;
      }

      .step-2,
      .step-3 {
        margin-top: 2.5rem;

        &.valid-not {
          cursor: default;
          pointer-events: none;

          p,
          .timer {
            color: var(--gray-500);
          }

          button {
            border: 1px solid var(--gray-500);
            color: var(--gray-500);
          }
        }

        .num-input {
          width: calc(100% - 98px);
        }
      }

      .step-2 {
        p span {
          font-size: 0.75rem;
          color: var(--primary-200);
        }
      }

      .step-3 .timer {
        color: var(--primary-200);
      }
    }

    .step-4 {
      text-align: center;

      .gray-small {
        font-size: 0.875rem;
        color: var(--gray-400);
        margin-top: 1rem;
      }

      .purple-btn {
        width: 100%;
      }
    }
  }

  &.landing {
    .agree-check {
      position: absolute;
      top: 1.25rem;
      right: 4rem;
      cursor: pointer;
      z-index: 2;
    }

    #viewUtil {
      z-index: 2;
    }

    .pop-article {
      overflow: hidden;

      .slick-slider {
        margin-top: 270px;
        width: 100%;
      }

      .slick-list {
        width: 90%;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .slick-slide {
        width: calc((100% / 3) - 40px) !important;
        margin-right: 10px;
        margin-left: 10px;
        overflow: hidden;
      }

      .slick-slide:last-child {
        margin-right: 0;
      }
    }

    .gray-box {
      background: var(--gray-600);
      border-radius: 10px;
      position: relative;
      cursor: pointer;

      .title {
        padding: 10px 20px;
        font-size: 0.875rem;
        text-align: center;
        width: 100%;
        min-height: 56px;
      }
    }

    .no-slick {
      display: flex;
      margin-top: 270px;
      justify-content: space-around;
      align-items: center;

      .gray-box {
        width: 40%;
      }
    }
  }

  &.snsQr {
    .pop-cont {
      width: auto;
    }

    .pop-article {
      .purple-btn {
        width: 100%;
      }

      img {
        border-radius: 14px;
      }
    }
  }

  .pop-sizing {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: auto;
    border: none;
    vertical-align: middle;
    overflow-y: scroll;
    max-height: calc(100% - 48px);
  }

  .pop-cont {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    max-width: 57.75rem;
    width: 94%;
    height: auto;
    margin-top: 1.5rem;
  }

  .pop-article {
    position: relative;
    box-sizing: border-box;
    margin: 0 0 1.5rem;
    padding: 3rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    color: #929199;
    background: #1d1d1d;

    .info {
      font-size: 0.75rem;
      text-align: left;
      margin-bottom: 1rem;

      * {
        display: inline-block;
      }

      .cate {
        margin-right: 0.5rem;
        color: #8e37ff;
        text-transform: uppercase;
      }

      .bar {
        margin-right: 0.5rem;
        width: 1px;
        height: 1rem;
        background: #3e404c;
      }
    }

    .top-area {
      text-align: left;
      border-bottom: 1px solid #35363b;
      padding-bottom: 1.25rem;
      margin-bottom: 1.25rem;

      .sub-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.25rem;

        > span {
          font-size: 0.875rem;
          color: #585858;
        }

        .purple-btn {
          width: 40%;
          margin-top: 0;
        }
      }

      .title {
        display: inline-block;
        font-size: 2rem;
        color: #fff;
        width: 100%;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .cont {
      text-align: left;
      line-height: 1.4;
      font-weight: 400;
      // min-height: 44rem;
      width: inherit;
      overflow: hidden;
      color: #929199 !important;

      * {
        color: #929199 !important;
      }
      // span {
      //   color: inherit;
      // }
      // a {
      //   color: inherit;
      // }

      img {
        max-width: 100% !important;
        height: auto !important;
      }

      table {
        max-width: 100% !important;
        border: 1px solid !important;

        tr,
        td {
          border: 1px solid !important;
        }
      }

      em {
        font-style: italic;
      }

      ol li,
      ul li {
        list-style: inherit !important;
        padding-inline-start: inherit;
      }

      ol,
      ul {
        list-style-position: inside;
        padding-inline-start: 1rem;
      }
    }

    .purple-btn {
      margin-top: 3rem;
      width: 40%;
    }
  }

  .social {
    display: flex;
    align-items: center;

    li {
      font-size: 0.75rem;
      margin-left: 0.5rem;
    }

    button {
      padding: 0.375rem 1rem;
    }

    .btn-like {
      line-height: 2px;
      border-radius: 20px;
      padding: 8px;
      border: 1px solid #929199;
      cursor: pointer;

      .fa-heart {
        font-size: 0.75rem;

        &.on {
          color: #e72982;
        }
      }
    }
  }

  #viewUtil {
    z-index: 1;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;

    .x-btn {
      width: 1.125rem;
      height: 1.125rem;
      background-color: transparent;
      background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/library/icon-x.png);
      background-size: 21px 21px;
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        background-image: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/library/icon-x-hover.svg);
        background-size: 28px 28px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .view-popup.sms .pop-cont {
    width: 50%;
  }
}

@media screen and (max-width: 1200px) {
  .view-popup .pop-article {
    padding: 3rem 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .view-popup.sms .pop-cont {
    width: 70%;
  }
}

@media screen and (max-width: 870px) {
  .view-popup.landing .pop-article .slick-slide {
    width: calc((100% / 2) - 40px) !important;
    margin-right: 0;

    &:nth-child(2) {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .view-popup.sms {
    .pop-sizing {
      padding: 0 2rem;
    }

    .pop-cont {
      width: 100%;
    }
  }
}

@media screen and (max-width: 640px) {
  .view-popup {
    &.sms {
      .pop-article {
        .country-name {
          display: none !important;
        }

        .select {
          min-width: 90px;
        }

        .row .num-input,
        .step-2 .num-input,
        .step-3 .num-input {
          width: calc(100% - 78px);
        }

        .row {
          width: calc(100% - 98px);
        }

        .btn {
          font-size: 0.75rem;
          width: 70px;
        }
      }
    }

    &.landing {
      .no-slick .gray-box {
        width: 46%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .view-popup.landing .pop-article .slick-slide {
    width: 100% !important;
  }
}

@media screen and (max-width: 440px) {
  .view-popup {
    .pop-article {
      padding: 1.5rem;

      .cont {
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .top-area {
        .title {
          font-size: 1.5rem;
        }

        .sub-area {
          > span {
            font-size: 0.75rem;
          }
        }
      }
    }

    .pop-cont {
      width: 98%;
    }

    &.sms {
      .pop-sizing {
        padding: 1rem;
      }

      .pop-cont {
        width: 100%;
      }

      .pop-article {
        .step-1 .num-auth {
          flex-direction: column;
          align-items: flex-start;
        }

        .row {
          margin-top: 6px;
          width: 100%;
        }

        .select {
          width: 100%;
          min-width: auto;
          margin-right: 0;
        }

        .country-name {
          display: inline-block !important;
          width: calc(100% - 80px);
        }
      }
    }

    &.landing {
      .pop-article .slick-slider {
        margin-top: 220px;
      }

      .no-slick {
        margin-top: 220px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .view-popup.landing {
    .pop-article .slick-slider {
      margin-top: 180px;
    }

    .no-slick {
      margin-top: 180px;
      .gray-box {
        width: 48%;
      }
    }
  }
}
