@charset "utf-8";

.shadow {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  padding-bottom: 0;
  top: 0;
  left: 0;
  background-size: cover;
  background: rgba(0, 0, 0, 0.5);
}

.layer-wrap {
  z-index: 9997 !important;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }

  .btn {
    width: 100%;
    font-weight: 700;
    border-radius: 0.625rem;
    line-height: 1;
    padding: 0.75rem 0;

    // 아래 세개는 기존 btn css 때문에 추가
    height: auto;
    margin-bottom: 0;
    text-transform: none;

    &.purple {
      background: #713fff;

      &:hover {
        background: #a585ff;
      }
    }

    &.blue {
      background: var(--primary-200);
      color: var(--gray-900);
      font-size: 14px;
      display: inline-block;

      &:hover {
        background: var(--primary-300);
      }
    }

    &.gray {
      background: #323232;

      &:hover {
        background: var(--gray-500);
      }
    }
  }
}

.pop-layer {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 500px;
  height: auto;
  border-top: 6px solid #139bb8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.pop-container {
  padding: 16px 32px 35px;
  position: relative;
}

.pop-container .skip {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  visibility: hidden;
}

.pop-container .frame {
  z-index: 9999;
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
}

.pop-container .btn-layer-close {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 23px;
  height: 24px;
  background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/popup/pop-x.png)
    0 0 no-repeat;
  background-size: contain;
  text-indent: -9999px;
  z-index: 1;
}

.pop-container .frame h1 {
  position: relative;
  padding: 5px 0 0 0;
  color: #a2a2a2;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 0.2px;
  background: 0 0;
  text-align: left;
  margin-bottom: 1.25rem;

  &.history-title {
    text-align: center;
    font-size: 1.8rem;
    color: #404040;
    font-weight: normal;
  }
}

.history-desc {
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #6f6f6f;
  font-size: 0.875rem;
  letter-spacing: -0.025rem;
}

.pop-container .frame h1 img {
  margin-right: 6px;
}

.pop-container .alert {
  width: 100%;
  // padding-top: 41px;
  padding-top: 10px;
  line-height: 1.4;
}

.pop-container .alert div {
  // margin: 0 auto;
  text-align: center;
  min-height: 60px;
}

.pop-container .alert h5 {
  box-sizing: border-box;
  width: 100%;
  -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 34px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #282828;
  background: #dece;
}

.pop-container .alert img {
  // position: absolute;
  // left: 0;
  // top: 49px;
  width: 27px;
  float: left;
}

.pop-container .alert .alert-text {
  display: inline;
  width: 100%;
  text-indent: 34px;
  margin-top: -10px;
  line-height: 1.2;
  text-align: left;
  font-size: 22px;
  font-size: 1.375rem;
  color: #a2a2a2;
}

.pop-container .alert .alert-text.text1 {
  // line-height: 1.7;
  color: #6a6a6a;
  font-size: 1rem;
  // margin-top: 10px;
  // padding-left: 21px;
  text-indent: 12px;
  width: 100%;
  // vertical-align: top;
}
// 여기

.pop-container .alert .alert-text-ph {
  display: block;
  width: 100%;
  line-height: 1.7;
  color: #6a6a6a;
  font-size: 0.875rem;
  vertical-align: top;
  text-align: left;
  text-indent: 1rem;
}

.pop-container .alert .emphasis {
  margin-bottom: 1rem;
  padding: 0.5rem;
  text-indent: 0;
  color: #78c5dd;
  background: #26262b;
  text-align: center;
}

.pop-container .alert .emphasis .small {
  font-size: 1rem;
}

.pop-container .alert .alert-list {
  width: 100%;
  line-height: 1.7;
  color: #6a6a6a;
  text-indent: 12px;
  padding-left: 21px;
  text-align: left;
}

.pop-container .alert .alert-list li {
  float: none;
  text-align: left;
  font-size: 0.875rem;
}

.pop-container .btn-wrap {
  margin-top: 50px;

  &.history-btn {
    margin-top: 0;
  }
}

.btn-wrap a.btn-alert {
  box-sizing: border-box;
  display: inline-block;
  min-width: 100px;
  font-size: 14px;
  line-height: 41px;
  text-align: center;
  color: #fff;
  background: #238ba3;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation: aninone;
  -moz-animation: aninone;
  animation: aninone;

  &.not {
    cursor: default;
    pointer-events: none;
    background: #a0a1a1;
  }
}

.btn-wrap a.btn-alert:hover {
  box-sizing: border-box;
  display: inline-block;
  min-width: 100px;
  font-size: 14px;
  line-height: 41px;
  text-align: center;
  color: #fff;
  background: #c06e02;
  text-decoration: underline;
  -webkit-animation: colorChange 0.5s ease-in-out infinite alternate;
  -moz-animation: colorChange 0.5s ease-in-out infinite alternate;
  animation: colorChange 0.5s ease-in-out infinite alternate;
  text-decoration: none;
}

.btn-wrap a.btn-alert-line {
  box-sizing: border-box;
  display: inline-block;
  min-width: 100px;
  font-size: 14px;
  line-height: 37px;
  border: 2px solid #238ba3;
  text-align: center;
  color: #238ba3;
  background: #fff;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation: aninone;
  -moz-animation: aninone;
  animation: aninone;
}

.btn-wrap a.btn-alert-line:hover {
  box-sizing: border-box;
  display: inline-block;
  min-width: 100px;
  font-size: 14px;
  line-height: 37px;
  border: 2px solid #35a1ba;
  text-align: center;
  color: #35a1ba;
  background: #fff;
  text-decoration: underline;
  -webkit-animation: lineChange 0.5s ease-in-out infinite alternate;
  -moz-animation: lineChange 0.5s ease-in-out infinite alternate;
  animation: lineChange 0.5s ease-in-out infinite alternate;
  text-decoration: none;
}

@-webkit-keyframes colorChange {
  from {
    background: #238ba3;
  }

  to {
    background: #35a1ba;
  }
}

@-webkit-keyframes lineChange {
  from {
    border: 2px solid #238ba3;
    color: #238ba3;
  }

  to {
    border: 2px solid #35a1ba;
    color: #35a1ba;
  }
}

@media screen and (max-width: 640px) {
  .pop-container {
    padding: 16px 16px 20px;
  }

  .pop-layer {
    width: 80%;
  }
}

.pop-container .alert div.desc-basic {
  width: 100%;
  min-height: inherit;
  display: block;
  text-align: left;
  margin-bottom: 7px;
}

.pop-container .alert img.item-desc-img {
  position: relative;
  top: inherit;
  width: 100px;
  height: 100px;
  margin-right: 15px;
  border: 1px solid #b6ccd1;
  border-radius: 16px;
}

.pop-container .alert div.desc-basic .item-desc-basic {
  float: right;
  margin-top: 2px;
  width: calc(100% - 136px);
}

.pop-container .alert div.desc-basic .item-desc-basic li {
  text-align: left;
  margin-top: 5px;
  text-indent: 0;
  font-weight: 700;
  line-height: 1.4;
}

.pop-container .alert div.desc-basic .item-desc-basic li:first-child {
  max-height: 66px;
  overflow: hidden;
}

.pop-container .alert div.desc-spec {
  width: 100%;
  display: block;
  padding-top: 15px;
  margin-top: 10px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
}

.pop-container .alert p.p-desc {
  margin-top: 13px;
  margin-bottom: 5px;
  line-height: 1.6;
  font-size: 14px;
  text-align: left;
}

.pop-container .desc-spec table {
  border: 1px solid #e6e6e6;
}

.pop-container .desc-spec table th {
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  text-indent: 0;
  border-bottom: 1px solid #e6e6e6;
  background: #ececec;
  color: #7f7f7f;
  text-align: center;
  font-size: 12px;
  line-height: 36px !important;
  border-bottom: 1px solid #e6e6e6;
}

.pop-container .desc-spec table td {
  text-align: center;
  font-size: 12px;
  color: #000;
  text-indent: 0;
  border-bottom: 1px solid #e6e6e6;
  line-height: 1.6;
  padding-bottom: 5px;
  padding-top: 4px;
  padding-bottom: 5px;
}

.pop-container table.tbl-purchase {
  border: 1px solid #e6e6e6;
  margin-bottom: 12px;
  margin-top: 10px;
}

.pop-container table.tbl-purchase th {
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  text-indent: 0;
  border-bottom: 1px solid #e6e6e6;
  width: 162px;
  background: #ececec;
  color: #7f7f7f;
  text-align: center;
  font-size: 12px;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 36px !important;
  vertical-align: bottom;
}

.pop-container table.tbl-purchase td {
  position: relative;
  text-align: center;
  font-size: 12px;
  color: #000;
  text-indent: 0;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
  height: 36px;
  vertical-align: bottom;

  .loading {
    position: absolute;
    top: 0.75rem;
    right: 2rem;
  }
}

table.tbl-purchase select,
table.tbl-purchase select:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: 0;
  float: right;
  box-sizing: border-box;
  padding: 0 14px;
  width: 100%;
  max-width: inherit;
  height: 37px;
  line-height: 37px;
  border: none;
  color: #4f4f4f !important;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/popup/layer-sel-arrow.png)
    no-repeat calc(100% - 10px) 50%;
}

table.tbl-purchase select:active,
table.tbl-purchase select:checked,
table.tbl-purchase select:hover {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 14px;
  width: 100%;
  max-width: inherit;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  color: #4f4f4f !important;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/popup/layer-sel-arrow-on.png)
    no-repeat calc(100% - 10px) 50%;
  height: 37px;
  line-height: 37px;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

table.tbl-purchase option {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 0 14px;
  hegiht: 37px;
  line-height: 37px;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 400;
  color: #4f4f4f !important;
  background: #fff;
}

table.tbl-purchase option:hover,
table.tbl-purchase option[selected="selected"] {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: 0;
  border: none;
  font-size: 12px;
  height: 37px;
  line-height: 37px;
  padding: 0 14px;
  font-weight: 400;
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  color: #4f4f4f !important;
}

table.tbl-purchase select option:checked,
table.tbl-purchase select option:hover {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 37px;
  line-height: 37px;
  padding: 0 14px;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  color: #4f4f4f !important;
  background: #e8eeef;
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

input[type="text"].inputPrice {
  box-sizing: border-box;
  margin: 0;
  margin-top: -6px;
  display: inline-block;
  padding: 0 5px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  color: #000;
  align-items: inherit;
  border: none;
  outline: 0;
  background: #fff;
  text-align: center;
  width: 50%;
}

input[type="text"].inputPrice:read-only {
  background: #fff;
}

.arrow.after,
.arrow.before {
  position: absolute;
  display: inline-block;
  right: 29px;
  width: 16px;
  height: 17px;
  font-size: 16px;
  right: 10px;
  background: #fff;
  cursor: pointer;
  z-index: 10;
  color: #a6c8d0;
  border-radius: 3px;
}

.arrow.before {
  margin-bottom: 0;
  bottom: 3px;
  font-size: 12px;
}

.arrow.after {
  margin-top: 0;
  font-size: 12px;
  margin-left: -4px;
  top: 3px;
}

.arrow.after:hover,
.arrow.before:hover {
  color: #286c7c;
}

.item-desc-basic b {
  display: inline-block;
  margin-right: 5px;
  font-size: 13px;
  height: 18px;
  line-height: 18px;
  width: 18px;
  border-radius: 9px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  background: #ffcc32;
  color: #000;
}

.item-desc-basic span {
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
  color: #000;
}

.item-desc-basic .game-name {
  display: inline-block;
  text-align: left;
  font-weight: 400;
  background: #b6ccd1;
  color: #fff;
  font-size: 14px;
  padding: 2px 3px;
  margin-right: 7px;
  border-radius: 4px;
  text-transform: uppercase;
}

.btn-wrap a.btn-alert-line,
.btn-wrap a.btn-alert-line:hover {
  min-width: 100px;
}

.btn-wrap a.btn-alert-line:nth-child(2),
.btn-wrap a.btn-alert:nth-child(2) {
  margin-left: 5px;
  min-width: 100px;
}

.pop-container .alert div.tbl-wrapper {
  margin-bottom: 1rem !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  width: 100% !important;
}

.pop-container .paging {
  margin-bottom: 0 !important;
  min-height: 0 !important;
}

table.tbl-purchase td.td-md {
  vertical-align: middle !important;
  letter-spacing: 0.025rem !important;
}

.history-btn a {
  display: inline-block !important;
  box-sizing: border-box !important;
  margin-top: 1em !important;
  padding: 0.5rem 2rem !important;
  text-align: center !important;
  border: 1px solid #238ba3 !important;
  font-size: 0.825rem !important;
  color: #fff !important;
  background: #238ba3 !important;
  font-weight: 700 !important;
  text-indent: -2px !important;
  transition: all 0.5s ease !important;
  outline: 0 !important;
}

@media screen and (max-width: 640px) {
  .pop-container .alert div.desc-basic .item-desc-basic li:first-child {
    font-size: 12px;
  }

  .btn-wrap a.btn-alert,
  .btn-wrap a.btn-alert-line {
    min-width: 100px;
    padding: 0 20px;
  }

  table.tbl-purchase option,
  table.tbl-purchase option:hover,
  table.tbl-purchase option[selected="selected"],
  table.tbl-purchase select,
  table.tbl-purchase select option:checked,
  table.tbl-purchase select option:hover,
  table.tbl-purchase select:active,
  table.tbl-purchase select:checked,
  table.tbl-purchase select:focus,
  table.tbl-purchase select:hover {
    font-size: 12px;
  }

  input[type="text"].inputPrice {
    font-size: 12px;
  }

  .item-desc-basic .game-name {
    font-size: 11px;
    vertical-align: baseline;
  }
}

@media screen and (max-height: 812px) and (orientation: landscape) {
  .pop-container {
    overflow-y: scroll;
    max-height: 75vh;
  }

  #yt .pop-container {
    overflow-y: hidden;
  }
}

#yt .layer-wrap {
  z-index: 9000;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}

#yt .layer-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

#yt .pop-layer {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 53vw;
  height: auto;
  background-color: #fff;
  z-index: 10;
  background: rgba(0, 0, 0, 0.85);
  border: none;
}

#yt .pop-container {
  position: relative;
  padding: 0;
}

#yt .pop-container .skip {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  visibility: hidden;
}

#yt .pop-container .frame {
  z-index: 9999;
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
}

#yt .pop-container .close {
  display: inline-block;
  z-index: 9999;
  position: absolute;
  top: -60px;
  top: -3.75rem;
  right: 0;
  width: 40px;
  width: 2.5rem;
  height: 40px;
  height: 2.5rem;
  background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/slide/close-art.png)
    center no-repeat;
  background-size: contain;
  transition: transform 0.4s ease 0.1s;
  text-indent: -9999px;
}

#yt .pop-container .close:hover {
  background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/slide/close-art-on.png)
    center no-repeat;
  background-size: contain;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

#yt .shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  padding-bottom: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background: rgba(0, 0, 0, 0.7);
}

#media {
  display: none;
  opacity: 0;
}

#yt .pop-container .frame .media-video {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 3px solid #06b3db;
}

#yt {
  display: none;
  opacity: 0;
}

#yt .pop-container .frame {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  &.img {
    padding-top: 0;
    box-sizing: border-box;
    width: 100%;
    // border: 3px solid #06b3db;
    height: auto;
    padding-bottom: 0;

    img {
      width: 100%;
    }
  }
}

#yt .pop-container .frame embed,
#yt .pop-container .frame iframe,
#yt .pop-container .frame object {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // border: 3px solid #06b3db;
}

@media screen and (max-width: 1024px) {
  #yt .pop-layer {
    width: 90vw;
  }
}
