@charset "utf-8";

@font-face {
  font-family: "M PLUS 1p";
  font-style: normal;
  font-weight: 300;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.eot);
  src: local("M PLUS 1p Light"), local("MPLUS1p-Light"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.svg#MPLUS1p)
      format("svg");
}

@font-face {
  font-family: "M PLUS 1p";
  font-style: normal;
  font-weight: 400;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.eot);
  src: local("M PLUS 1p"), local("MPLUS1p-Regular"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.svg#MPLUS1p)
      format("svg");
}

@font-face {
  font-family: "M PLUS 1p";
  font-style: normal;
  font-weight: 700;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.eot);
  src: local("M PLUS 1p Bold"), local("MPLUS1p-Bold"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.svg#MPLUS1p)
      format("svg");
}

@font-face {
  font-family: "Cinzel";
  font-weight: 400;
  src: local("☺"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/Cinzel/Cinzel-Regular.ttf")
      format("truetype");
}
@font-face {
  font-family: "Cinzel";
  font-weight: bold;
  src: local("☺"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/Cinzel/Cinzel-Bold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Noto Serif KR";
  font-style: normal;
  font-weight: 400;
  src: url(https://file.qijisoft.com/Valofe_file/fonts/noto-serif-kr-v6-latin_korean-regular.eot);
  src: local("Noto Serif KR"), local("NotoSerifKR-Regular"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.svg#NotoSerifKR)
      format("svg");
}

@font-face {
  font-family: "Noto Serif KR";
  font-style: normal;
  font-weight: 900;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.eot);
  src: local("Noto Serif KR Black"), local("NotoSerifKR-Black"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.svg#NotoSerifKR)
      format("svg");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 100;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.woff")
      format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 400;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.woff")
      format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 500;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.woff")
      format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 700;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 400;
  src: url(//file.qijisoft.com/Valofe_file/web/fonts/NotoSansSc/NotoSansSC-Regular.otf)
    format("opentype");
}

@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 500;
  src: url(//file.qijisoft.com/Valofe_file/web/fonts/NotoSansSc/NotoSansSC-Medium.otf)
    format("opentype");
}

@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 700;
  src: url(//file.qijisoft.com/Valofe_file/web/fonts/NotoSansSc/NotoSansSC-Bold.otf)
    format("opentype");
}

* {
  font-family: "NotoKr", "Helvetica Neue", "Noto Sans SC", "Malgun Gothic",
    Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", "M PLUS 1p", sans-serif;
  word-break: normal;
  -webkit-font-smoothing: antialiased;
}

:lang(jp) {
  font-family: "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Hiragino Sans CNS",
    "Yu Gothic", "M Plus 1p", sans-serif;
  word-break: break-all;
  -webkit-font-smoothing: antialiased;
}

:lang(cn) {
  font-family: "Noto Sans SC", "Arial", "M PLUS 1p", "Helvetica Neue",
    "Malgun Gothic", Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", sans-serif;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: middle;
  background: 0 0;
  font-style: normal;
}
h1 {
  font-style: normal;
  font-weight: 700;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
li,
nav ul {
  list-style: none;
}

a {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  background: 0 0;
  text-decoration: none;
}
img {
  object-fit: cover;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
}
hr {
  display: block;
  height: 2px;
  border: 0;
  margin: 0;
  padding: 0;
}
body,
html {
  // font-size: 16px;
  font-size: 1rem;
  scrollbar-gutter: stable;
}
body::-webkit-scrollbar {
  width: 8px;
  background: var(--gray-600);
}
body::-webkit-scrollbar-button {
  height: 15px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #666;
  box-shadow: inset 1px 1px 1px rgb(0 0 0 / 50%),
    inset -2px -2px 2px rgb(0 0 0 / 12%);
}
body::-webkit-scrollbar-track {
  background-color: transparent;
  height: 100%;
  filter: brightness(80);
}

body {
  background-color: #242526;
}
.global_wrap {
  position: fixed !important;
  width: 100%;
}
a {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
input[type="search"] {
  z-index: 10;
  min-width: 180px;
  margin-bottom: 12px;
  padding: 8px 16px;
  height: 52px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 14px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  margin-right: 30px;
}
.btn,
button,
button.btn,
button[type="button"],
button[type="button"].btn,
input[type="button" i],
input[type="button" i].btn,
input[type="button"],
input[type="button"].btn {
  display: inline-block;
  box-sizing: border-box;
  border: 0;
  font-style: normal;
  text-align: center;
  box-shadow: none;
  text-shadow: none;
  background-image: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
}
a.btn {
  display: inline-block;
  box-sizing: border-box;
  min-width: 251px;
  margin: 0;
  height: 52px;
  line-height: 52px;
  align-items: flex-start;
  border: 0;
  vertical-align: top;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  box-shadow: none;
  text-shadow: none;
  text-transform: uppercase;
  color: #fff;
  background-image: none;
  background-color: #8e9297;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 0.5rem;
  outline: 0;
}
.btn:hover,
a.btn:hover,
button.btn:hover,
button:hover,
button[type="button"].btn:hover,
input[type="button" i]:hover,
input[type="button"]:hover {
  box-sizing: border-box;
  color: #131313;
  background-color: rgba(80, 214, 224, 0.85);
  text-decoration: none;
  transition: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
input,
input[type="file" i]::-webkit-file-upload-button,
input[type="reset" i],
input[type="submit" i],
select,
textarea {
  box-sizing: border-box;
  display: inline-block;
  margin: 0 auto;
  width: calc(100% - 2px);
  padding: 16px 10px;
  padding: 1rem 0.625rem;
  border: 0;
  vertical-align: top;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #8e9297;
  background-color: #fff;
  align-items: flex-start;
  cursor: default;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
input[type="email"]:disabled,
input[type="email"]:read-only,
input[type="file"]:disabled,
input[type="file"]:read-only,
input[type="password"]:disabled,
input[type="password"]:read-only,
input[type="text"]:disabled,
input[type="text"]:read-only {
  font-weight: 400;
  color: #8e9297;
  background: #efefef;
  font-size: 14px !important;
  cursor: default;
}
select::-ms-expand {
  display: none;
}
select {
  outline: 0;
  padding: 0 14px;
  width: calc(100% - 30px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #eaeaea;
  color: #8e9297 !important;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  background: #fff
    url(//file.qijisoft.com/Valofe_file/web/vfun/images/common/select-arrow.png)
    no-repeat right 50%;
  font-size: 14px;
  height: 37px;
  line-height: 37px;
  background-size: 20px 6px;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
select:active,
select:checked,
select:focus {
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #eaeaea;
  font-weight: 400;
  color: #8e9297 !important;
  text-align: left;
  font-size: 14px;
  font-size: 14px;
  background-size: 20px 6px;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
option {
  width: 100%;
  border-color: transparent;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 400;
  color: #6e788e !important;
  text-align: left;
  font-size: 14px;
  padding: 0;
  font-weight: 400;
  font-style: normal;
}
select option:checked,
select option:hover {
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select option:checked,
select option:hover {
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #8e9297;
}
:-moz-placeholder,
:-ms-input-placeholder,
::-moz-placeholder,
::-ms-input-placeholder,
::-webkit-input-placeholder,
::placeholder,
textarea:-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::-moz-placeholder,
textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: #6e788e !important;
  text-align: left;
  font-size: 14px;
}
input[type="email"],
input[type="file"],
input[type="password"],
input[type="text"] {
  box-sizing: border-box;
  display: inline-block;
  margin: 0 auto;
  padding: 9px 16px;
  width: 100%;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #eaeaea;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #8e9297 /* !important*/;
  text-align: left;
  font-size: 14px;
  background-color: #fff;
  align-items: flex-start;
  cursor: default;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
input[type="email"]:disabled,
input[type="email"]:read-only,
input[type="file"]:disabled,
input[type="file"]:read-only,
input[type="password"]:disabled,
input[type="password"]:read-only,
input[type="text"]:disabled,
input[type="text"]:read-only {
  font-weight: 400;
  color: #8e9297;
  background: #efefef;
  font-size: 14px;
  cursor: default;
}
textarea {
  box-sizing: border-box;
  display: inline-block;
  margin: 0 auto;
  padding: 9px 16px;
  padding: 0.5625rem 1rem;
  width: 100%;
  height: 180px;
  border: 1px solid #eaeaea;
  resize: none;
  font-weight: 400;
  background: #fff;
}
input[type="checkbox"] {
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 4px 0 0;
  background: #fafafa;
  border: 1px solid #9c9c9c !important;
}
input[type="checkbox"]:checked {
  background: #8e9297;
  border: 1px solid #9c9c9c !important;
}
input[type="checkbox"]:checked:after {
  position: absolute;
  content: "";
  display: inline-block;
  margin: 0;
  top: 0;
  left: 0;
  border: 1px solid #8e9297 !important;
  width: 18px;
  height: 18px;
  margin-right: 24px;
  left: -1px;
  top: -1px;
  background: #8e9297
    url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/blit-check.png) 0 -2px
    no-repeat;
}
.mb-show {
  display: none;
}
#wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  // margin-top: 60px;
}
#content {
  background: #2f3136;
  position: relative;
  // min-height: inherit !important;
  width: 100%;
  z-index: 0;
  padding: 0;
  // font-size: 20px;
  font-size: 1.25rem;
  color: #8e9297;
}
.y-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(#5d5e65, 0.55);
    // border: 1px solid transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#5d5e65, 0.95);
  }
}
.x-scroll {
  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(#5d5e65, 0.55);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#5d5e65, 0.95);
  }
}
// Header Slider
#visual {
  .slide-img {
    width: 100% !important;
    height: 100%;
    max-height: 584.77px;
  }
}

.main-cont {
  width: 100%;
  padding: 4rem 0;
}
.main-cont h1 {
  overflow-x: hidden;
  height: auto;
  line-height: 1;
  padding-bottom: 1.2rem;
  font-size: 2.1875rem;
  font-weight: 400;
  color: #dcddde;
  letter-spacing: -0.025rem;
}
.main-cont .arrow-wrap {
  position: absolute;
  right: 10px;

  .page-num {
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 400;
    vertical-align: middle;
  }

  .arrows {
    clear: both;
    display: inline-block;
    margin-left: 6px;
    vertical-align: baseline;

    .prev,
    .next {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: 14px;
      height: 14px;
      line-height: 0;
      border: 1px solid #d1d1d1;
      background-color: #eee;
      color: #4f4f4f;
      cursor: pointer;
      transition: all 0.2s linear;
      background-image: none;

      &:hover {
        background-color: rgba(60, 159, 253, 0.85);
        border: 1px solid #3c9ffd;
        color: #fff;
      }

      svg {
        height: 100%;
        vertical-align: baseline;
      }
    }
  }
}
.title-area {
  display: flex;
  align-items: center;
}
.main-more {
  font-size: 1.5rem;
  padding-bottom: 0.75rem;
  vertical-align: middle;
  margin-left: 0.625rem;
  color: rgba(60, 159, 253, 0.85);
  letter-spacing: -0.0625rem;

  &:hover {
    color: rgba(60, 159, 253, 0.65);
  }
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  top: -1px;
  left: -1px;
  text-indent: -9999px;
}
.inquiry-send .wrap-btn {
  max-width: inherit;
  margin-bottom: 60px;
}
.clearfix {
  clear: both;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.ellipsis-multi {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.2;
}
.ellipsis-multi:after {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  // content: "\00b7\00b7\00b7";
  padding-left: 8px;
  padding-left: 0.5rem;
  width: 32px;
  width: 2rem;
  font-size: 16px;
  font-size: 1rem;
  background: #7387a5;
  color: #fff;
  -webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  font-family: Verdana;
}
.line-2 {
  -webkit-line-clamp: 2;
  max-height: 44px;
  max-height: 2.75rem;
}
.line-4 {
  -webkit-line-clamp: 4;
  max-height: 70px;
  max-height: 5rem;
  overflow: hidden;
}
.line-6 {
  -webkit-line-clamp: 6;
  max-height: 132px;
  max-height: 8.25rem;
}
.line-8 {
  -webkit-line-clamp: 8;
  max-height: 176px;
  max-height: 11rem;
}
.row-w {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  max-width: 1200px !important;
}
a {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.wrap-btn {
  width: 100%;
  max-width: 400px;
  margin: 34px auto 0;
  margin: 2.215rem auto 0;
  text-align: center;
}
.wrap-btn.long {
  max-width: inherit;
}
.inquiry-send .wrap-btn {
  max-width: inherit;
}
table {
  border-collapse: collapse;
  cell-padding: 0;
  cell-spacing: 0;
  -webkit-font-smoothing: antialiased;
}
.color-y {
  color: rgba(80, 214, 224, 0.85) !important;
}
.color-r {
  color: #bf0303;
}
.color-dk-grey {
  color: #8e9297;
}
.color-md-grey {
  color: grey;
}
.color-grey {
  color: #8a8a8a;
}
.color-wh {
  color: #fff;
}
.color-black {
  color: #000;
}
.color-green {
  color: #3bc37e;
}
.color-dk-green {
  color: #3f6d3f;
}
.color-bluegreen {
  color: #68e5e4;
}
.color-mint {
  color: #00a8c1;
}
.color-purple {
  color: #59a9fd;
}
.color-dk-pink {
  color: #db6c53;
}
.color-pink {
  color: #eda595;
}
.color-white {
  color: #fff;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.color-theme {
  color: rgba(80, 214, 224, 0.85);
}
.color-theme2 {
  color: rgba(80, 214, 224, 0.75);
}
.color-theme3 {
  color: rgba(80, 214, 224, 0.75);
}
.color-lightpurple {
  color: #797e9f;
}
.bg-white {
  background: #fff !important;
}
.bg-y {
  background: rgba(80, 214, 224, 0.85) !important;
}
.bg-grey {
  background: #f5f6f7;
}
.bg-dark-grey {
  background: #e6e6e6 !important;
}
.bg-mint {
  background: #71a5b0 !important;
}
.color-theme4 {
  color: rgba(80, 214, 224, 0.85);
}
.color-grey2 {
  color: #8e9297;
}
.color-grey3 {
  color: #8e9297;
}
.color-blue {
  color: #5282c9;
}
.color-blue-light {
  color: #4271f5;
}
.color-green {
  color: #95c373;
}
.font-100 {
  font-weight: 100;
}
.font-bold {
  font-weight: 700;
}
.f-none {
  float: none;
}
.f-left {
  float: left !important;
}
.f-right {
  float: right;
}
.pull-none {
  float: none !important;
}
.w100 {
  width: 100%;
}
.mt0 {
  margin-top: 0 !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt34 {
  margin-top: 34px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt80 {
  margin-top: 80px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb14 {
  margin-bottom: 14px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb34 {
  margin-bottom: 34px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb45 {
  margin-bottom: 45px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.mb80 {
  margin-bottom: 80px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.ml0 {
  margin-left: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.ml1 {
  margin-left: 1rem !important;
}
.ml2 {
  margin-left: 2rem !important;
}
.ml3 {
  margin-left: 3rem !important;
}
.mp0 {
  margin: 0 !important;
  padding: 0 !important;
}
section {
  position: relative;
  width: 100%;
  // margin-top: 46px;
  // margin-top: 2.875rem;
}
section:first-child {
  // margin-top: 60px;
}
section:last-of-type {
  margin-bottom: 0;
}
h1 {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
  color: #8e9297;
  letter-spacing: -1px;
}
h2 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #8e9297;
  letter-spacing: 0;
}
h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: #8e9297;
  letter-spacing: 0;
}
h4 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: #8e9297;
  letter-spacing: 0;
}
span {
  font-size: inherit;
}
#event-list {
  display: flex;
  min-height: 0.875rem;
  li {
    display: none;
    font-size: 0.625rem;
    text-transform: uppercase;
    letter-spacing: -0.05px;
    color: #fff;
    padding: 0.125rem 0.5rem;
    border-radius: 1rem;
    margin-right: 4px;

    &.N {
      display: inline-block;
      background: #0d72c7;
    }

    &.H {
      display: inline-block;
      background: #e37611;
    }

    &.E {
      display: inline-block;
      background: #891abd;
    }
  }
}
.paging {
  display: block;
  text-align: center;
  margin-top: 36px;
  margin-top: 2.25rem;
  margin-bottom: 36px;
  margin-bottom: 2.25rem;
}
.paging.no {
  margin-top: 0;
  margin-bottom: 0;
}
.paging ul {
  display: inline-block;
  width: auto;
  margin: 0 auto;
}
.paging ul li {
  float: left;
  margin-left: 10px;
  // margin-bottom: 7px;
}
.paging ul li:first-child {
  margin-left: 0;
}
.paging ul li a {
  box-sizing: border-box;
  display: inline-block;
  width: 31px;
  height: 31px;
  line-height: 29px;
  text-align: center;
  border: 1px solid #cdcdcd;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
}
.paging ul li a.fa,
.paging ul li a.num {
  color: #a2a5ac;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  font-weight: 400;
}
.paging ul li a:hover,
.paging ul li a:hover a.fa,
.paging ul li.on a.fa,
.paging ul li.on a.num {
  font-size: 14px;
  color: #139bb8;
  border: 1px solid #139bb8;
}
table {
  width: 100%;
}
table tbody th {
  color: #8e9297;
}
table tbody td {
  color: #8e9297;
  font-size: 1rem;
}

.twinkle1 {
  /*position:absolute;left:50%; !*9*! margin-left:-3rem; top:-6rem; !* 14 * 16 *! width: 0.875rem; height: 1rem; background: url(//file.qijisoft.com/Valofe_file/web/vfun-ko/images/common/crt/space/light1-min.png) center no-repeat;*/
  animation: twinkle 2s 0s infinite ease-in-out;
  -webkit-animation: twinkle 2s 0s infinite ease-in-out;
  -o-animation: twinkle 2s 0s infinite ease-in-out;
  -moz-animation: twinkle 2s 0s infinite ease-in-out;
  opacity: 0;
}
.twinkle2 {
  /*position:absolute;left:50%;!*12*! margin-left:1.25rem; top:-5rem; !* 8 * 9 *!width: 0.5rem; height: 0.5625rem;  background: url(//file.qijisoft.com/Valofe_file/web/vfun-ko/images/common/crt/space/light2-min.png) center no-repeat;*/
  animation: twinkle 2s 0.5s infinite ease-in-out;
  -webkit-animation: twinkle 2s 0.5s infinite ease-in-out;
  -o-animation: twinkle 2s 0.5s infinite ease-in-out;
  -moz-animation: twinkle 2s 0.5s infinite ease-in-out;
  opacity: 0;
}
.twinkle3 {
  /*position:absolute;left:50%; !*13.25*!margin-left:-1rem; top:-2.25rem;  !* 12 * 13 *! width: 0.75rem; height: 0.8125rem; background: url(//file.qijisoft.com/Valofe_file/web/vfun-ko/images/common/crt/space/light3-min.png) center no-repeat;*/
  animation: twinkle 2.5s 0.25s infinite ease-in-out;
  -webkit-animation: twinkle 2.5s 0.25s infinite ease-in-out;
  -o-animation: twinkle 2.5s 0.25s infinite ease-in-out;
  -moz-animation: twinkle 2.5s 0.25s infinite ease-in-out;
  opacity: 0;
}
.twinkle4 {
  /*position:absolute;left:50%;!*9.75*!margin-left:3rem; top:-1.75rem;  !* 14 * 16 *! width: 0.875rem; height: 1rem; background: url(//file.qijisoft.com/Valofe_file/web/vfun-ko/images/common/crt/space/light4-min.png) center no-repeat;*/
  animation: twinkle 3s 0.75s infinite ease-in-out;
  -webkit-animation: twinkle 3s 0.75s infinite ease-in-out;
  -o-animation: twinkle 3s 0.75s infinite ease-in-out;
  -moz-animation: twinkle 3s 0.75s infinite ease-in-out;
  opacity: 0;
}
@keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
:lang(jp) .gcoin-info .sentence,
:lang(tw) .gcoin-info .sentence,
:lang(zh) .gcoin-info .sentence {
  word-break: break-all;
}

.guide-link {
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  background: url(//file.qijisoft.com/Valofe_file/web/vfun-ko/images/common/bg_guide.jpg)
    center top no-repeat !important;
  background-size: cover;

  h1 {
    text-align: center;
    padding-bottom: 2.5rem;
    font-size: 1.5625rem;
    font-weight: 700;
    color: #fff;
    letter-spacing: 2px;
  }

  ul {
    width: 660px;
    margin: 0 auto;

    li {
      width: 220px;
      float: left;

      button {
        background: transparent;
      }

      a,
      button {
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0.75;

        .guide-txt {
          display: block;
          margin-top: 10px;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 18px;
          font-size: 1.125rem;
          color: #fff;
        }
      }

      &:focus a .guide-txt,
      &:hover a .guide-txt,
      &:focus button .guide-txt,
      &:hover button .guide-txt {
        color: #242424;
      }
    }
  }

  .guide-icon {
    display: inline-block;
  }
}
@-webkit-keyframes neon3 {
  from {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 10px #ffdd1b, 0 0 20px #ffdd1b, 0 0 30px #ffdd1b;
  }
  to {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 0px #ffdd1b, 0 0 1px #ffdd1b;
  }
}

@-moz-keyframes neon3 {
  from {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 10px #ffdd1b, 0 0 20px #ffdd1b, 0 0 30px #ffdd1b;
  }
  to {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 0px #ffdd1b, 0 0 1px #ffdd1b;
  }
}
@-ms-keyframes neon3 {
  from {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 10px #ffdd1b, 0 0 20px #ffdd1b, 0 0 30px #ffdd1b;
  }
  to {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 0px #ffdd1b, 0 0 1px #ffdd1b;
  }
}
@-o-keyframes neon3 {
  from {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 10px #ffdd1b, 0 0 20px #ffdd1b, 0 0 30px #ffdd1b;
  }
  to {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 0px #ffdd1b, 0 0 1px #ffdd1b;
  }
}

@keyframes neon3 {
  from {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 10px #ffdd1b, 0 0 20px #ffdd1b, 0 0 30px #ffdd1b;
  }
  to {
    text-shadow: 0 0 0px #fff, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b, 0 0 0px #ffdd1b,
      0 0 0px #ffdd1b, 0 0 1px #ffdd1b;
  }
}
#valofe-footer {
  // margin-bottom: 50px;
  padding-bottom: 25px;

  .sns {
    li {
      float: left;
      margin-right: 21px;

      &:last-child {
        margin-right: 0;
      }
    }

    .lg,
    .yt {
      display: inline-block;
      width: 32px;
      height: 32px;
      background-size: 100% !important;
    }

    .lg {
      background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/cbm/icon-lg-on.png)
        0 0 no-repeat;
    }

    .yt {
      color: #fff;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    margin: 32px auto;
    background-color: #4c4c4d;
  }

  .copyright {
    line-height: 100%;
    color: #fff;

    .logo {
      float: left;
      cursor: pointer;
      display: inline-block;
      width: 94px;
      height: 18px;
      font-weight: 400;
      background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/logo/logo-valofe-one-white-txt.png)
        0 center no-repeat;
      background-size: 100%;
    }

    p {
      display: inline-block;
      // margin-top: 4px;
      margin-left: 28px;
    }

    .entity-c {
      padding-bottom: 2px;
      font-family: "Arial", sans-serif;
      display: inline-block;
    }

    .year-of-copyrights {
      display: inline-block;
      padding-bottom: 2px;
      margin-left: 8px;
    }
  }

  .row-w.cont {
    line-height: 100%;
  }

  .nav {
    width: auto;

    display: flex;
    align-items: center;

    ul {
      line-height: 100%;
      // margin-right: 8px;

      li {
        float: left;
        margin-left: 18px;
        font-size: 12px !important;

        &:first-child {
          margin-left: 32px;
        }
      }

      a {
        color: #fff;
      }
    }
  }

  .footer-logo {
    float: left;
    margin-top: 0;
  }

  .jp-terms {
    display: inline-block;
    margin-left: 18px;

    a {
      color: #fff;
      font-size: 12px;
    }

    span {
      margin-left: 18px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
#vfun-fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #2b2d34; /*#005567;*/

  .row-w {
    overflow: visible;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: calc(50px + constant(safe-area-inset-bottom));
    height: calc(50px + env(safe-area-inset-bottom));
  }

  ul,
  a {
    color: #80aab3;
    cursor: pointer;

    &:hover {
      color: #50d6e0;
    }
  }

  ul li {
    display: inline-block;
    text-align: center;

    a {
      display: inline-block;
      font-size: 11px;
      line-height: 14px;
      vertical-align: middle;
      height: auto;

      .FontAwesomeIconSpan {
        font-size: 20px;
      }
    }
  }

  ul.menu {
    border-right: 1px solid rgba(0, 0, 0, 0.15);

    li {
      width: 50px;
      padding: 0 0.5rem;
    }
  }

  ul.go-to {
    li {
      width: 40px;
    }

    .FontAwesomeIconSpan {
      font-size: 24px;
      // padding-left: 1rem;
    }
  }

  .user-wallet {
    position: relative;
  }

  .my-space-btn {
    position: relative;

    .star-tag {
      position: absolute;
      top: -8px;
      right: -2px;
      color: #ffdd1b;
      font-size: 16px;
    }
  }
}

/* naver footer */
#valofeKoFooter {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 0;
  text-align: center;
  background-color: #242526;
  letter-spacing: 0;

  .row-w {
    position: relative;
    //overflow: unset;
    display: flex;
    width: 94%;
    margin: 0 auto 50px;
    padding: 30px 0;
    border-top: 1px solid #2d303a;
    text-align: left;
    background: transparent;

    .footer-space {
      margin: -0.2rem 0.5rem 0;
      display: inline-block;
      font-size: 10px;
    }
  }

  .logo-footer {
    display: inline-block;
    margin-right: 6%;
  }

  .company-logo-footer {
    display: inline-block;
    width: 103px;
    height: 23px;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun-ko/images/logoVALOFE_transparent.png)
      0 center no-repeat;
    background-size: contain;

    .nhn {
      margin-top: 40px;
    }
  }

  .game-footer-logo.naver {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 137px;
    width: 103px;
    height: 43px;
    background: url(https://lostsagakr-cdn-image.valofe.com/2014_grand/front/images/common/footer_naver_logo.gif)
      no-repeat center/100% auto !important;
  }

  .partner.naver {
    display: inline-block;
    width: 100%;
    margin-top: 1.25rem;
    font-size: 12px;
    color: #acacac;
  }

  address {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    letter-spacing: -0.5px;
    text-align: left;
    font-size: 12px;
    line-height: 1.7;
    color: #acacac;
    font-family: "NotoKr", sans-serif;

    &.nhn {
      margin-top: -53px;
    }

    a {
      line-height: inherit;

      &:hover {
        color: #ffc538;
      }
    }
  }
  .copy * {
    font-family: "NotoKr", sans-serif;
    letter-spacing: 0;
  }
}

#valofeKoFooter,
#valofeKoFooter address a {
  color: #acacac;
  font-size: 12px;
}
@media screen and (max-width: 1200px) {
  // html {
  //   font-size: 13px;
  // }
  html,
  body {
    font-size: 14px;
  }
  .main-cont h1,
  .main-cont .title-area {
    font-size: 1.875rem;
  }
  .row-w {
    width: calc(100% - 32px) !important;
    // max-width: calc(100% - 32px) !important;
    //overflow-x: hidden  히든 지정하면 page short 헤더 움직임이 이상해짐 ;

    // vfun
    max-width: 1100px !important;

    -ms-overflow-style: none; /* IE and Edge : 삭제하면 안됩니다. 세로 스크롤 더 생김 */
    scrollbar-width: none; /* Firefox : 삭제하면 안됩니다. 세로 스크롤 더 생김  */
  }
  .row-w::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera  : 삭제하면 안됩니다. 세로 스크롤 더 생김 */
  }
}

@media screen and (max-width: 1024px) {
  #content {
    padding: 0 !important;
  }

  #wrapper.bg-even section:last-child {
    padding: 3rem 0 !important;
  }

  section:first-child.visual {
    margin-top: 0;
    margin-bottom: 3.8rem;
  }

  section:first-child {
    // margin-top: 3rem;
  }
  section:last-child {
    margin-bottom: 0;
  }

  section:last-of-type.guide-link {
    padding-top: 3rem;
  }

  #wrapper {
    top: 0;
  }

  #wrapper.bg-even section:last-child.guide-link {
    margin-bottom: 60px;
  }
  .guide-link ul li a .guide-txt {
    font-size: 1rem;
  }
  .guide-link ul {
    width: 420px !important;
  }
  .guide-link ul li {
    width: 140px;
  }
  .guide-icon {
    width: 80%;
  }
  .inquiry-send .wrap-btn {
    margin-bottom: 120px;
  }
  .main-cont {
    padding: 3rem 0;
  }
  #valofe-footer {
    .row-w:nth-of-type(3) {
      display: block;
      text-align: center;
    }

    .copyright,
    .footer-logo,
    .copyright p {
      display: block;
      width: 100%;
      float: none;
      text-align: center;
      clear: both;
      margin: 0;
    }

    .copyright {
      .logo {
        float: none;
      }
      p {
        margin-top: 8px;
      }
    }

    .nav {
      margin: 10px auto 0;
      width: 100%;
      text-align: center;
      flex-direction: column;

      ul {
        display: inline-block;

        li:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }

    .jp-terms {
      margin-top: 4px;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .guide-link {
    padding: 3rem 0;
  }
  .paging ul li {
    margin-left: 1px;
  }
  .paging ul li a {
    width: 24px;
    height: 24px;
    line-height: 22px;
  }
}
@media screen and (max-width: 640px) {
  #content {
    min-height: inherit;
  }

  #visual.popular-header-slide .slide-img {
    min-height: 240px;
  }
  section:last-child.guide-link {
    padding: 1.75rem 0 !important;
  }
  .guide-link ul li a .guide-txt {
    font-size: 0.75rem;
    line-height: 12px;
  }
  .guide-link ul {
    width: 300px !important;
  }
  .guide-link h1 {
    font-size: 1.5rem !important;
  }
  .guide-link ul li {
    width: 100px;
  }
}

@media screen and (max-width: 480px) {
  .mb-show {
    display: inline-block;
  }

  .guide-link ul {
    width: 252px !important;
  }
  .guide-link ul li {
    width: 84px;
  }
  #valofe-footer .nav ul li {
    margin-left: 10px;
    // font-size: 0.5rem !important;
  }
  #valofe-footer .copyright p {
    margin-left: 0;
  }
  #valofe-footer .nav ul li:first-child {
    margin-left: 10px;
  }
  #vfun-fixed-footer {
    ul li a {
      font-size: 10px;
    }

    ul.menu {
      width: calc(100% - 66px);

      li {
        width: calc(100% / 5);
        padding: 0;
      }
    }

    ul.go-to {
      li {
        width: 30px;
      }

      .FontAwesomeIconSpan {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  html {
    // min-width: 320px;
  }
  .paging ul li {
    margin-left: 1px;
  }
  .paging ul li a {
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 11px;
  }

  .main-cont h1 {
    font-size: 1.5rem !important;
    text-align: center;
  }

  #valofe-footer {
    .nav ul li,
    .jp-terms a {
      font-size: 10px !important;
    }
  }
}
@media screen and (max-width: 320px) {
  .paging ul li {
    margin-left: 1px;
  }
  .paging ul li a {
    width: 19px;
    height: 19px;
    line-height: 17px;
  }
}
