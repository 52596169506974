@charset "utf-8";

.row-flex {
  display: flex;
}

.hottest {
  width: 68%;
  .faPlus {
    font-size: 1.5rem;
  }

  .tab-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 0.625rem;
    margin-right: 0.625rem;

    .wrapper {
      background: rgb(40 42 46);
      display: flex;

      .img-area {
        /* float: left; */
        width: 9rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          display: block;
          margin: 0.5rem;
          height: 7.875rem;
          object-fit: cover;
          border-radius: 3px;
        }
      }

      .game-info-area {
        padding: 1rem 0.5rem 1rem 0.625rem;
        color: #fff;
        width: 100%;

        h3 {
          font-size: 1.125rem;
          letter-spacing: 0;
          color: #fff;
          font-weight: 400;
          padding-bottom: 0.625rem;
        }

        p {
          font-size: 0.875rem;
          letter-spacing: 0.2px;
          color: #fff;
          font-weight: 400;
          max-height: 3.4rem;
          padding-bottom: 1.25rem;
          line-height: 1rem;
          margin-top: 0.5rem;
        }

        .genre {
          overflow: hidden;
          font-size: 0.875rem;
          color: #8e9297;
        }
        .faDesktop {
          margin-right: 5px;
        }
      }
    }

    .wrapper:hover {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
      transition: all 0.2s linear;
    }
  }

  .game-info-area {
    padding-bottom: 0.5rem !important;

    h3 {
      padding-bottom: 1.125rem !important;
    }
  }

  .genre {
    color: #fff !important;
  }

  .btn-mb-download {
    margin-top: 1rem;
    background-color: rgba(166, 211, 255, 0.15);
    color: rgba(255, 255, 255, 0.5);
  }

  .a-wrap {
    background-color: rgba(166, 211, 255, 0.45);
    width: 100%;
    box-sizing: border-box;
    float: left;
    line-height: 2rem;
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    background-size: 0 100%;
    transition: all 0.5s;
  }

  .mb-icon {
    display: inline-block;
    max-height: 100%;
    margin-top: 3px;
    vertical-align: middle;
    background: transparent;

    img {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
      margin-bottom: 4px;
    }
  }

  .a-wrap:hover {
    color: #fff;
    font-weight: 700;
    background-color: rgba(60, 159, 253, 0.85);
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    transition: background-size 0.5s 0.25s, background-image 0.5s 0.25s,
      background-color 0.5s 0.25s;
  }
}

.Ranking {
  width: 32%;

  .ranking-btn {
    width: 100%;
    border-bottom: 1px solid #575757;
    margin-bottom: 0.8125rem;
  }

  li {
    float: left;

    button {
      padding: 0.25rem 2rem;
      font-size: 1rem;
      font-weight: 700;
      transition: all 0.2s linear;

      border-left: 1px solid #575757;
      background: #575757;
      color: #fff;

      &:hover {
        background: #fff;
        color: #575757;
      }
    }

    .on {
      background: #fff;
      color: #575757;
    }
  }

  .rank-list {
    display: block;
    padding: 0 0.5rem;
    font-weight: 700;
    font-size: 0.8125rem;

    li {
      float: none;
      padding-bottom: 0.75rem;
    }

    .rank {
      display: inline-block;
      text-align: center;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      line-height: 21px;
      font-size: 0.6875rem;
      color: #fff;

      background: linear-gradient(
        135deg,
        #a3a7ad 0,
        #a3a7ad 15%,
        #81868e 48%,
        #626770 51%,
        #626770 100%
      );
      border: 2px solid #646a74;
      text-shadow: 0 1px 2px #646a74;
    }

    ._1 {
      background: linear-gradient(
        135deg,
        #ffc34f 0,
        #ffc34f 15%,
        #feaa17 48%,
        #fd9404 51%,
        #fd9404 100%
      );
      border: 2px solid #fb963a;
      text-shadow: 0 1px 2px #fb963a;
    }
    ._2 {
      background: linear-gradient(
        135deg,
        #a3a7ad 0,
        #a3a7ad 15%,
        #bcbcbc 48%,
        #898989 51%,
        #898989 100%
      );
      border: 2px solid #898989;
      text-shadow: 0 1px 2px #898989;
    }
    ._3 {
      background: linear-gradient(
        135deg,
        #ef850a 0,
        #ef850a 15%,
        #ee8108 48%,
        #e86300 51%,
        #e86300 100%
      );
      border: 2px solid #e86300;
      text-shadow: 0 1px 2px #e86300;
    }

    strong {
      color: #9fa1a7;
      // width: calc(100% - 11rem);
      vertical-align: middle;
      display: inline-block;
      padding-left: 5px;
    }

    .piling {
      display: inline-block;
    }

    .blit {
      margin-right: 0.25rem;
      vertical-align: middle;
      line-height: 1;
      text-align: center;
      padding: 0.25rem 0.5rem 0.25rem 0.6rem;
      border-radius: 13px;
      font-size: 0.625rem;
      letter-spacing: 0.5px;
      background: #06c;
      text-transform: uppercase;
      display: inline-block;
      color: #fff;
    }

    .num {
      color: #9fa1a7;
      text-align: right;
      width: 4rem;
      display: inline-block;
      font-weight: normal;
    }
  }
}

@media screen and (max-width: 1200px) {
  .hottest .btn-mb-download {
    margin-top: 0.625rem;
  }

  .hottest .tab-grid {
    row-gap: 3rem;
  }

  .Ranking {
    .ranking-btn {
      margin-bottom: 0.625rem;
    }

    li {
      button {
        font-size: 0.875rem;
      }
    }

    .rank-list {
      .rank {
        line-height: 16px;
      }
      .blit {
        padding: 0.125rem 0.5rem 0.125rem 0.6rem;
      }

      li {
        padding-bottom: 0.625rem;

        strong,
        .piling .num {
          font-size: 0.625rem;
        }
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .hottest .tab-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-right: 2rem;

    .wrapper:nth-child(3),
    .wrapper:nth-child(4) {
      display: none;
    }
  }

  .Ranking {
    width: 60% !important;
  }
}

@media screen and (max-width: 640px) {
  .row-flex {
    display: block;
  }

  .hottest {
    width: 100%;
    margin-bottom: 2rem;
  }

  .hottest .tab-grid {
    margin-right: 0;
    row-gap: 2rem;

    .wrapper:nth-child(3),
    .wrapper:nth-child(4) {
      display: flex;
    }
  }

  .Ranking {
    width: 100% !important;
  }
}
