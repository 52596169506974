@charset "utf-8";

/* support*/
/* .sec1 */
.sec1 {
  border-bottom: 4px solid /*#f39a00*/ #00bfff;
  padding-bottom: 16px;
  padding-bottom: 1rem;
}
.sec1.box {
  box-sizing: border-box;
  margin-top: 4rem;
  padding: 26px 20px 20px;
  padding: 1.625rem 1.25rem 1.25rem;
  border: 10px solid rgba(203, 203, 203, 0.22);
}
section:last-child.sec1.box {
  margin-bottom: 0;
}
section:first-child.sec1 {
  margin-top: 4rem;

  h1 {
    color: #fff;
  }
}
.sec1.box h1 {
  border-bottom: 0;
  line-height: 1.7;
}
.sec2.faq {
  margin-top:/*2.875rem*/ 2.875rem;
}
.sec2.faq h1 {
  border-bottom: 4px solid /*#f39a00*/ #00bfff;
  padding-bottom: 16px;
  padding-bottom: 1rem;
}
.sec3 h4 {
  color: #868686;
  text-align: center;
  line-height: 24px;
  line-height: 1.5rem;
}
.sec3.inquiry {
  margin-bottom: 4rem;
  margin-top: 1rem;
  .btn {
    display: inline-block;
    box-sizing: border-box;
    min-width: 251px;
    margin: 0;
    height: 52px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #8e9297;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 0.5rem;
    outline: 0;
  }

  .chk-agree {
    background: #8e9297;
    border: 1px solid #9c9c9c !important;
  }
}
/* support > support*/
.menu-tab h1 {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.menu-tab.mobile {
  display: none;
}

.menu-tab tr th {
  padding-top: 20px;
  padding-top: 1.25rem;
  line-height: 1.75rem;
  vertical-align: top;
}
.menu-tab tr td {
  padding-top: 24px;
  padding-top: 1.5rem;
  vertical-align: top;
}

.menu-tab tr th,
.menu-tab tr td {
  border-bottom: 1px solid rgba(203, 203, 203, 0.22) /*#cbcbcb*/;
  padding-bottom: 14px;
  padding-bottom: 0.875rem;
}

.menu-tab tr:first-child th,
.menu-tab tr:first-child td {
  padding-top: 0;
}
.menu-tab tr:last-child th,
.menu-tab tr:last-child td {
  border-bottom: none;
  padding-bottom: 0;
}

/* li */
.menu-tab ul .list {
  float: left;
  position: relative;
  padding-bottom: 10px;
}
.menu-tab ul li a {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.7rem;
  padding-right: 22px;
  font-weight: 400;
  color: #8e9297 /*#8e9297*/;
}
.menu-tab ul li a:active,
.menu-tab ul li a:visited {
  color: #8e9297;
}
.menu-tab ul li:hover a,
.menu-tab ul li.on a {
  color:/*#f39a00*/ #00bfff;
}

/* | */
.menu-tab ul .list:before {
  content: "•";
  color: #ccc;
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  height: 14px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 6px;
  vertical-align: top; /*width:1px;display:none;background-color:#cccccc;*/
  background: none;
}
.menu-tab ul li:first-child:before {
  display: inline-block;
}

.faq h1 {
  border-bottom: 4px solid /*#f39a00*/ #55575e;
  padding-bottom: 16px;
  padding-bottom: 1rem;
}
.faq h1 span {
  font-weight: bold;
}

/* fAQ  리스트 */
/* fAQ  리스트 - 1. question */
.faq .list {
  border-bottom: 5px solid rgba(203, 203, 203, 0.22); /*#cdcdcd;*/
}
.faq .list li {
  overflow: hidden;
  border-bottom: 1px solid rgba(203, 203, 203, 0.22); /* #cdcdcd;*/
  border-top: 0;
}
.faq .list li .question {
  padding: 0rem 1rem;
  min-height: 55px;
  line-height: 51px;
  overflow: hidden;
}
.faq .list li .question .blit-new {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  margin-left: 8px;
  margin-left: 0.5rem;
  padding: 4px 6px 6px;
  padding: 0.25rem 0.375rem 0.375rem;
  color: #fff;
  background: #0075cf;
  font-size: 14px;
  text-transform: uppercase;
  vertical-align: middle;
}
.faq .list li .question .blit-hot {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  margin-left: 8px;
  margin-left: 0.5rem;
  padding: 4px 6px 6px;
  padding: 0.25rem 0.375rem 0.375rem;
  color: #fff;
  background: #bd0c0c;
  font-size: 14px;
  text-transform: uppercase;
  vertical-align: middle;
}

.faq .list li:first-child .question {
  border-top: none;
}

.faq .list li .question .pull-left {
  display: inline-block;
  width: calc(100% - 2rem); /* height: 0; */
}
.faq .list li .question .pull-right {
  display: inline-block;
  margin-top: 4px;
}

.faq .list li .question h3 {
  display: block;
  padding: 0;
  width: 90%;
  font-weight: 700;
  color: #8e9297;
}

.faq .list li .question h3 a,
.faq li .question h3 a:visited {
  color: #8e9297;
  font-size: 24px;
  font-size: 1.5rem;
  text-decoration: none;
  font-size: 1rem;
}

.faq .list li.on .question h3.ellipsis {
  overflow: unset;
  line-height: 1.5rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  white-space: inherit;
  text-overflow: inherit;
  animation-duration: 0s;
}
.faq .list li.on,
.faq .list li:hover {
  background: #1a1c21; /*#efefef;*/
}
.faq .list li.no-list:hover {
  background: inherit;
}
.faq .list li.on .question h3 a,
.faq .list li.on .question h3.ellipsis,
.faq .list li:hover .question h3,
.faq .list li:hover .question h3 a,
.faq li .question h3 a:active {
  color:/*#f39a00*/ #00bfff;
  animation-duration: 0s;
}

.faq .list li .fa {
  font-size: 24px;
  font-size: 1.5rem;
  vertical-align: top;
  line-height: 55px;
}
.faq .list li .fa.fa-angle-up {
  display: none;
}
/* on 일 때는 up 이 보이고, on 아닐때는 down 이 보인다.*/
.faq .list li.on .fa.fa-angle-down {
  display: none;
}
.faq .list li.on .fa.fa-angle-up {
  display: inline-block;
  color: #00bfff;
}

/* fAQ  리스트 - 1. answer */
.faq .list li .answer {
  padding: 25px 16px 25px 28px;
  padding: 1.5625rem 1rem 1.5625rem 1.75rem;
  color: #8e9297;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  line-height: 1.875rem;
  z-index: 1;
}
.faq .list li .answer a,
.faq .list li .answer a:visited {
  z-index: 9990;
  color:/*#f39a00*/ #00bfff;
}
.faq .list li .answer a:hover {
  text-decoration: underline;
}
section .answer p:first-child {
  margin-top: 0;
}
.faq .list li.on .answer {
  border-top: 1px solid rgba(203, 203, 203, 0.22);
}
@media screen and (max-width: 1024px) {
  .sec1.box,
  .sec2.faq {
    margin-top: 2rem;
  }
  .btn#btn-inquiry,
  .btn#btn-send,
  .btn#btn-cancle {
    font-size: 12px;
  }
}

@media screen and (max-width: 640px) {
  #valofe-footer {
    margin-top: 0;
  }
}
