@charset "utf-8";

.mission {
  .row-w {
    padding: 46px 56px;
    background: #fff;
    margin-top: 14px;

    h1 {
      color: #238ba3;
      font-size: 1rem;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 0.25rem;
      overflow-x: hidden;
      height: auto;
      min-height: 25px;
      line-height: 1.5rem;
      letter-spacing: -1px;
    }
  }

  /* 소팅 네비 */
  .nav {
    display: inline-block;
    width: auto;
    margin-top: 0rem;
    float: right;
  }
  .nav ul {
    float: none;
    display: inline-block;
    width: auto;
  }
  .nav ul li {
    float: left;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .nav ul li:before {
    content: "|";
    vertical-align: middle;
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 14px;
    font-weight: normal;
    color: #a2a5ac;
  }
  .nav ul li:first-child:before {
    display: none;
  }
  .nav ul li:first-child {
    margin-left: 0rem;
  }
  .nav ul li a {
    display: inline-block;
    color: #a2a5ac;
    font-size: 14px;
    font-weight: bold;
  }
  .nav ul li a .fa {
    display: inline-block;
    color: #a2a5ac;
    font-size: 14px;
    font-weight: bold;
  }
  .nav ul li a:hover,
  .nav ul li.on a,
  .nav ul li.on a .fa {
    display: inline-block;
    color: #434343;
    font-size: 14px;
    font-weight: bold;
  }

  /* 보유 포인트 */
  #myPoint {
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: right;
    font-size: 13px;
  }

  #myPoint em {
    display: inline;
    margin-right: 7px;
    vertical-align: middle;
    font-weight: bold;
    color: #000;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  #myPoint em.status {
    color: #a2a5ac;
  }
  #myPoint b {
    display: inline-block;
    margin-right: 5px;
    height: 18px;
    line-height: 18px;
    width: 18px;
    font-size: 12px;
    border-radius: 9px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    background: #ffcc32;
    color: #000;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  #myPoint span {
    display: inline;
    vertical-align: middle;
    font-weight: normal;
    color: #000;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  #myPoint a:hover em,
  #myPoint a:hover span {
    color: #5bc5dd;
    text-decoration: none;
  }
  #myPoint a:hover b {
    background: #5bc5dd;
    color: #fff;
    text-decoration: none;
  }

  /* 미션 리스트 */
  ul.mission-list li {
    float: none;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e6e6e6;
  }
  ul.mission-list li:first-child {
    padding-top: 0px;
  }
  ul.mission-list li ul li {
    list-style: none;
    float: none;
    border: none;
    padding-bottom: 0;
  }
  ul.mission-list li ul li:before {
    content: "";
  }
  ul.mission-list li:last-child {
    // border-bottom: none;
  }

  .game-desc .game-tit {
    color: #444;
    font-weight: bold;
    text-align: center;
    font-size: 25px;

    a {
      color: #444;
    }
  }

  .game-image {
    position: relative;
    box-sizing: border-box;
    width: 127px;
    height: 127px;
    text-align: left;
  }
  .game-image img {
    width: 100%;
    border-radius: 10px;
  }
  .game-image.event:after {
    position: absolute;
    display: inline-block;
    content: "";
    left: -5px;
    top: 10px;
    width: 40px;
    height: 44px;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/blit-event.png)
      0 0 no-repeat;
  }
  .game-image.new:after {
    position: absolute;
    display: inline-block;
    content: "";
    left: -5px;
    top: 10px;
    width: 40px;
    height: 44px;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/blit-new.png)
      0 0 no-repeat;
  }
  .game-image.hot:after {
    position: absolute;
    display: inline-block;
    content: "";
    left: -5px;
    top: 10px;
    width: 40px;
    height: 44px;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/blit-hot.png)
      0 0 no-repeat;
  }

  .game-txt {
    position: relative;
    box-sizing: border-box;
    padding-top: 14px;
    padding-left: 26px;
    width: calc(100% - 145px); /*127 + 85 */
    text-align: left;
  }
  .game-line.game-line-cont h2 {
    margin-top: 4px;
    margin-bottom: 0px;
    font-size: 32px;
    font-weight: bold;
    text-align: left;
    color: #000000;
    line-height: 1.2;
    letter-spacing: -1px;
  }
  .game-line-cont .fa {
    margin-right: 4px;
    text-align: center;
    height: inherit; /*line-height: 1.2;*/
    font-size: 14px;
  }

  .game-line-cont .btn-wrap {
    margin-top: 16px;
  }
  .game-line-cont .game-desc {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.2;
    font-weight: bold;
    color: #f39a00;
  }
  .game-line-cont .game-event {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.2;
    font-weight: normal;
    color: #444;
  }

  .game-desc .game-info {
    margin-top: 17px;
    margin-bottom: 5px;
  }
  .game-desc .wrap-device-all {
    display: inline-block;
    width: auto;
    padding: 0px 16px 0px 0;
  }
  .wrap-device {
    display: inline-block;
    width: auto;
    margin-top: 0px;
    margin-right: 10px;
    text-align: right;
  }

  .game-line-cont .device-txt {
    position: relative;
    padding-left: 4px;
    font-size: 12px;
    color: #444;
  }
  .game-line-cont .device-txt:after {
    content: "\00b7";
    position: absolute;
    left: -12px;
    top: 0px;
    margin: 0px 7px 0;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #444;
  }
  .game-desc .fa {
    font-size: 12px;
  }
  .game-desc a.btn {
    text-align: left;
    display: inline-block;
    margin: 0;
    padding: 0 8px;
    width: auto;
    height: 1.2rem;
    vertical-align: middle;
    min-width: inherit;
    border-radius: 1.2rem;
    color: #f39a00;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2rem;
    border: 1px solid #f39a00;
    background: none;
    align-items: inherit;
  }
  .game-desc a.btn:hover {
    color: #000;
    background: #f39a00;
  }

  .game-desc .game-tit a:hover,
  .game-desc .game-tit a:focus {
    color: #f39a00;
  }

  .download-num {
    color: #a2a5ac;
    font-size: 15px;
    font-size: 0.9375rem;
    margin-left: inherit;
    margin-right: 1rem;
    vertical-align: middle;
  }
  .like-num {
    color: #a2a5ac;
    font-size: 15px;
    font-size: 0.9375rem;
    margin-left: inherit;
    margin-right: 1rem;
    vertical-align: middle;
  }

  .row-w.padding-none {
    margin: 2rem auto;
    padding: 0;
    background: none;
    border-top: 0;
  }
  .wrap-btn {
    width: 100%;
    max-width: 100%;
    background: none;
  }
  .wrap-btn a.more {
    margin-top: 0;
    display: block;
    padding: 1rem 0;
    width: 100%;
    color: #000;
    font-size: 1rem;
    border: 1px solid #e3e3e3;
    background: #fff;
  }
  .wrap-btn a.more .fa {
    color: #000;
    font-size: 1rem;
    vertical-align: middle;
  }
  .wrap-btn a.more:hover,
  .wrap-btn a.more:hover .fa {
    background: #139bb8;
    color: #000;
  }

  ul.mission-list li ul.ea {
    float: left;
    width: auto;
    padding-left: 10px;
    padding-right: 30px;
  }
  ul.mission-list li ul.ea li {
    float: none;
    width: 100%;
    color: #444;
    text-align: center;
  }
  ul.mission-list li ul.ea li.bubble span {
    display: none;
    width: 58px;
    height: 43px;
    line-height: 40px;
    font-size: 14px;
    vertical-align: middle;
    color: #fff;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/stamp/stamp-bubble.png)
      0 0 no-repeat;
  }
  ul.mission-list li.times ul.ea li.bubble span {
    display: inline-block;
  }

  ul.mission-list li ul.ea li div {
    width: 96px;
    display: block;
    text-align: center;
    margin-top: 24px;
  }
  ul.mission-list li ul.ea li div i {
  } /* 모바일일 때만 히든 */
  ul.mission-list li.times ul.ea li.bubble + li div {
    margin-top: 0;
  }
  ul.mission-list li ul.ea li em {
    font-weight: bold;
    text-align: center;
    margin-top: -1px;
    display: inline-block;
  }

  ul.mission-list li ul.status {
    float: right;
    width: auto;
    padding-right: 10px;
    padding-left: 36px;
  }
  ul.mission-list li ul.status li {
    position: relative;
    width: 96px;
    height: 96px;
    color: #444;
    text-align: center;
  }
  ul.mission-list li ul.status li a {
    cursor: default;
    display: block;
    width: 100%;
    height: 100%;
    margin-top: -24px;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/stamp/stamp-dim.png)
      0 0 no-repeat;
    background-size: cover;
  }
  ul.mission-list li.complete ul.status li a {
    display: block;
    width: 100%;
    height: 100%;
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/stamp/stamp-active.png)
      0 0 no-repeat;
    background-size: cover;
  }
  ul.mission-list li ul.status li label {
    visibility: hidden;
  }
  .stamp-status[type="checkbox"] {
    /*-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor:pointer;
    outline:none;*/
    position: relative;
    box-sizing: border-box;
    margin-left: -60px;
    margin-top: 2px;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: #fff !important;
    border: 1px solid #d4d4d4 !important;
  }
  .stamp-status[type="checkbox"]:checked {
    /*-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor:pointer;
    outline:none;
    background:transparent;*/
    box-sizing: border-box;
    background: #fff;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: none;
    border: 1px solid #d4d4d4 !important;
  }
  .stamp-status[type="checkbox"]:checked:after {
    /*    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor:pointer;
    outline:none;*/
    content: "";
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    margin: 0;
    top: -1px;
    left: -1px;
    border: none;
    border: 1px solid #5bc5dd !important;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    background: #5bc5dd
      url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/stamp/blit-check-white.png)
      center center no-repeat;
    background-size: cover;
  }

  /* 미션 리스트 상세 설명*/
  .desc {
    font-size: 13px;
    color: #444;
    padding-left: 17px;
    line-height: 1.4;
    height: 55px;
    margin-top: 1.25rem;
  }
  .reset-time {
    margin-top: 15px;
    font-size: 13px;
    color: #444;
    padding-left: 0px;
    line-height: 1.4;
  }
  .show-mobile {
    display: none;
  }
  .show-desktop {
    display: inline;
  }
  .reset-time .em {
    color: #139bb8;
    display: block;
    margin-bottom: 5px;
  }
  .reset-bg {
    display: inline-block;
    color: #fff;
    background: #a2a5ac;
    padding: 1px 4px;
    margin-right: 3px;
    border-radius: 2px;
    font-size: 11px;
  }
  .ellipsis-multi:after {
    display: inline-block;
    display: none;
    position: absolute;
    right: 0;
    bottom: 0px;
    content: "+";
    padding-right: 9px;
    width: 4px;
    height: 17px;
    line-height: 16px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
    background: #efefef;
    color: #000;
    text-align: center;
    -webkit-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .desc .icon-mission {
    display: inline-block;
    vertical-align: middle;
    margin-left: -17px;
    margin-right: 3px;
  }

  /* 인포메이션 */
  section.info {
    margin-top: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  section.info h1 {
    font-size: 1rem;
  }
  .info .row-w {
    padding: 46px 56px;
    background: #fff;
    border-top: none;
  }
  ul.show-info {
    padding-left: 1rem;
  }

  ul.show-info li {
    position: relative;
    list-style: circle;
    font-size: 13px;
    color: #444;
    padding-left: 0px;
    line-height: 1.5;
    text-align: left;
    color: #444;
  }

  :lang(tw) ul.show-info li {
    word-break: break-all;
  }
  :lang(jp) ul.show-info li {
    word-break: break-all;
  }
  :lang(ko) ul.show-info li {
    word-break: keep-all;
  }
  :lang(tr) ul.show-info li {
    word-break: keep-all;
  }
  :lang(fr) ul.show-info li {
    word-break: keep-all;
  }
  :lang(de) ul.show-info li {
    word-break: keep-all;
  }
  :lang(en) ul.show-info li {
    word-break: keep-all;
  }

  ul.show-info li a {
    color: #139bb8;
  }
  ul.show-info li a:hover {
    color: #139bb8;
    text-decoration: underline;
  }
  ul.show-info li img {
    width: 13px;
    height: 13px;
    vertical-align: middle;
  }
  ul.show-info li .info-img {
    position: absolute;
    box-sizing: border-box;
    z-index: 1;
    left: 200px;
    padding: 1.5rem 2rem 1rem;
    box-sizing: border-box;
    border: 1px solid #d4d4d4;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background: #fff;
    text-align: center;
    color: #a2a5ac;
  }

  ul.show-info li .info-img a {
    color: #d4d4d4;
    font-size: 16px;
  }
  ul.show-info li .info-img a:hover {
    color: #139bb8;
    font-size: 16px;
  }
  ul.show-info li .info-img.dim {
    display: none;
    top: -86px;
    left: 100px;
    width: 220px;
    min-height: 155px;
  }
  ul.show-info li .info-img.active {
    display: none;
    top: -70px;
    left: 100px;
    width: 220px;
    min-height: 155px;
  }
  ul.show-info li .info-img img {
    width: 96px;
    height: 96px;
  }
  ul.show-info li .info-img .btn-close-info {
    position: absolute;
    display: inline-block;
    right: 1rem;
    top: 0.5rem;
  }

  /* 페이지 */
  section.page-area {
    margin-top: 0;
  }
  section.page-area .row-w {
  }
  /* 로그인 전 내 상태: 마이 스탬프, 미션 */
  em.status {
    text-decoration: none;
    display: block;
    width: 100%;
    margin-right: 0px;
    vertical-align: middle;
    font-weight: bold;
    color: #a2a5ac;
    float: left;
    font-size: 13px;
    text-align: left;
  }
  em.status .status-bg {
    display: inline-block;
    color: #fff !important;
    background: #a2a5ac;
    padding: 4px 4px;
    margin-right: 3px;
    border-radius: 2px;
    font-size: 11px;
    line-height: 11px;
  }

  h1 em.status {
    display: inline-block;
    width: auto;
    float: right;
    text-align: right;
  }

  input[type="checkbox"]:after {
    background-image: none;
  }
}

/* 인포메이션 */

@media screen and (max-width: 1200px) {
  .mission-list li ul.ea li {
    padding-top: 0;
  }
  .mission-list li ul.status li {
    padding-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .mission {
    /* 미션 설명 */
    .desc {
      margin-top: 0.5rem;
      font-size: 13px;
      color: #444;
      padding-left: 17px;
      line-height: 1.4;
      padding-left: 40px;
      height: 38px;
      line-height: 32px;
    }

    .game-txt {
      padding-left: 0;
      padding-top: 0;
      width: calc(100% - 110px);
    }
    .game-image {
      width: 96px;
      height: 96px;
    }
    .game-desc .pull-right {
      max-width: inherit;
      float: none;
    }
    .game-desc .pull-left {
      max-width: inherit;
      float: none;
    }

    .desc .icon-mission {
      margin-left: -28px;
      margin-right: 13px;
    }
    /* 스탬프 지급 */
    ul.mission-list li {
    }
    ul.mission-list li ul.ea {
      // margin-top: 10px;
      padding-left: 0;
      position: relative;
    }
    ul.mission-list li ul.ea:after {
      content: "|";
      display: inline-block;
      position: absolute;
      width: auto;
      right: 0px;
      top: 9px;
      font-size: 13px;
      color: #ddd;
    }
    ul.mission-list li ul.ea li {
      float: left;
      width: auto;
      height: 32px;
      line-height: 32px;
    }
    ul.mission-list li ul.ea li.bubble span {
      display: none;
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      font-size: 14px;
      vertical-align: middle;
      color: #fff;
      background-image: none;
      background: #139bb8;
    }
    ul.mission-list li ul.ea li div {
      width: auto;
      margin-top: 0px;
      margin-left: 5px;
      font-size: 13px;
      vertical-align: middle;
    }
    ul.mission-list li ul.ea li div i {
      display: none;
    }
    /* 스탬프 지급 상태 */
    ul.mission-list li ul.status {
      float: left;
      margin-top: 7px;
      padding-left: 27px;
    }
    ul.mission-list li ul.status li {
      width: auto;
      height: auto;
    }
    ul.mission-list li ul.status li a,
    ul.mission-list li.complete ul.status li a {
      display: none;
    }
    .stamp-status[type="checkbox"] {
      margin-left: 0;
      margin-top: 0;
      vertical-align: middle;
    }
    ul.mission-list li ul.status li label {
      visibility: visible;
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: 640px) {
  .mission {
    .row-w {
      padding: 1.5rem;
    }

    .game-image {
      width: 39px;
      height: 39px;
    }
    /* 게임 이미지 e, h, n 블릿 */
    .game-image.event:after {
      display: none;
    }
    /* 미션 게임 이름 */
    .game-desc .game-tit {
      display: none;

      color: #444;
      font-weight: bold;
      text-align: center;
      font-size: 25px;
    }
    /* 미션 설명 글 */
    .game-txt {
      width: calc(100% - 58px);
    }
    .desc {
      margin-top: 0;
      height: 36px;
      padding-left: 0;
    }
    .desc .icon-mission {
      display: none;
      margin-left: 0;
    }
    /* 스탬프 지급 개수 및 상태 틀*/
    .game-desc .pull-right {
      clear: right;
      margin-left: -58px;
      margin-top: 10px;
      border: none; /*padding: 6px 8px background: #efefef;*/
      text-align: right;
    }
    .game-desc .pull-right {
      margin-top: 0;
    }
    ul.mission-list li ul.status {
      margin-top: 0px;
      padding-right: 0;
    }
    /* 스탬프 지급 개수*/
    ul.show-info li {
      font-size: 12px;
    }

    /* 미션*/
    .nav {
      width: auto;
      float: right;
      text-align: center;
      margin: 10px auto 0;
    }
    .nav ul li a,
    .nav ul li a .fa,
    .nav ul li a:hover,
    .nav ul li.on a,
    .nav ul li.on a .fa {
      font-size: 12px;
    }
    .nav ul li:before {
      content: "|";
      vertical-align: middle;
      display: inline-block;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      font-size: 14px;
      font-weight: normal;
      color: #a2a5ac;
    }

    ul.mission-list li ul.ea {
      display: inline-block;
      vertical-align: middle;
      float: none;
      margin-top: 0px;
      padding-right: 1rem;
    }
    ul.mission-list li.times ul.ea li.bubble + li div {
      margin-top: 0px;
    }
    ul.mission-list li ul.ea li {
      float: left;
      width: auto;
      height: 16px;
      line-height: 16px;
    }

    ul.mission-list li ul.ea li.bubble span {
      display: none;
      height: 16px;
      line-height: 16px;
      border-radius: 2px;
      font-size: 14px;
      vertical-align: top;
      color: #fff;
      background: #139bb8;
    }

    ul.mission-list li ul.status {
      display: inline-block;
      vertical-align: middle;
      float: none;
      margin-top: 0px;
      padding-left: 1rem;
    }
    .stamp-status[type="checkbox"],
    .stamp-status[type="checkbox"]:checked,
    .stamp-status[type="checkbox"]:checked:after {
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }

    ul.mission-list li ul.ea:after {
      content: "|";
      display: inline-block;
      position: absolute;
      width: auto;
      right: 0px;
      top: 1px;
      font-size: 13px;
      color: #ddd;
    }

    /* 미션 셀렉트 */
    select,
    select:hover,
    select:focus,
    select:active,
    select:checked {
      box-sizing: border-box;
      display: block;
      width: 100%;
      max-width: 100%;
      font-size: 14px;
      height: 37px;
      line-height: 37px;
      background: #fff
        url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/stamp/arrow-default.png)
        no-repeat calc(100% - 7px) 50%;
    }

    select:hover {
      background: #fff
        url(https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/stamp/arrow-close.png)
        no-repeat calc(100% - 7px) 50%;
    }

    option {
      box-sizing: border-box;
      display: block;
      width: 100%;
      font-size: 14px;
      padding: 0px 14px;
      height: 37px;
      line-height: 37px;
    }
    /* 로그인 전 내 상태: 마이 스탬프, 미션 */
    em.status {
      text-decoration: none;
      display: block;
      width: 100%;
      margin-top: 6px;
      margin-right: 0px;
      margin-bottom: 6px;
      vertical-align: middle;
      font-weight: bold;
      color: #a2a5ac;
      float: left;
      font-size: 13px;
      text-align: left;
    }
    em.status .status-bg {
      display: inline-block;
      color: #fff !important;
      background: #a2a5ac;
      padding: 4px 4px;
      margin-right: 3px;
      border-radius: 2px;
      font-size: 11px;
      line-height: 11px;
    }

    h1 em.status {
      float: right;
    }

    .reset-time {
      background: #efefef;
      padding: 12px 12px 16px;
    }
    .reset-time .em {
      margin-bottom: 5px;
    }
    .reset-bg {
      font-size: 11px;
    }
    .show-mobile {
      display: inline;
    }
    .show-desktop {
      display: none;
    }
  }
  /* 미션 게임 이미지 */
}
@media screen and (max-width: 480px) {
  .mission {
    #myPoint {
      text-align: right;
      padding-right: 0;
    }
    em.status {
      line-height: 1.8;
    }
  }
}

@media screen and (max-width: 414px) {
  .mission {
    .game-desc .pull-right {
      margin-top: 15px;
      // margin-left: -57px;
      // width: calc(100% + 57px);
      text-align: left;
      background: #efefef;
      padding: 3px 12px 7px;
    }

    .desc {
      text-align: left;
      line-height: 42px;
      font-weight: bold;
    }

    ul.mission-list li ul.status {
      padding-left: 0;
      width: 100%;
    }
    ul.mission-list li ul.ea:after {
      display: none;
    }
    ul.mission-list li ul.ea,
    ul.mission-list li ul.ea li {
      width: 100%;
    }

    ul.mission-list li ul.ea li div {
      margin-left: 20px;
    }

    ul.mission-list li {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      float: none;
    }
    ul.mission-list li:last-child {
      margin-top: 7px;
    }
  }
}
@media screen and (max-width: 360px) {
  .mission {
    #myPoint {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-right: 0;
    }
    #myPoint + .mt20 {
      margin-top: 10px !important;
    }
  }
}
