@charset "utf-8";
/* 레이아웃 */
#errors {
  width: 100%;
  height: auto;
  background: #2a2b33; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #2a2b33 0%,
    #25252b 53%,
    #201f23 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #2a2b33 0%,
    #25252b 53%,
    #201f23 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #2a2b33 0%,
    #25252b 53%,
    #201f23 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a2b33', endColorstr='#201f23',GradientType=0 ); /* IE6-9 */

  .pc-hidden {
    display: none;
  }
  /*#aside {position:relative;width:500px;min-height:722px;float:left;}*/
  #cont {
    color: #fff;
    box-sizing: border-box;
    padding: 40px 85px;
    margin: 0 auto;
    width: 1200px;
    /*min-height:722px;*/
    // height: calc(100dvh - 60px);
    /* Permalink - use to edit and share this gradient: //colorzilla.com/gradient-editor/#2a2b33+0,25252b+53,201f23+100 */
    background: #2a2b33; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #2a2b33 0%,
      #25252b 53%,
      #201f23 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #2a2b33 0%,
      #25252b 53%,
      #201f23 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #2a2b33 0%,
      #25252b 53%,
      #201f23 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a2b33', endColorstr='#201f23',GradientType=0 ); /* IE6-9 */
  }
  .wrap {
    display: block;
    width: 100%;
    // background: url(../../assets/images/errors/bg-404.png) center center
    //   no-repeat;
  }
  .neon {
    width: 100%;
    height: auto;
    margin: 0;
    text-align: center;
  }
  .neon b {
    display: inline-block;
    position: relative;
    margin-top: 3rem;
    margin-left: 1.5rem;
    width: 100%;
    height: 100%;
    font-size: 5rem;
    font-family: "Trebuchet MS", "Helvetica Neue", Sans-serif;
    text-align: center;
    color: #fee;
    text-shadow: 0 0px 15px rgba(255, 255, 255, 0.15), 0 0 2px, 0 0 1em #dc4a67,
      0 0 0.5em #dc4a67, 0 0 0.1em #dc4a67, 0 5px 3px #000;
  }
  .neon b i {
    margin-right: -1.5rem;
    display: inline-block;
    animation: blink linear infinite 2s;
  }
  #t0 {
    margin-left: -2.5rem;
  }
  #t1 {
    margin-left: 1rem;
  }
  #t4 {
    margin-left: 1.5rem;
  }
  #t7 {
    margin-left: 1.5rem;
    margin-right: 1.25rem;
  }
  #t8 {
    margin-left: 0;
  }
  #t9 {
    margin-left: 0;
  }
  #t10 {
    margin-left: 1.5rem;
  }

  @keyframes blink {
    78% {
      color: inherit;
      text-shadow: inherit;
    }
    79% {
      color: #222;
    }
    80% {
      text-shadow: none;
    }
    81% {
      color: inherit;
      text-shadow: inherit;
    }
    82% {
      color: #222;
      text-shadow: none;
    }
    83% {
      color: inherit;
      text-shadow: inherit;
    }
    92% {
      color: #222;
      text-shadow: none;
    }
    92.5% {
      color: inherit;
      text-shadow: inherit;
    }
  }

  /* GHOST */
  .box {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 142px;
    margin-top: 8rem;
    border-radius: 20px;

    .box__ghost {
      padding: 15px 25px 25px;
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -30%);
    }
  }

  .btn-wrap {
    margin: 3.5rem auto 2rem;
    padding: 0;
    text-align: center;

    button {
      display: inline-block;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      border-radius: 2rem;
      padding: 0.7rem 2.9629rem;
      background: #139bb8; /*#dc4a67*/

      &:hover {
        background: rgba(19, 155, 184, 0.75); /*#dc4a67*/
      }
    }
  }
  p {
    margin-top: 25px;
    text-align: center;
    line-height: 1.6;
    font-size: 1rem;
    padding: 0 1rem;

    .noti-color {
      color: #139bb8;
    }

    &.ask {
      margin-top: 15px;

      button {
        color: #139bb8;
        text-decoration: underline;
        background: transparent;
        padding: 0;
        font-size: 1rem;

        &:hover {
          color: #5282c9;
        }
      }
    }
  }

  /* funny */
  .funny-wrap {
    .funny {
      .eye {
        display: block;
        width: 45px;
        height: 44px;
        background: #fff4f9;
        border-radius: 48%;
        position: absolute;
        top: 58px;
        left: 56px;
        z-index: 9999;
        animation: wink 2s ease-in-out infinite;

        &:before {
          display: block;
          content: "";
          width: 64%;
          height: 65%;
          transform: translate(9px, 9px);
          background: #9b3d90;
          border-radius: 45%;
        }
        &:after {
          display: block;
          content: "";
          width: 7px;
          height: 7px;
          transform: translate(12px, -14px);
          background: #fff4f9;
          border-radius: 50%;
        }
      }
    }

    .deco {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      filter: grayscale(1) brightness(0.5);
      animation: blink 3s 1.6s infinite;

      .neon1 {
        position: absolute;
        left: -20px;
        top: -5px;
        height: 40px;
        width: 40px;
      }
      .neon2 {
        position: absolute;
        right: -15px;
        top: 10px;
        height: 40px;
        width: 40px;
      }
      .neon3 {
        position: absolute;
        left: -40px;
        bottom: 30%;
        height: 40px;
        width: 40px;
      }
      .neon4 {
        position: absolute;
        right: -30px;
        bottom: 25%;
        height: 40px;
        width: 40px;
      }
    }
  }

  @keyframes blink {
    0% {
      filter: grayscale(0) brightness(1);
    }
    5% {
      filter: grayscale(1) brightness(0.5);
    }
    10% {
      filter: grayscale(0) brightness(1);
    }
    15% {
      filter: grayscale(1) brightness(0.5);
    }
    20% {
      filter: grayscale(0) brightness(1);
    }
    100% {
      filter: grayscale(0) brightness(1);
    }
  }

  @keyframes wink {
    0%,
    94%,
    100% {
      transform: scaleY(1) scaleX(1);
    }
    95% {
      transform: scaleY(0.2) scaleX(1);
    }
  }

  /* media query */
  @media screen and (max-width: 1024px) {
    #cont {
      width: 100%;
      padding: 40px;
    }
    .btn-wrap {
      margin-top: 52px;
    }
  }
  @media screen and (max-width: 640px) {
    #cont {
      width: 100%;
      padding: 40px 0;
    }
    .box {
      min-height: 160px;
    }
    .neon b {
      font-size: 3rem;
      line-height: 1.4;
    }
    .neon b i {
      margin-right: -1.5rem;
    }
  }
  @media screen and (max-width: 414px) {
    /* .pc-hidden {display: block;}*/
    .neon b {
      font-size: 2rem;
    }
  }
}
