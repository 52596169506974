@charset "utf-8";
.wrapper {
  #NotSupport {
    color: #ffffff;
    text-align: center;
    font-size: 1rem;

    a {
      cursor: pointer;
    }

    .guide-top {
      background: url(../../assets/images/bg.jpg);
      padding: 0 1.5rem 3.375rem 1.5rem;
      .guide-funny {
        padding-top: 8.75rem;
      }
      .text {
        font-size: 1.875rem;
        font-weight: bold;
        padding-top: 2.625rem;
        letter-spacing: -1px;

        .small-txt {
          font-size: 0.875rem;
          font-weight: normal;
          color: #c0a5ff;
          margin-top: 10px;
        }
      }
    }
    .btns {
      padding-top: 1.5rem;
    }

    .btn-window {
      display: inline-block;
      background: #34363d;
      width: 15rem;
      height: 4.125rem;
      border-radius: 5px;
      margin: 0 1rem;

      .cont {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.5rem;

        .btn-text {
          font-weight: bold;
          color: #9ea1a9;
          padding: 0 1rem 0 0.75rem;

          &.download {
            text-align: start;
          }

          div {
            &:first-child {
              color: #7289c6;
            }
          }
        }
      }
    }

    .guide-bottom {
      background: #2f3136;
      padding-bottom: 12.125rem;

      .install-box {
        max-width: 875px;
        width: 100%;
        margin: 0 auto;

        p {
          line-height: 1.5rem;
        }
      }

      .install-box:nth-child(1) {
        padding-top: 4.375rem;
      }
      .install-box:nth-child(2) {
        padding-top: 3.125rem;
      }

      .title {
        font-size: 1.125rem;
        font-weight: bold;
        text-align: left;
        padding-bottom: 0.313rem;
      }
      .box {
        background: #282a32;
        padding: 2rem 1rem 1.75rem 1rem;
        .go-btn {
          display: inline-block;
          color: #ffffff;
          margin-bottom: 1.25rem;
          padding: 0.5rem 0.625rem;
          background: #4271f5;

          .icon-launcher-small {
            width: 20px;
          }

          span {
            padding-left: 0.313rem;
            padding-right: 0.5rem;
            line-height: 1px;
          }

          & + .go-btn {
            margin-left: 1.25rem;
            background: #9642f5;
          }
        }

        p {
          color: #7f8189;
        }
      }
      .box-launcher {
        background: #282a32;
        padding: 2rem 1rem 1.75rem 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .launcher-info {
          width: 70%;
        }

        table {
          color: #9ea1a9;
          text-align: start;
          thead {
            background: #34363e;

            th {
              padding: 5px 20px;
              text-align: start;
            }
          }

          td {
            padding: 4px 20px;
            font-size: 14px;
            border: 1px solid #34363e;
            border-left: none;

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .guide-funny {
        img {
          width: 20rem;
        }
      }
      .guide-bottom {
        .install-box {
          width: 90%;
        }
      }
    }

    @media screen and (max-width: 554px) {
      font-size: 0.875rem;

      .guide-top {
        .btn-window {
          width: 11.875rem;
        }
        .cont {
          img {
            &:first-child {
              width: 2rem;
            }
          }

          .btn-text {
            padding: 0 0.5rem;
          }
        }
      }
    }

    @media screen and (max-width: 490px) {
      .guide-top {
        padding: 0 1.5rem 2rem 1.5rem;
        .text {
          font-size: 1.5rem;
        }
        .btn-window {
          &:first-child {
            margin-bottom: 0.5rem;
          }
        }
        .guide-funny {
          padding-top: 6.25rem;
          img {
            width: 16rem;
          }
        }
      }

      .guide-bottom {
        .box {
          .go-btn {
            & + .go-btn {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
