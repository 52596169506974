@charset "utf-8";

.main-header-slide {
  &#visual {
    .pointer {
      position: absolute;
      background: #121212;
      color: #fff;
      border-radius: 20px;
      display: inline-block;
      top: -50px;
      left: 8px;
      z-index: 9;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: bold;
      line-height: 100%;

      svg {
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .slide-img {
      width: 100% !important;
      height: 100%;
      max-height: 584.77px;
    }
  }

  .slick-list {
    .slick-track {
      // width: 100% !important;

      .slick-slide {
        &.slick-current {
          z-index: 9990;
        }

        .slide-article {
          z-index: 10;
          position: absolute;
          // width 임시 적용
          width: 33.75rem;
          top: 4em;
          left: calc(50% - 720px);
          word-break: normal;
          text-align: left;
          font-weight: 700;
          text-shadow: 0 0 2px rgb(127 99 54 / 90%);
          color: #fff;
        }
        .logo {
          height: 4.5em;
          width: auto;
        }
        .tit {
          display: inline-block;
          box-sizing: border-box;
          margin-top: 1em;
          padding-right: 0;
          width: 100%;
          color: #fff;
          line-height: 1.1;
          font-size: 1.563em;
          font-weight: 700;
        }
      }
    }
  }
  .slick-dots {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    border-bottom: none;
    text-align: center;
    left: 0;
    width: 100%;

    ul {
      bottom: 0;
    }

    li {
      background-color: rgba(255, 255, 255, 0.3);
      display: inline-block;
      position: relative;
      width: calc(100% / 8 - 10px);
      padding: 0;
      cursor: pointer;
      height: 3em;

      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
        transition: all 0.5s linear;
      }

      .dot-btn {
        z-index: 1;
        position: absolute;
        box-sizing: border-box;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0.5rem 1rem;
        line-height: inherit;
        text-align: center;
        vertical-align: middle;
        display: table !important;
      }

      .game-tit {
        position: relative;
        display: table-cell !important;
        color: #000;
        vertical-align: middle;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        word-break: normal !important;
      }

      span {
        display: block;
        width: 0%;
      }

      // 현재 페이지 className
      &.slick-active span {
        position: absolute;
        left: 0;
        z-index: -1;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 102, 204, 0.7);
        animation: progressbar 5s linear;
      }
    }
  }
}

// animation
@keyframes progressbar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@media screen and (max-width: 1766px) {
  .main-header-slide .slick-slide article {
    left: calc(50% - 600px) !important;
  }
}

@media screen and (max-width: 1456px) {
  .main-header-slide .slick-slide article {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 1280px) {
  .main-header-slide .slick-dots {
    width: 96%;
    margin-left: 2%;
  }

  .main-header-slide article img.logo {
    height: 3.5em !important;
  }
}

@media screen and (max-width: 1200px) {
  .main-header-slide .slick-slide article {
    left: 2rem !important;
  }

  .main-header-slide .slick-dots li {
    height: 2.5em;
  }

  .main-header-slide .slick-dots li .dot-btn {
    padding: 0.375rem 1rem;
  }

  .main-header-slide .slick-dots li .game-tit {
    font-size: 12px;
  }
}

@media screen and (max-width: 1106px) {
  .main-header-slide .slick-slide article {
    font-size: 0.7em !important;
  }
}

@media screen and (max-width: 1024px) {
  .main-header-slide .slick-slide article {
    top: 6em !important;
  }

  .main-header-slide .slick-list .slide-img {
    height: 340px !important;
  }

  .main-header-slide article .tit {
    line-height: 1.3;
  }

  .main-header-slide .slick-dots {
    display: none;
    width: 100%;
    margin-left: 0;
    left: 0;
    bottom: calc(-3em + 2px);
    border-bottom: 3px solid #000;
    border-top: 4px solid #000;
    background: #000;
  }
  .main-header-slide .slick-dots li {
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0 2px;
  }
}

@media screen and (max-width: 940px) {
  .main-header-slide .slick-slide article {
    font-size: 0.65em !important;
  }
}

@media screen and (max-width: 812px) {
  .main-header-slide .row-w {
    width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .main-header-slide .slick-dots {
    bottom: calc(-3em + 8px);
  }

  .main-header-slide .slick-slide article {
    top: 5.5em !important;
  }

  .main-header-slide .slick-dots li {
    width: calc(100% / 4 - 6px);
  }

  .main-header-slide .slick-dots li:nth-child(1),
  .main-header-slide .slick-dots li:nth-child(2),
  .main-header-slide .slick-dots li:nth-child(3),
  .main-header-slide .slick-dots li:nth-child(4) {
    border-bottom: 2px solid #000;
  }

  .main-header-slide .slick-dots li:nth-child(5),
  .main-header-slide .slick-dots li:nth-child(6),
  .main-header-slide .slick-dots li:nth-child(7),
  .main-header-slide .slick-dots li:nth-child(8) {
    border-top: 2px solid #000;
  }
}

@media screen and (max-width: 586px) {
  .main-header-slide .slick-slide article {
    top: 4em !important;
    width: 21rem !important;
  }
  .main-header-slide article .tit {
    font-size: 1.5em !important;
  }
}

@media screen and (max-width: 460px) {
  .main-header-slide .slick-list .slide-img {
    height: 260px !important;
  }

  .main-header-slide .slick-slide article {
    // 임시
    width: 21rem !important;
  }
}

@media screen and (max-width: 414px) {
  .main-header-slide .slick-list .slide-img {
    // margin-left: -60px;
    overflow: inherit;
  }

  .main-header-slide article .tit {
    width: 90%;
  }

  .main-header-slide .slick-dots li .dot-btn {
    padding: 0.375rem 0.625rem;
  }
}
