@charset "utf-8";

.guide-link {
  background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/bg_guide.jpg)
    center top no-repeat !important;

  h1 {
    text-align: center;
    padding-bottom: 2.5rem;
    font-size: 1.5625rem;
    font-weight: 700;
    color: #fff;
    letter-spacing: 2px;
  }

  ul {
    display: flex;
    justify-content: space-around;
    width: 660px;
    margin: 0 auto;
  }

  li {
    width: 220px;
    float: left;
  }
}
