@charset "utf-8";

#cookieNotice {
  display: block;
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -400px;
  width: 800px;
  height: auto;
  padding: 1rem;
  z-index: 9999;
  font-size: 13px;
  text-align: center;
  color: #282828;
  border: 1px solid #d4d4d4;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.85);

  .cookie-notice-container {
    display: block;
    padding: 1rem;
    width: 800px;
    border-radius: 4px;
    line-height: 1.7;
    background: rgba(255, 255, 255, 0.9);

    &:before {
      padding: 0.5rem 1rem;
      content: "cookie";
      text-transform: uppercase;
      box-sizing: border-box;
      position: absolute;
      margin-right: -3.3rem;
      right: 0;
      top: 0;
      width: 2.2rem;
      line-height: 1;
      font-size: 10px !important;
      height: auto;
      word-wrap: break-word;
      text-align: -webkit-right;
      color: #fff;
      background: #35a1ba;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.privacy {
      display: none;

      .ckNoticeText.clearfix {
        position: relative;
        overflow: hidden;
        word-wrap: break-word;
      }
    }
  }

  #ckNoticeText {
    overflow: hidden;
    width: 746px;
    line-height: 1.2;
  }

  #ckNoticeText,
  .cookie-notice-container {
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
    text-align: left;
  }

  .cookie-notice-container.cookie:before {
    content: "cookie";
  }

  .button.wp-default {
    box-sizing: border-box;
    display: inline-block;
    line-height: 18px;
    padding: 2px 12px;
    background: #238ba3;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    white-space: nowrap;
    margin-left: 10px;
    outline: 0;
    margin-top: 3px;
    height: auto;
  }

  #cookieClose {
    position: absolute;
    display: inline-block;
    width: 34px;
    height: 34px;
    width: 2.125rem;
    height: 2.125rem;
    line-height: 1;
    top: -2.5rem;
    right: -2.45rem;
    color: #fff;

    .close-icon {
      font-size: 36px !important;
      color: #fff;
    }
  }

  .shadow {
    background: rgb(0 0 0 / 0.3);
  }
}

@media screen and (max-width: 1024px) {
  #cookieNotice {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 83%;

    .cookie-notice-container {
      width: 100%;
      line-height: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  #cookieNotice {
    width: 70%;

    #ckNoticeText {
      font-size: 12px;
    }

    .button.wp-default {
      padding: 2px 8px;
    }
  }
}
