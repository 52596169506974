@charset "utf-8";

.view-popup.sns {
  .pop-sizing {
    overflow-y: hidden;
  }

  h4 {
    color: var(--gray-100);
  }

  ul {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;

    * {
      color: var(--gray-100);
    }

    li {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      padding: 0.5rem;
      border-radius: 50%;
      background: var(--gray-600);
      margin-bottom: 10px;
      display: inline-block;
      line-height: 100%;

      svg {
        width: 32px;
        height: 32px;
      }

      &:hover {
        background: var(--gray-500);
      }
    }

    div {
      font-size: 14px;
    }
  }
}
