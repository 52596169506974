@charset "utf-8";

.allGames {
  background: rgb(40, 42, 46);
  width: 100%;

  .title-area {
    align-items: start;
  }

  .allGames-btn {
    margin-top: 4px;
    li {
      display: inline-block;
    }

    button {
      width: 2.125rem;
      height: 2.125rem;
    }

    .feed {
      margin-left: 1rem;
      border: 1px solid #fff;
      background: #fff
        url(https://file.qijisoft.com/Valofe_file/web/vfun/images/home/game/btn_view_box_off.png)
        center no-repeat;
      background-size: 100%;
      opacity: 0.75;
      background-size: cover;

      &:hover,
      &.on {
        border: 1px solid #06c;
        background: #06c
          url(https://file.qijisoft.com/Valofe_file/web/vfun/images/home/game/btn_view_box_on.png)
          center no-repeat;
        background-size: 100%;
        opacity: 1;
        background-size: cover;
      }
    }

    .list {
      margin-left: 0.5rem;
      border: 1px solid #fff;
      background: #fff
        url(https://file.qijisoft.com/Valofe_file/web/vfun/images/home/game/btn_view_list_off.png)
        center no-repeat;
      background-size: 100%;
      opacity: 0.75;

      &:hover,
      &.on {
        border: 1px solid #06c;
        background: #06c
          url(https://file.qijisoft.com/Valofe_file/web/vfun/images/home/game/btn_view_list_on.png)
          center no-repeat;
        background-size: 100%;
        opacity: 1;
      }
    }
  }

  .sub-title {
    padding-bottom: 10px;
    text-align: left;
    font-weight: 400;
    letter-spacing: -0.0625rem;
  }

  .games-area {
    .pc-game .pc-game-list .tab,
    .mobile-game .mobile-game-list .tab {
      margin-bottom: 2rem;
      margin-right: 10px;
      display: inline-block;
      width: calc(33.3333% - 10px);
      background: rgba(0, 0, 0, 0.15);
      position: relative;
      box-shadow: -1px 0 1px rgb(0 0 0 / 10%);
      overflow: hidden;
      border-radius: 10px;

      &:hover {
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 50%);
        transition: all 0.2s linear;

        .img-area {
          overflow: hidden;
        }

        .img-area > img {
          transform: scale(1.1) translateZ(0);
          filter: blur(10px);
          background-size: cover;
        }

        .hover {
          opacity: 1;
          transition: all 1.75s cubic-bezier(0.23, 1, 0.32, 1);
        }
      }
    }

    .normal {
      width: 100%;
      position: relative;
      box-sizing: border-box;
      transition: all 0.15s linear;
      cursor: pointer;
    }
    .img-area {
      > img {
        transition: all 1.75s cubic-bezier(0.23, 1, 0.32, 1);
        width: 100%;
      }

      .logo {
        z-index: 1;
        display: block;
        position: absolute;
        width: 100%;
        top: 4.5rem;
        text-align: center;

        > img {
          width: 48%;
        }
      }
    }

    .desc-area {
      padding: 1.125rem;
      line-height: 1;

      h3 {
        color: #fff;
        box-sizing: border-box;
        letter-spacing: -0.09px;
        // margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        // max-height: 1.125rem;
      }

      #event-list {
        padding-bottom: 2rem;
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        font-size: 12px;

        .genre {
          letter-spacing: -0.06px;
          color: #888888;
          min-width: 75px;
        }

        .pre {
          font-weight: 700;
          color: #0a64c2;
          text-transform: uppercase;
          text-align: end;
        }
      }
    }

    .hover {
      box-sizing: border-box;
      z-index: 100;
      position: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100%;
      padding: 24% 0.5rem 0.5rem;
      text-align: center;
      opacity: 0;
      background: rgb(0 0 0 / 81%);
      color: rgba(255, 255, 255, 0.85);
      transition: all 1.75s cubic-bezier(0.23, 1, 0.32, 1);

      h3 {
        font-size: 1.125rem;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.85);
      }

      p {
        margin-bottom: 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
      }

      .a-wrap {
        background-color: rgba(166, 211, 255, 0.45);
        width: calc(50% - 0.5rem);
        box-sizing: border-box;
        float: left;
        margin-bottom: 0.5rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 0.875rem;
        font-weight: 700;
        text-align: center;
        color: #fff;
        background-size: 0 100%;
        transition: all 0.5s;

        :last-child {
          float: right;
        }
      }
    }

    .pc-game {
      .pc-game-list > .tab {
        width: calc(25% - 12px);
        // max-height: 285px;
      }
    }

    .mobile-game {
      h2 {
        padding-top: 15px;
      }

      .normal {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
      }

      .img-area {
        display: inline-block;
        overflow: hidden;
        border-radius: 10px;
      }

      .img-area > img {
        width: 7rem;
      }

      .desc {
        display: inline-block;
        padding: 1rem;
        padding-right: 0;
        width: calc(100% - 8rem);

        h3 {
          color: #fff;
          font-weight: 400;
          letter-spacing: -0.025rem;
        }

        p {
          color: #fff;
          font-size: 0.875rem;
          padding-top: 1rem;

          .icon {
            margin-right: 5px;
          }
        }
      }

      .hover {
        &.desc {
          padding: 0.5rem;
        }
        p {
          font-size: 1rem;
          font-weight: 400;
          color: #929292;
          padding-top: 0.25rem;
          margin-bottom: 0.25rem;
        }

        .a-wrap {
          height: 2rem;
          line-height: 0;
          padding-top: 15px;

          &.btn-mb-download {
            padding-top: 0;
          }

          :last-child {
            float: none;
          }
        }
      }

      .mb-icon {
        max-height: 100%;
        margin-right: 0.25rem;
        vertical-align: middle;
        height: 100%;
        display: inline-block;

        img {
          width: 1.375rem;
          // 자꾸 변동되는 이유를 알 수 없음
          padding-top: 0.375rem;
        }
      }
    }
  }

  .mobile-game-list .tab:hover .hover .a-wrap:hover,
  .pc-game-list .tab:hover .hover a:hover {
    color: #fff;
    font-weight: 700;
    background-color: rgba(60, 159, 253, 0.85);
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    transition: background-size 0.5s 0.25s, background-image 0.5s 0.25s,
      background-color 0.5s 0.25s;
  }
}

@media screen and (max-width: 1200px) {
  .allGames {
    .sub-title {
      font-size: 1.375rem;
    }

    .allGames-btn {
      margin-top: -6px;

      button {
        width: 2rem;
      }
    }

    .pc-game-list .tab:hover,
    .mobile-game-list .tab:hover {
      .a-wrap {
        background-color: rgba(166, 211, 255, 0.45);
      }
    }

    .mobile-game {
      .img-area img {
        width: 6.875rem !important;
      }
      .desc.mb {
        width: 145px !important;
      }
    }
  }
  .allGames .games-area .mobile-game .hover.desc {
    padding-top: 0;
  }

  .allGames .games-area .hover {
    top: 0;
    height: 5.5rem;
    padding: 0 0.5rem 0.5rem;
    text-align: center;
    opacity: 1;
    position: relative;
    background: 0 0;

    h3,
    p {
      display: none;
    }

    .a-wrap {
      background-color: rgba(166, 211, 255, 0.15);
      color: rgba(255, 255, 255, 0.5);
      height: 2rem;
      line-height: 2rem;
      font-size: 0.75rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .allGames .games-area .hover {
    padding: 1rem;
    padding-top: 0;
  }

  .allGames .games-area .pc-game-list .tab {
    margin: 0 0 2rem 6px;
    width: calc(33.3333% - 16px) !important;
    height: auto;
    min-height: calc(23vw + 1.375rem);
  }

  .allGames .games-area .mobile-game-list .tab {
    width: calc(50% - 12px) !important;

    .desc {
      h3 {
        font-size: 1rem;
      }
      p {
        font-size: 0.75rem;
      }
    }
  }

  .allGames .games-area .img-area .logo {
    bottom: 1.125rem;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .allGames .games-area .pc-game-list .tab {
    min-height: calc(36vw + 18px);
    width: calc(50% - 20px) !important;

    &:nth-child(2n) {
      margin-left: 10px;
    }
  }

  .allGames .games-area .mobile-game-list .tab {
    min-height: calc(27vw + 2px);
  }
}

@media screen and (max-width: 640px) {
  .allGames .allGames-btn {
    button {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .allGames .games-area .pc-game .pc-game-list .tab:hover .img-area > img,
  .allGames
    .games-area
    .mobile-game
    .mobile-game-list
    .tab:hover
    .img-area
    > img {
    filter: none;
  }

  .allGames .games-area .mobile-game-list .tab,
  .allGames .games-area .pc-game-list .tab {
    min-height: auto;
    width: 100% !important;
    margin: 0;
    margin-right: 0px !important;

    &:nth-child(2n) {
      margin-left: 0px;
    }
  }

  .allGames .games-area {
    .normal {
      padding: 1rem;
    }
    .img-area {
      display: inline-block;
      width: 11rem;
      border-radius: 10px;

      .logo {
        display: none;
      }

      img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .desc-area {
      display: inline-block;
      width: calc(100% - 12rem);
      padding: 0;
      padding-left: 1rem;

      #event-list {
        padding-bottom: 1rem;
      }

      .bottom {
        flex-direction: column-reverse;

        .pre {
          margin-bottom: 5px;
        }
      }
    }

    .hover {
      height: auto;
    }
  }

  .allGames .games-area .mobile-game-list .tab .img-area {
    width: 7rem;
  }
}

@media screen and (max-width: 360px) {
  .allGames .mobile-game .desc.mb {
    width: 144px !important;
  }

  .allGames .games-area .desc-area .bottom .pre {
    font-size: 10px;
    font-weight: 400;
  }
}
