@charset "utf-8";

.gcoin-info {
  width: 100%;
  padding: 1.5rem 0 0;
  background-color: transparent;
  background-image: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/gcoin/bg_gcoin.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .img-gcoin {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .desc {
    display: inline-block;
    float: inherit;
    padding: 2rem 0 0;
    margin-bottom: 3rem;
    max-width: 790px;
  }

  .tit {
    display: block;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    font-size: 2.8125rem;
    color: #fff;
    font-weight: 700;
    letter-spacing: -2px;
  }

  .btn.gcoin {
    vertical-align: middle;
    padding: 0 2rem 0 1.75rem;
    margin-bottom: 0.5rem;
    min-width: auto;
    height: 2rem;
    line-height: 2rem;
    border-radius: 3px;
    color: #242424;
    letter-spacing: 0;
    background: linear-gradient(230deg, #a24bcf, #4b79cf, #4bc5cf);
    background-size: 300% 300%;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-animation: MoveBG 5s ease infinite;
    -moz-animation: MoveBG 5s ease infinite;
    -o-animation: MoveBG 5s ease infinite;
    animation: MoveBG 5s ease infinite;
  }

  .define {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    color: #ffc61d;
  }

  .sentence {
    line-height: 1.5;
    font-size: 1.125rem;
    color: gray;
    word-break: keep-all;
  }
}

@-webkit-keyframes MoveBG {
  0%,
  to {
    background-position: 0 84%;
  }

  50% {
    background-position: 100% 16%;
  }
}

@-moz-keyframes MoveBG {
  0%,
  to {
    background-position: 0 84%;
  }

  50% {
    background-position: 100% 16%;
  }
}

@-o-keyframes MoveBG {
  0%,
  to {
    background-position: 0 84%;
  }

  50% {
    background-position: 100% 16%;
  }
}

@keyframes MoveBG {
  0%,
  to {
    background-position: 0 84%;
  }

  50% {
    background-position: 100% 16%;
  }
}

@media screen and (max-width: 1200px) {
  .gcoin-info .img-gcoin {
    width: 14rem;
  }
  .gcoin-info .desc {
    width: calc(100% - 24rem);
    padding: 0;
  }
  .gcoin-info .tit {
    font-size: 2rem;
    letter-spacing: -0.125rem;
  }
  .gcoin-info .btn.gcoin {
    margin-bottom: 1.5rem;
  }
  .gcoin-info .sentence {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .gcoin-info .tit {
    font-size: 1.5rem;
    letter-spacing: -0.05rem;
  }
}

@media screen and (max-width: 900px) {
  .gcoin-info .sentence {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 840px) {
  .gcoin-info .img-gcoin {
    width: 13rem;
    margin-left: 1rem;
    margin-right: 3rem;
  }

  .gcoin-info .desc {
    width: calc(100% - 18rem);
  }
}

@media screen and (max-width: 768px) {
  .gcoin-info .tit {
    font-size: 1.25rem;
  }
  .gcoin-info .desc {
    margin-bottom: 0;
  }
  .gcoin-info .sentence {
    padding-bottom: 1.25rem;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 576px) {
  .gcoin-info {
    background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/gcoin/bg_gcoin.jpg) -140px
      bottom no-repeat;
  }
  .gcoin-info .row-w > div {
    text-align: center;
  }
  .gcoin-info .img-gcoin {
    overflow: hidden;
    width: 11rem;
    height: 11rem;
    border-radius: 50%;
    margin: 0 auto;
    border: 4px solid #696969;
  }
  .gcoin-info .desc {
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 2rem 0 0;
  }
  .gcoin-info .define,
  .gcoin-info .sentence {
    display: none;
  }
}
