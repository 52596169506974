@charset "utf-8"; /* 문의 하기 정보수집 단락 */
.required {
  padding-right: 1.5rem;
  font-size: 0.8725rem;
}
.sec2.inquiry-list {
  margin-top: 0;
}
.sec2.inquiry-write {
  margin-top: 4rem;
}
.sec3.inquiry-send {
  margin-top: 2rem;
}
.sec3 h4 {
  color: #868686;
  text-align: center;
  line-height: 24px;
  line-height: 1.5rem;
  font-size: 16px;
  font-size: 1rem;
}

/**********************************************************************************************/
/* my Inquiry */

section.inquiry {
  margin-top: 4rem;
  border-bottom: 4px solid /*#f39a00*/ #00bfff;
  padding-bottom: 1rem;

  h1 {
    overflow-x: hidden;
    height: auto;
    min-height: 25px;
    line-height: 1.7rem;
    padding-bottom: 14px;
    color: #fff;
  }

  .pull-left {
    line-height: 1;
  }
}

.sec1 p {
  color: #8e9297;
  font-weight: normal;
  margin-top: 20px;
  line-height: 24px;
  line-height: 1.5rem;
  margin-top: 1.25rem; /*font-size:20px;font-size:1.25rem;*/
  font-size: 16px;
}

/*inquiry - write */
.inquiry-write {
  border-bottom: 5px solid #53545d;

  tr.file {
    .icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .attatch-wrap {
    text-align: end;
    // width: 120px;

    label {
      display: inline-block;
      box-sizing: border-box;
      width: 90px;
      height: 40px;
      padding: 10px 0;
      text-align: center;
      color: #fff;
      background-color: #a9a9a9;
      transition: all 0.2s ease-in;
      cursor: pointer;

      input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
      }

      &:hover {
        background-color: #8e9297;
      }
    }
  }
}
.wrap-agree {
  text-align: center;
  color: #8e9297;
  font-size: 16px;
  font-size: 1rem;
  margin-top: 25px;
  margin-top: 1.5625rem;
}
/*askQuestion*/
.btn#btn-inquiry,
.btn#btn-send,
.btn#btn-cancle,
.btn#btn-list {
  display: inline-block;
  box-sizing: border-box;
  min-width: 251px;
  margin: 0;
  height: 52px;
  /*line-height: 52px;*/
  align-items: flex-start;
  border: 0;
  vertical-align: top;
  padding: 0 16px;
  font-size: 14px;
  /*    font-family:"Microsoft JhengHei",'NotoKr','Dotum',Sans-serif;*/
  font-weight: bold;
  font-style: normal;
  text-align: center;
  box-shadow: none;
  text-shadow: none;
  text-transform: uppercase;
  color: #fff;
  background-image: none;
  background-color: #4c4c4c;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 0.5rem;
}

.btn#btn-send {
  margin-left: 5px;
}
.btn#btn-inquiry:hover,
.btn#btn-send:hover,
.btn#btn-cancle:hover,
.btn#btn-list:hover {
  background-color: #00bfff;
}

/**********************************************************************************************/
/*Inquiry-view */

.inquiry-view {
  border-bottom: 5px solid #53545d;
}
.sec3.view-btn .wrap-btn {
  overflow: hidden;
  text-align: left;
  max-width: inherit;
}
.sec3.view-btn .pull-left .btn.btn-small {
  min-width: 103px;
  margin: 0;
  height: 52px;
  align-items: flex-start;
  vertical-align: top;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #8e9297;
  margin-bottom: 0.5rem;

  &.btn-next {
    margin-left: 5px;
  }

  &:hover {
    background: #68e5e4;
    color: black;
  }
}
.sec3.view-btn .pull-right .btn {
  min-width: 103px;
}

/* ******************************************************************************************** */
/*Inquiry-list */
/* 테이블 */
table.question {
  margin-top: 16px;
  margin-top: 1rem;
}
table.question h1 {
  display: inline-block;
  width: 100%;
  text-align: left;
}
table.question th {
  padding-top: 20px;
  padding-top: 1.25rem;
  vertical-align: top;
  text-align: right;
}
table.question th h1 {
  text-align: right;
}
table.question td {
  padding: 24px;
  padding: 1.5rem;
  vertical-align: top;
  text-align: left;
}

table.question tr#mailAlert {
  display: table-row;
}
table.question td.check_text {
  box-sizing: border-box;
  padding: 0 24px 24px 48px;
  padding: 0 1.5rem 1.5rem 3rem;
  text-indent: -0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
  word-break: keep-all;
}
:lang(jp) table.question td.check_text,
:lang(tw) table.question td.check_text,
:lang(zh) table.question td.check_text,
:lang(ar) table.question td.check_text {
  word-break: break-all;
}

table.question td.check_text span {
}

table.question select {
  margin-top: -16px;
  margin-top: -1rem;
  font-weight: 400;
  color: #8e9297;
}
table.question input {
  margin-top: -16px;
  margin-top: -1rem;
  font-weight: 400;
  color: #8e9297;
}
table.question textarea {
  margin-top: -16px;
  margin-top: -1rem;
  font-weight: 400;
  color: #8e9297;
  font-size: 14px;
}

/*placeholder*/
table.question ::placeholder,
table.question ::-webkit-input-placeholder,
table.question ::-moz-placeholder, /* Firefox 19+ */
table.question :-moz-placeholder, /* Firefox 18- */
table.question :-ms-input-placeholder,/* IE 10+ */
table.question ::-ms-input-placeholder,

table.question textarea::-webkit-input-placeholder,
table.question textarea::-moz-placeholder, /* Firefox 19+ */
table.question textarea:-moz-placeholder,/* Firefox 18- */
table.question textarea:-ms-input-placeholder {
  color: #6e788e !important;
  text-align: left;
  font-size: 14px;
}

table.question .attached-file {
  color: #00bfff;
  padding: 1rem 0rem;
  text-align: left;
  font-size: 14px;
}
table.question .attached-file span {
  color: #00bfff;
  text-align: left;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  font-size: 14px;
}
table.question tr.file1 td {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
table.question tr.file1 th {
  padding-top: 2.5rem;
}
table.question tr.file2 td {
  padding-top: 0;
}
table.question tr.file2 td input {
  margin-top: 0;
}
/*inquiry-view*/
table.view {
}
table.view th {
  padding: 16px 18px;
  padding: 1rem 1.125rem;
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #53545d;
}
table.view th h1 {
  text-align: right;
}
table.view th + td {
  font-size: 18px;
  font-size: 1.125rem;
  text-align: left;
}

table.view td {
  padding: 16px 18px;
  padding: 1rem 1.125rem;
  vertical-align: top;
  text-align: left;
  border-bottom: 1px solid #53545d;

  ul {
    li span {
      font-size: 14px;
      cursor: pointer;

      &:hover {
        border-bottom: 2px solid #53545d;
      }
    }
  }
}
table.view td.question-view {
  padding: 16px 24px;
  padding: 1rem 1.5rem;
}
table.view td.answer-view {
  padding: 16px 24px;
  padding: 1rem 1.5rem;
  background: #1a1c21;
}
table.view td.answer-view .answer-header {
  color: #8e9297;
  display: block;
  overflow: hidden;
  margin-top: 6px;
  margin-top: 0.375rem;
  margin-bottom: 16px;
  margin-bottom: 1rem;
}
table.view td.answer-view .answer-header .answer-tit {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
}
table.view td.answer-view .answer-header .answer-date {
  font-size: 14px;
}

table.view td p {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  margin-top: 0;
  font-size: 14px;
  font-size: 0.875rem;
}

/* inquiry-list*/
table.inquiry-list {
  border-bottom: 3px solid #53545d;
}
table.inquiry-list {
}

table.inquiry-list th {
  padding: 16px 18px;
  padding: 1rem 1.125rem;
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #53545d;
}
table.inquiry-list td {
  padding: 16px 18px;
  padding: 1rem 1.125rem;
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #53545d;
}
table.inquiry-list td.date {
  line-height: 1rem;
}
table.inquiry-list td.subject {
  text-align: left;
  cursor: pointer;
}
table.inquiry-list tr.no-list td.subject {
  text-align: left;
}
table.inquiry-list td.subject {
  color: #8e9297;
}

table.inquiry-list tr.no-list td {
  cursor: default;
}
table.inquiry-list tr:hover td {
  color: #00bfff;
  background: #1a1c21;
}
table.inquiry-list tr.no-list:hover td {
  color: inherit;
  background: inherit;
}
/* 아이디 찾기 결과 */
/*
 .tbl-type1 : 노란색 탑라인, 헤더 백그라운드, 세로 td 선 없음 - width:100%;
*/
.tbl-type1 {
  width: 100%;
}
.tbl-type1 th {
  width: 100%;
  padding-top: 21px;
  padding-bottom: 21px;
  border-top: 2px solid #fa9a00;
  background: #f9fafc;
}
.tbl-type1 td {
  width: 100%;
  padding-top: 19px;
  padding-bottom: 16px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
}

/*회원탈퇴*/
/*
 .tbl-type2 : 노란색 탑라인, 헤더 백그라운드, 세로 td 선 없음 - width: 지정 안함
*/
.tbl-type2 {
  width: 100%;
  border-top: 2px solid #fa9a00;
  border-bottom: 2px solid #e7e7e7;
}

.tbl-type2 td {
  padding: 21px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
}
.tbl-type2 td p {
  font-size: 16px;
  font-size: 1rem;
}
.tbl-type2 td p strong {
  color: #000;
}

.num1 {
  background: #efefef
    url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/circle-num1.png);
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center;
}
.num2 {
  background: #efefef
    url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/circle-num2.png);
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center;
}
.num3 {
  background: #efefef
    url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/circle-num3.png);
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center;
}
.num4 {
  background: #efefef
    url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/circle-num4.png);
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center;
}
.num5 {
  background: #efefef
    url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/circle-num5.png);
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center;
}
.num6 {
  background: #efefef
    url(https://file.qijisoft.com/Valofe_file/web/vfun/images/common/circle-num6.png);
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 1024px) {
  section:first-child {
    // margin-top: 2rem;
  }
  .sec3.inquiry-send {
    margin-top: 2rem;
  }

  .none-member .sec1 .pull-right {
    width: 100%;
    float: none;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 640px) {
  .btn#btn-inquiry,
  .btn#btn-send,
  .btn#btn-cancle,
  .btn#btn-list {
    min-width: 103px;
  }
}
@media screen and (max-width: 576px) {
  .only-desktop {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .sec3 h4 {
    font-size: 1rem;
  }
  .wrap-agree {
    font-size: 1.125rem;
  }
}
