#fall_leaf{
  position:absolute;
  left:0;
  top:0;
  width: auto;
  height: 100%;
}

#fall_front_leaf{
  position: absolute;
  right: 0;
  bottom: 0;
  width: auto;
  max-height: 220px;
  min-height: 100px;
  height: auto;
}
